// import React, { useState } from "react";
import css from "../MainStyleForms.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { BiSolidCoupon } from "react-icons/bi";
import { createCourse, resetCourseData } from "../../../Store/CourseSlice.js";
import { useEffect } from "react";
import { setError } from "../../../Store/ErrorSlice.js";
import { useNavigate } from "react-router-dom";

export default function BuyFormModel({ data, type }) {
  const { loading } = useSelector((state) => state.course);
  const meRedux = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      dispatch(resetCourseData());
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      coupon: "",
    },
    onSubmit: async (values) => {
      if (!values.coupon && data?.price > meRedux?.records?.point) {
        dispatch(
          setError({
            type: "error",
            message: "ليس لديك رصيد كافي الرجاء الشحن",
            location: "شراء ",
          })
        );
        navigate(`/checkout`);
      } else {
        const logData =
          type === "section"
            ? {
                section: data._id,
                ...(values.coupon && { coupon: values.coupon }),
              }
            : {
                lacture: data._id,
                ...(values.coupon && { coupon: values.coupon }),
              };
        dispatch(createCourse(logData));
      }
    },
  });

  return (
    <div className={`container ${css.container}  overflow-hidden `}>
      <h4 style={{ color: "var(--dark-color)" }} className="text-center py-2 ">
        هل تريد شراء :{" "}
        <span className="text-primary">{data.lecture || data.name}</span>
      </h4>
      <h5 style={{ color: "var(--dark-color)" }} className="text-center mb-3">
        السعر : <span className="text-primary">{data.price} ج.م</span>
      </h5>
      <form className="row g-3" onSubmit={formik.handleSubmit}>
        <div className="col-12">
          <div className="input-group has-validation">
            <span className="input-group-text rounded-0 rounded-end">
              <BiSolidCoupon
                className="fs-6"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid">
              <input
                type="text"
                id="coupon"
                className={`form-control rounded-0 ${
                  formik.touched.coupon && formik.errors.coupon && "is-invalid"
                }`}
                placeholder=""
                {...formik.getFieldProps("coupon")}
              />
              <label style={{ color: "var(--gray-color)" }} htmlFor="coupon">
                ادخل كود الكوبون ان وجد
              </label>
            </div>
          </div>
        </div>
        <div className="col-12  d-flex justify-content-center align-content-center">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary px-4 py-2 fs-5"
          >
            {!loading
              ? `شراء ال${type === "section" ? "باب" : "محاضرة"}`
              : "جاري الشراء"}
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </form>
      <span
        className="text-danger text-end py-2 mt-2"
        style={{ fontSize: "12px" }}
      >
        ملحوظة: إذا اشتريت الدرس من هذا الجهاز، ستتمكن من المشاهدة فقط على نفس
        الجهاز
      </span>
    </div>
  );
}
