import React, { useEffect, useState } from "react";
import css from "./loginForm.module.css";
import { HiOutlineLogin } from "react-icons/hi";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { login, resetData } from "../../../Store/AuthSlice";
import Cookies from "js-cookie";
import MainModel from "../../../Model/mainModel/MainModel";
import { loginFormValid } from "../../../Util/VallationForms";
import ResetPasswordForm from "../ResetPasswordForm/ResetPasswordForm";

export default function LoginForm() {
  const { records, loading, complete } = useSelector(
    (state) => state.auth
  );

  const [openModel, setOpenModel] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (complete && records.token) {
      Cookies.set("access_token", `${records.token}`, { expires: 350 });
      setTimeout(() => {
        if (
          (records?.data?.role === "admin" ||
          records?.data?.role === "manager") && Cookies.get("access_token")
        ) {
          navigate("/dashboard");
        } else {
          navigate("/");
        }
      }, 3000);
    }
  }, [complete, navigate, records, records.token]);

  useEffect(() => {
    return () => dispatch(resetData());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginFormValid,

    onSubmit: async (values) => {
      try {
        dispatch(login(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  return (
    <div className="d-flex gap-3 flex-column py-4">
      <div className="d-flex align-content-center py-3 px-2 gap-2">
        <span className={css.icon}>
          <HiOutlineLogin className="fs-3" />
        </span>
        <h3 className="px-2">
          تسجيل <span>الدخول :</span>
        </h3>
      </div>
      <div className="container">
        <form
          className={`d-flex gap-4 flex-column ${css.form}`}
          onSubmit={formik.handleSubmit}
        >
          <p className=" text-wrap pb-2">
            ادخل علي حسابك بإدخال الاميل و كلمة المرور المسجل بهم من قبل
          </p>
          <div className="input-group has-validation">
            <span className="input-group-text rounded-0 rounded-end">
              <MdEmail
                className="fs-4"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="text"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.email && formik.errors.email && "is-invalid"
                }`}
                id="email"
                placeholder=""
                {...formik.getFieldProps("email")}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // إزالة المسافات من البداية والنهاية
                  formik.setFieldValue("email", trimmedValue); // تعيين القيمة بدون مسافات زائدة
                }}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="email right-0"
              >
                ادخل الاميل
              </label>
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </div>
          <div className="input-group has-validation">
            <span className="input-group-text rounded-0 rounded-end">
              <RiLockPasswordFill
                className="fs-4"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.password &&
                  formik.errors.password &&
                  "is-invalid"
                }`}
                id="password"
                placeholder=""
                {...formik.getFieldProps("password")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="password right-0"
              >
                ادخل الباسورد
              </label>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="invalid-feedback ">{formik.errors.password}</div>
            )}
          </div>
          <div className="d-flex justify-content-between align-content-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary px-4 py-2 fs-5"
            >
              {!loading ? " تسجيل الدخول" : "تحميل"}
              {loading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    aria-hidden="true"
                  ></span>
                </>
              )}
            </button>
            <span
              className="link-primary"
              type="button"
              onClick={() => setOpenModel(true)}
            >
              نسيت كلمة المرور
            </span>
            {openModel && (
              <MainModel
                setModel={setOpenModel}
                content={<ResetPasswordForm emailValue={formik.values.email} />}
              />
            )}
          </div>
          <div>
            ليس لديك حساب ؟
            <span>
              {" "}
              <Link className="text-decoration-none" to="/signup">
                انشئ حساب جديد
              </Link>
            </span>
          </div>
        </form>
        {/* {complete && (
          <AlertsModel
            msg="تم تسجيل الدخول بنجاح"
            place="تسجيل الدخول"
          />
        )} */}
      </div>
    </div>
  );
}
