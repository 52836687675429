import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherByID } from "../../../Store/TeacherSlice.js";
import { useParams } from "react-router-dom";
import LoadingModel from "../../../Model/Loading/Loading.jsx";
import css from "./SectionPage.module.css";
import LectureComponents from "../../../Components/LectureComponents/LectureComponents.jsx";
import { IoVideocam } from "react-icons/io5";
import { MdQuiz } from "react-icons/md";
import { FaFilePdf } from "react-icons/fa6";
import BtnBuy from "../../../Components/Buttons/BtnBuy/BtnBuy.jsx";
// import { AiFillPoundCircle } from "react-icons/ai";

export default function SectionPage() {
  const { records, loading } = useSelector((state) => state.teacher);
  const meRedux = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const { section, id } = useParams();
  const [sectionChops, setSectionChops] = useState(null);
  const [LectureChops, setLectureChops] = useState(null);
  const [countVideo, setCountVideo] = useState(0);
  const [countPdf, setCountPdf] = useState(0);
  const [countQuiz, setCountQuiz] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!loading && records?.length === 0) {
      dispatch(getTeacherByID(id));
    }

    if (records?.section?.length > 0) {
      const data = records.section.find((e) => e?._id === section);
      const lectorData = records.lecutre.filter(
        (e) => e?.section?._id === section
      );

      if (data) {
        setSectionChops(data);
      } else {
        setSectionChops(null);
      }
      if (lectorData) {
        setLectureChops(lectorData);
      } else {
        setLectureChops(null);
      }
    }
  }, [dispatch, id, loading, records, section]);

  useEffect(() => {
    if (LectureChops) {
      let videoCount = 0,
        pdfCount = 0,
        quizCount = 0;
      LectureChops.forEach((e) => {
        if (e.video) videoCount++;
        if (e.quiz) quizCount++;
        if (e.pdf) pdfCount++;
      });
      setCountVideo(videoCount);
      setCountQuiz(quizCount);
      setCountPdf(pdfCount);
    }
  }, [LectureChops]);

  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    return formattedDate;
  };
  return (
    <>
      {loading ? (
        <LoadingModel />
      ) : (
        <div className={css.container}>
          {sectionChops ? (
            <div className="container mt-4 d-flex align-items-center justify-content-center flex-column  ">
              <div className={`row ${css.info}`}>
                <div className={`col-lg-6 ${css.img}`}>
                  <img src={sectionChops.image} alt="section" loading="lazy" />
                </div>
                <div
                  className={`col-lg-6 ${css.count} d-flex  justify-content-around flex-column`}
                >
                  <div>
                    <h6 className="text-center border border-primary  p-2 w-auto rounded-5 mt-3 mt-md-1">
                      عدد المحاضرات داخل الباب{" "}
                      <span className="text-primary fw-bold fs-5">
                        {" "}
                        {LectureChops.length}
                      </span>
                    </h6>
                    <div className="row text-center gap-1 justify-content-around my-2">
                      <span
                        className={`col-3  border border-danger ${css.infoCount}`}
                      >
                        <span className="bg-danger">
                          <IoVideocam className="fs-5" />
                        </span>
                        {countVideo}
                      </span>
                      <span
                        className={`col-3 border border-warning ${css.infoCount}`}
                      >
                        <span className="bg-warning">
                          <MdQuiz className="fs-5" />
                        </span>{" "}
                        {countQuiz}
                      </span>
                      <span
                        className={`col-3  border border-success  ${css.infoCount}`}
                      >
                        <span className="bg-success">
                          <FaFilePdf className="fs-5" />
                        </span>{" "}
                        {countPdf}
                      </span>
                    </div>
                  </div>
                  <div className={`${css.tit_dis}`}>
                    <h3> {sectionChops.name}</h3>
                    <p> {sectionChops.description}</p>
                  </div>
                  <div className="my-2 d-flex align-items-center justify-content-around p-2 border-2 flex-column">
                    <h6
                      className={`text-center  border border-primary p-2 px-4 w-auto rounded-5 ${css.price}`}
                    >
                      سعر الباب بعد الخصم{" "}
                      <span className="text-primary fw-bold">
                        {sectionChops.price} ج.م
                      </span>{" "}
                      بدل من{" "}
                      <span className="text-primary fw-bold text-decoration-line-through">
                        {Math.ceil(
                          sectionChops.price / (1 - sectionChops.discount / 100)
                        )}{" "}
                        ج.م
                      </span>
                    </h6>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="text-end">
                      <span
                        style={{ fontSize: "12px" }}
                        className=" px-2 text-bg-primary rounded-5"
                      >
                        تاريخ انشاء : {handleExpiryTime(sectionChops.createdAt)}
                      </span>
                    </div>
                    {meRedux?.records?.role !== "admin" &&
                      meRedux?.records?.role !== "manager" &&
                      meRedux?.records?.role !== "teacher" && (
                        <BtnBuy data={sectionChops} type="section" />
                      )}
                  </div>
                </div>
              </div>
              <h3
                style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
                className="text-center pt-3 px-2 my-4"
              >
                {" "}
                المحاضرات{" "}
              </h3>
              <LectureComponents data={LectureChops} />
            </div>
          ) : (
            <p>لا يوجد بيانات متاحة في هذا الباب</p>
          )}
        </div>
      )}
    </>
  );
}
