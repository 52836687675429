import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./AuthSlice";
import forgetReducer from "./ForgetPassword";
import meReducer from "./getMeSlice";
import userReducer from "./UserSlice";
import themeReducer from "./GlobalSlice";
import couponsReducer from "./CouponsSlice";
import pointsReducer from "./AddPoints";
import classReducer from "./ClassSlice";
import teacherReducer from "./TeacherSlice";
import sliderReducer from "./HeaderSliderSlice";
import sectionsReducer from "./SectionSlice";
import freeVideosReducer from "./FreeVideo";
import honourReducer from "./HonourSlice";
import galleryTeacherReducer from "./GalleryTeacherSlice";
import transactionsReducer from "./TransactionsSlice";
import lectureReducer from "./LectureSlice";
import courseReducer from "./CourseSlice";
import errorSlice from "./ErrorSlice";
import VideoSlice from "./VideoSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    forget: forgetReducer,
    me: meReducer,
    users: userReducer,
    theme: themeReducer,
    coupons: couponsReducer,
    points: pointsReducer,
    class: classReducer,
    teacher: teacherReducer,
    slider: sliderReducer,
    section: sectionsReducer,
    freeVideos: freeVideosReducer,
    honour: honourReducer,
    galleryTeacher: galleryTeacherReducer,
    transaction: transactionsReducer,
    lecture: lectureReducer,
    course: courseReducer,
    errors: errorSlice,
    video: VideoSlice,
  },
});

export default store;
