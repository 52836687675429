import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoAdd } from "react-icons/io5";
import css from "../MainStyleForms.module.css";
import { FaChalkboardTeacher } from "react-icons/fa";
import { getAllTeachers } from "../../../Store/TeacherSlice";
import { createGallery, editGallery } from "../../../Store/GalleryTeacherSlice";
import { galleryTeacherFormValid } from "../../../Util/VallationForms";

export default function GalleryTeacherForm({ type, SendData }) {
  const { loading } = useSelector((state) => state.class);
  const [img, setImg] = useState(SendData?.image || null);
  const teacherRedux = useSelector((state) => state.teacher);

  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getAllTeachers());
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      image: SendData?.image || null,
      teacher: SendData?.teacher?._id || "",
    },
    validationSchema: galleryTeacherFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("image", values.image);
      formData.append("teacher", values.teacher);

      try {
        if (type === "edit") {
          if (typeof values.image === "object") {
            dispatch(editGallery([SendData._id, formData]));
          } else if (typeof values.image === "string") {
            // formData.delete("image");
            dispatch(editGallery([SendData._id, { teacher: values.teacher }]));
          }
        } else if (type === "add") {
          dispatch(createGallery(formData));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };

  return (
    <div className={`container ${css.container} `}>
      <h3
        style={{ color: "var(--dark-color)" }}
        className="text-center py-3"
      >{`${type === "add" ? "اضف" : "عدل"} الصوره الخاصة بالمدرس`}</h3>
      <form className="row g-3" onSubmit={formik.handleSubmit}>
        <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ">
          <div className={css.image}>
            {img && <img src={img} alt="MyPhoto" loading="lazy" />}
            <div>
              <label className={css.customFileInput} htmlFor="image">
                <IoAdd />
              </label>
              <input
                type="file"
                id="image"
                name="image"
                accept=".png, .jpg, .jpeg"
                onChange={fileChange}
              />
              {formik.touched.image && formik.errors.image && (
                <div className="text-danger">{formik.errors.image}</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-8 d-flex align-items-center justify-content-between">
          <div className="input-group has-validation col-md-12">
            <span className="input-group-text rounded-0 rounded-end">
              {teacherRedux.loading ? (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              ) : (
                <FaChalkboardTeacher
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              )}
            </span>
            <div className="form-floating is-invalid">
              <select
                id="teacher"
                style={{ color: "var(--gray-color)" }}
                className={`form-select form-select-md rounded-0 rounded-start ${
                  formik.touched.teacher &&
                  formik.errors.teacher &&
                  "is-invalid"
                }`}
                aria-label="Large select example"
                {...formik.getFieldProps("teacher")}
                value={formik.values.teacher}
              >
                <option value="">اختر المدرس</option>
                {teacherRedux?.records?.data?.map((e) => (
                  <option key={e._id} value={e._id}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.teacher && formik.errors.teacher && (
              <div className="invalid-feedback ">{formik.errors.teacher}</div>
            )}
          </div>
        </div>
        <div className="col-12  d-flex justify-content-center align-content-center">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary px-4 py-2 fs-5"
          >
            {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
