import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllGallery,
  resetGallerySliceData,
} from "../../../../Store/GalleryTeacherSlice";
import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import GalleryTeacherForm from "../../../../Components/Forms/GalleryTeacherForm/GalleryTeacherForm";
import { deleteGallery } from "../../../../Store/GalleryTeacherSlice";
import ShercheTeacharGallery from "../../../../Components/Serches/ShercheTeacharGallery/ShercheTeacharGallery";
export default function GalleryTeacher() {
  const { records, loading, complete} = useSelector((state) => state.galleryTeacher);
  const dispatch = useDispatch();

  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);


    useEffect(() => {
      if (complete) {
        dispatch(getAllGallery());
      }
    }, [complete, dispatch]);

  useEffect(() => {
    dispatch(getAllGallery());
    return () => {
      dispatch(resetGallerySliceData());
    };
  }, [dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(
        `هل انت متأكد من حذف الخاصة با (${row?.original?.teacher?.name}) `
      );
      const dispatchData = () => {
        dispatch(deleteGallery(row?.original?._id));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "الصورة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "60px", height: "50px", borderRadius: "25%" }}
              src={row?.original?.image}
              alt={`teacher-img`}
            />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "اسم المدرس",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.teacher?.name}</span>
          </>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex algin-items-center justify-content-center gap-2">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            <MainBtn
              btn={<MdDelete onClick={() => handleDeleteClick(row)} />}
            />
          </div>
        ),
      },
    ],
    [handleEditClick, handleDeleteClick]
  );
  return (
    <>
      <ShercheTeacharGallery/>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
        form={<GalleryTeacherForm type="add" SendData={[]} />}
      />
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<GalleryTeacherForm type="edit" SendData={sendData} />}
        />
      )}
    </>
  );
}
