import { useSelector } from "react-redux";
import img from "../../../dist/img/Avatar-page.png";
import css from "./HomeDashboardUser.module.css";
import { useEffect, useRef, useState } from "react";
import MainModel from "../../../Model/mainModel/MainModel";
import UserChangePassword from "../../../Components/Forms/UserChangePassword/UserChangePasswordForm";
import moon from "../../../dist/img/moon.png";
import sun from "../../../dist/img/sun.png";
// import { Link } from "react-router-dom";

export default function HomeDashboardUser() {
  const { records } = useSelector((state) => state.me);
  const { theme } = useSelector((state) => state.theme);
  const [openModel, setOpenModel] = useState(null);
  const beforeImg = useRef();

  useEffect(() => {
    if (beforeImg.current) {
      const style = document.createElement("style");
      document.head.appendChild(style);

      if (theme === "dark") {
        style.innerHTML = `
          .AASSAA::before {
            background-image: url(${moon});
          }
        `;
      } else {
        style.innerHTML = `
          .AASSAA::before {
            background-image: url(${sun});
          }
        `;
      }
    }
  }, [theme]);


  
  return (
    <>
      <div className="container d-flex align-items-center  justify-content-between w-100 ">
        {/* {!records?.userVerify && (
          <Link to="/signup/verify" className={`btn btn-danger z-1 ${css.btn}`}>
            {" "}
            اضغط لتأكيد الحساب
          </Link>
        )} */}
        <button
          className={`btn btn-primary z-1  ${css.btn}`}
          onClick={() => setOpenModel((prevModel) => !prevModel)}
        >
          تغير كلمة المرور المرور
        </button>
      </div>
      <div
        ref={beforeImg}
        className={`container ps-0 mt-2 AASSAA ${css.before}`}
      >
        <div className={`row justify-content-center ${css.container}`}>
          <div className="col-md-10 ">
            <div className=" d-flex align-items-center  flex-column  flex-md-row gap-2  mb-4">
              <img
                src={img}
                alt="Profile"
                className={`rounded-circle ${css.img}`}
                width="150"
                height="150"
              />
              <div className="me-2">
                <h3>{records?.name}</h3>
                <p>طالب</p>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>رقم الهاتف</label>
                  <h5 className="text-center">{`0${records?.phone}`}</h5>
                </div>
                <div className="col-md-6 mb-3">
                  <label>رقم هاتف ولي الامر</label>
                  <h5 className="text-center">{`${records?.guardianPhone}`}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>الموقع</label>
                  <h5 className="text-center">{records?.address}</h5>
                </div>
                <div className="col-md-6 mb-3">
                  <label>البريد الالكتروني</label>
                  <h5 className="text-center fs-6">{records?.email}</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>الصف</label>
                  <h5 className="text-center">
                    {records?.grade === "third" ? "الثالث" : records?.grade === "first" ?" الاول" : "الثاني"}
                  </h5>
                </div>
                <div className="col-md-6 mb-3">
                  <label>الرصيد</label>
                  <h5 className="text-center">{`${records?.point} جنيه`}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModel && (
        <MainModel setModel={setOpenModel} content={<UserChangePassword />} />
      )}
    </>
  );
}
