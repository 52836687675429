import { useDispatch, useSelector } from "react-redux";
import css from "./DashboardAvatar.module.css";
import img from "../../../../dist/img/images-removebg-preview.png";
import { MdAdd } from "react-icons/md";
import { useEffect, useState } from "react";
import MainModel from "../../../../Model/mainModel/MainModel";
import AddManagerAndAdminImg from "../../../Forms/AddManagerAndAdminImg/AddManagerAndAdminImg";
import { resetDataUser } from "../../../../Store/UserSlice.js";
import { getMe } from "../../../../Store/getMeSlice.js";

export default function DashboardAvatar() {
  const [openModel, setOpenModel] = useState(false);
  const { records } = useSelector((state) => state.me);
  const { completeGetAdmin } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    if (completeGetAdmin) {
      try {
        dispatch(resetDataUser());
        dispatch(getMe());
      } catch (err) {
        console.error(err);
      }
    }
  }, [completeGetAdmin, dispatch]);

  const name = records?.name?.split(" ").slice(0, 2).join(" ");
  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-column gap-1`}
    >
      <div className={css.containerImg}>
        <img
          className={`rounded-circle ${css.img}`}
          src={records?.image || img}
          alt="avatarDashboard"
        />
        <div onClick={() => setOpenModel(true)} className={css.Add}>
          <MdAdd />
        </div>
      </div>
      <div className="text-center">
        <span className={css.name}>{name || "name"}</span>
        <br />
        <span className={css.title}>{records?.role || "role"}</span>
      </div>
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<AddManagerAndAdminImg SendData={records} />}
        />
      )}
    </div>
  );
}
