import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
const initState = {
  records: [],
  loading: false,
  error: null,
  completeForgetPassword: false,
  completeVerifyCodeForgetPassword: false,
  completeSetNewPassword: false,
  emailChanged: "",
};

export const sendEmail = createAsyncThunk(
  "forget/forgetPassword",
  async (user, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.post(`/auth/forgetPassword`, user);
      dispatch(
        setError({
          type: "complete",
          message: "اميل صحيح تم ارسال الكود",
          location: "نسيان كلمة السر",
        })
      );
      return { data: response.data, email: user.email };
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في ارسال البريد الالكتروني",
          location: "نسيان كلمة السر",
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const VerifyCodeForget = createAsyncThunk(
  "forget/VerifyCodeForget",
  async (code, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.post(`/auth/verifycode`, code);
      dispatch(
        setError({
          type: "complete",
          message: "تم التأكد من الكود  ",
          location: "نسيان كلمة السر",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في التأكد من الكود",
          location: "نسيان كلمة السر",
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const setNewPasswordStore = createAsyncThunk(
  "forget/setNewPasswordStore",
  async (password, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.put(
        `/auth/setNewPassword`,
        password
      );
      dispatch(
        setError({
          type: "complete",
          message: "تم تغير كلمة السر بنجاح",
          location: "تغير كلمة السر",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في ارسال كلمة السر الجديدة",
          location: "نسيان كلمة السر",
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const forgetPassword = createSlice({
  name: "forget",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // sendEmail
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.completeForgetPassword = false;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.data;
        state.completeForgetPassword = true;
        state.emailChanged = action.payload.email;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.completeForgetPassword = false;
      })
      // VerifyCodeForgetPassword
      .addCase(VerifyCodeForget.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.completeVerifyCodeForgetPassword = false;
      })
      .addCase(VerifyCodeForget.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.completeVerifyCodeForgetPassword = true;
      })
      .addCase(VerifyCodeForget.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.completeVerifyCodeForgetPassword = false;
      })
      // SetNewPassword
      .addCase(setNewPasswordStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.completeSetNewPassword = false;
      })
      .addCase(setNewPasswordStore.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.completeSetNewPassword = true;
      })
      .addCase(setNewPasswordStore.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.completeSetNewPassword = false;
      });
  },
});

export default forgetPassword.reducer;
