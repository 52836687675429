import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllSlider = createAsyncThunk(
  "slider/getAllChapters",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/slider");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب معرض الصور الرئسي ",
          location: "معرض الصور الرئسي",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSlider = createAsyncThunk(
  "slider/deleteChapter",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/slider/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف الصورة بنجاح",
          location: "معرض الصور الرئسي",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف الصوره من معرض الصور الرئسي ",
          location: "معرض الصور الرئسي",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createSlider = createAsyncThunk(
  "slider/createSlider",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/slider", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء الصورة بنجاح",
          location: "معرض الصور الرئسي",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في انشاء صوره في معرض الصور الرئسي ",
          location: "معرض الصور الرئسي",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editSlider = createAsyncThunk(
  "slider/editChapter",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/slider/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل الصورة بنجاح",
          location: "معرض الصور الرئسي",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل الصوره من معرض الصور الرئسي ",
          location: "معرض الصور الرئسي",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const sliderSlice = createSlice({
  name: "slider",
  initialState: initState,
  reducers: {
    resetSliderData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllSlider
      .addCase(getAllSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllSlider.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllSlider.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteSlider
      .addCase(deleteSlider.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteSlider.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createSlider
      .addCase(createSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createSlider.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editSlider
      .addCase(editSlider.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editSlider.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editSlider.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetSliderData } = sliderSlice.actions;
export default sliderSlice.reducer;
