import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setError } from "./ErrorSlice";

import { axiosInstance } from "./Config";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllTransactions = createAsyncThunk(
  "transaction/getAllTransactions",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/transaction/getMyTransactions?limit=50000`
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب تحويلاتي ",
          location: "  التحويلات",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
export const getMeReceiver = createAsyncThunk(
  "transaction/getMeReceiver",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(
        `/transaction/getMyTransactions?receiver.id=${id}`
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب التحويلات القادمه ",
          location: "  تحويلاتي",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

const transactionsSlice = createSlice({
  name: "transaction",
  initialState: initState,
  reducers: {
    resetTransactionsData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllClass
      .addCase(getAllTransactions.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllTransactions.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // getMeReceiver
      .addCase(getMeReceiver.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getMeReceiver.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getMeReceiver.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      });
  },
});
export const { resetTransactionsData } = transactionsSlice.actions;
export default transactionsSlice.reducer;
