import React from "react";
import Time from "../DashboardHeader/Time/Time";
import { Link } from "react-router-dom";
import { GoHomeFill } from "react-icons/go";
import MainBtn from "../../Buttons/MainBtn/MainBtn";
import DarkLight from "../../Buttons/DarkLigth/DarkLigth";
import BtnFullscreen from "../../Buttons/BtnFullscreen/BtnFullscreen"

export default function DashboardHeader() {
  return (
    <div
      className={`ps-1 d-flex align-items-center justify-content-between z-3`}
    >
      <div className="d-flex align-items-center justify-content-center gap-2 gap-md-3 pe-1 pe-md-3">

        <BtnFullscreen/>
        <MainBtn
          btn={
            <Link to="/">
              <GoHomeFill />
            </Link>
          }
        />
        <DarkLight />
      </div>
      <>
        <Time />
      </>
    </div>
  );
}
