import { useFormik } from "formik";
import React, { useRef } from "react";
import { VerifyCodeForgetPasswordValid } from "../../../../Util/VallationForms";
import { useSelector, useDispatch } from "react-redux";
import { VerifyCodeForget } from "../../../../Store/ForgetPassword";

export default function VerifyCodeForgetPassword() {
  const inputs = useRef([]);
  const {loading } = useSelector((state) => state.forget);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      code: ["", "", "", "", "", ""],
    },
    validationSchema: VerifyCodeForgetPasswordValid,
    onSubmit: (values) => {
      const code = { code: values.code.join("") };
      try {
        dispatch(VerifyCodeForget(code));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleInput = (index, e) => {
    const input = e.target;
    const nextInput = inputs.current[index + 1];

    formik.setFieldValue(`code[${index}]`, input.value);

    if (nextInput && input.value) {
      nextInput.focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text").slice(0, 6); // قص النص لست حقول فقط

    // وضع النص المقصوص في الحقول
    paste.split("").forEach((char, index) => {
      if (inputs.current[index]) {
        inputs.current[index].value = char;
        formik.setFieldValue(`code[${index}]`, char);
      }
    });
  };

  // دالة للتعامل مع مفتاح الرجوع لحذف الحروف
  const handleBackspace = (index, e) => {
    const input = e.target;
    const previousInput = inputs.current[index - 1];

    if (!input.value && previousInput) {
      previousInput.focus();
    }
  };

  // دالة للتعامل مع مفاتيح الأسهم اليمنى واليسرى
  const handleArrow = (index, direction, e) => {
    const currentInput = inputs.current[index];
    let targetInput = null;

    if (direction === "left") {
      targetInput = inputs.current[index - 1];
    } else if (direction === "right") {
      targetInput = inputs.current[index + 1];
    }

    if (targetInput) {
      targetInput.focus();
    } else {
      currentInput.focus();
    }
  };

  return (
    <form dir="ltr" onSubmit={formik.handleSubmit} className="p-2">
      <h4 className="text-center mb-4">ادخل الكود المرسل أليك</h4>
      <div className="d-flex mb-3 gap-2 py-3">
        {formik.values.code?.map((_, index) => (
          <input
            key={index}
            type="tel"
            maxLength="1"
            pattern="[0-9]"
            className="form-control text-center"
            ref={(el) => (inputs.current[index] = el)} // تعيين المرجع لكل حقل إدخال
            onChange={(e) => handleInput(index, e)}
            onPaste={handlePaste}
            onKeyDown={(e) => {
              switch (e.key) {
                case "Backspace":
                  handleBackspace(index, e);
                  break;
                case "ArrowLeft":
                  handleArrow(index, "left", e);
                  break;
                case "ArrowRight":
                  handleArrow(index, "right", e);
                  break;
                default:
                  break;
              }
            }}
          />
        ))}
      </div>
      {formik.errors.code && formik.touched.code ? (
        <div className="text-danger mb-2 fs-6">
          {typeof formik.errors.code === "string" ? (
            <div>{formik.errors.code}</div>
          ) : (
            formik.errors.code?.map(
              (error, index) => error && <div key={index}>{error}</div>
            )
          )}
        </div>
      ) : null}

      <button
        type="submit"
        disabled={loading}
        className="w-100 btn btn-primary"
        onClick={formik.handleSubmit}
      >
        {loading ? "جاري التأكيد" : "تأكيد الكود"}
        {loading && (
          <>
            <span
              className="spinner-border spinner-border-sm mx-2"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden" role="status">
              Loading...
            </span>
          </>
        )}
      </button>
    </form>
  );
}
