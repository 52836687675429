import React, { useState } from "react";
import MainModel from "../../../Model/mainModel/MainModel";
import UserChangePassword from "../../../Components/Forms/UserChangePassword/UserChangePasswordForm";

export default function ChangePasswordBtn() {
  const [openModel, setOpenModel] = useState(null);

  return (
    <>
      <div
        onClick={() => setOpenModel((prevModel) => !prevModel)}
        className="btn btn-primary"
      >
        تغير الباسورد{" "}
      </div>
      {openModel && (
        <MainModel setModel={setOpenModel} content={<UserChangePassword />} />
      )}
    </>
  );
}
