import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserByRole,
  resetDataUser,
  deleteUser,
} from "../../../../Store/UserSlice";
import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";
import MainModel from "../../../../Model/mainModel/MainModel";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete } from "react-icons/md";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import CreateAdminForm from "../../../../Components/Forms/CreateAdminForm/CreateAdminForm";

export default function CreateAdmin() {
  const { records, loading, error, complete, completeGetAdmin } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  // const [openModel, setOpenModel] = useState(null);
  // const [sendData, setSendData] = useState([]);

  useEffect(() => {
    dispatch(getUserByRole());

    return () => {
      dispatch(resetDataUser());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getUserByRole());
    }
  }, [complete]);

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.name}) `);
      const dispatchData = () => {
        dispatch(deleteUser(row?.original?._id));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "صورة الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "45px", height: "45px", borderRadius: "25%" }}
              src={row?.original?.image}
              alt={`admin-img`}
            />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "الاسم",
        flex: 1,
      },
      {
        accessorKey: "email",
        header: "الاميل",
        flex: 1,
      },
      {
        accessorKey: "phone",
        header: "الهاتف",
        flex: 1,
      },
      {
        accessorKey: "point",
        header: "النقاط",
        size: 50,
      },
      {
        accessorKey: "role",
        header: "الدور",
        size: 50,
      },
      // {
      //   accessorKey: "userVerify",
      //   header: "تأكيد الاميل",
      //   flex: 1,
      //   Cell: ({ row }) => (
      //     <span
      //       style={{
      //         color: `${
      //           row?.original?.userVerify
      //             ? "var(--main-color)"
      //             : "var(--danger-color)"
      //         }`,
      //       }}
      //     >
      //       {row?.original?.userVerify ? "اميل مؤكد" : "اميل غير مؤكد"}
      //     </span>
      //   ),
      // },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-content-center justify-content-center gap-1">
            <MainBtn
              btn={
                <MdDelete
                  className="mainBtnIcon"
                  onClick={() => handleDeleteClick(row)}
                />
              }
            />
          </div>
        ),
      },
    ],
    [handleDeleteClick]
  );

  return (
    <>
      <h4 className="text-center py-2">الأدمن</h4>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
        form={<CreateAdminForm type="add" SendData={[]} />}
      />
      {error && (
        <AlertsModel error={error} msg="هناك خطاء في الصفوف" place="الصف" />
      )}
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {/* {openModel && (
        <MainModel
          setModel={setOpenModel}
          // content={<ClassForm type="edit" SendData={sendData} />}
        />
      )} */}
    </>
  );
}
