import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../../Store/getMeSlice";

import LoadingModel from "../../../Model/Loading/Loading";

export default function IsUser({ children }) {
  const { records, loading, complete,error } = useSelector((state) => state.me);
  const token = Cookies.get("access_token");
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && !complete && !loading && !error) {
      dispatch(getMe());
    }
  }, [token, complete, loading, error, dispatch]);

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  if (loading) {
    return <LoadingModel />;
  }

  if (complete) {
    if (records.role === "user") {
      return children;
    } else {
      return <Navigate to="/" replace />;
    }
  }

  return null;
}
