import { Link, useNavigate } from "react-router-dom";
import css from "./SignupForm.module.css";
import { RiLockPasswordFill } from "react-icons/ri";
import {
  MdEmail,
  MdDriveFileRenameOutline,
  MdOutlineLocationCity,
} from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import { FaAddressCard } from "react-icons/fa";
import { useFormik } from "formik";
import country from "../../../dist/data/countries.json";
import { signupFormValid } from "../../../Util/VallationForms";
import { GiLevelEndFlag } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { signup, resetData } from "../../../Store/AuthSlice";
import { useEffect } from "react";
import Cookies from "js-cookie";

export default function SignupForm() {
  const { records, loading, complete } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (complete && records.token) {
      Cookies.set("access_token", `${records.token}`, { expires: 350 });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [complete, navigate, records.token]);

  useEffect(() => {
    return () => dispatch(resetData());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      guardianPhone: "",
      password: "",
      passwordConfirm: "",
      address: "",
      grade: "",
    },
    validationSchema: signupFormValid,
    onSubmit: async (values) => {
      try {
        dispatch(signup(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <div className="d-flex gap-2 flex-column py-3">
      <div className="d-flex align-content-center py-2 px-2 gap-2">
        <span className={css.icon}>
          <FaAddressCard className="fs-3 text-body-primary" />
        </span>
        <h3 className="px-2">
          انشاء <span>حساب جديد :</span>
        </h3>
      </div>
      <div className="container">
        <form className="row g-2" onSubmit={formik.handleSubmit}>
          <p className=" text-wrap pb-2 col-12">
            ادخل بياناتك بشكل صحيح للحصول علي افضل تجربة داخل الموقع
          </p>
          <div className="input-group has-validation col-md-12 ">
            <span className="input-group-text rounded-0 rounded-end">
              <MdDriveFileRenameOutline
                className="fs-6"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="text"
                id="name"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.name && formik.errors.name && "is-invalid"
                }`}
                placeholder=""
                {...formik.getFieldProps("name")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="name right-0"
              >
                ادخل الاسم ثنائي
              </label>
            </div>
            {formik.touched.name && formik.errors.name && (
              <div className="invalid-feedback ">{formik.errors.name}</div>
            )}
          </div>
          <div className="input-group has-validation col-md-12 ">
            <span className="input-group-text rounded-0 rounded-end">
              <MdEmail
                className="fs-6"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="text"
                id="email"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.email && formik.errors.email && "is-invalid"
                }`}
                placeholder=""
                {...formik.getFieldProps("email")}
                onChange={(e) => {
                  const trimmedValue = e.target.value.trim(); // إزالة المسافات من البداية والنهاية
                  formik.setFieldValue("email", trimmedValue); // تعيين القيمة بدون مسافات زائدة
                }}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="email right-0"
              >
                ادخل اميل صحيح
              </label>
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback ">{formik.errors.email}</div>
            )}
          </div>

          <div
            className={`input-group ${css.inputWidth}  col-md-6  has-validation`}
          >
            <span className="input-group-text rounded-0 rounded-end">
              <FaPhoneFlip
                className="fs-6"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="text"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.phone && formik.errors.phone && "is-invalid"
                }`}
                id="phone"
                placeholder=""
                {...formik.getFieldProps("phone")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="phone right-0"
              >
                ادخل رقم الهاتف
              </label>
            </div>
            {formik.touched.phone && formik.errors.phone && (
              <div className="invalid-feedback ">{formik.errors.phone}</div>
            )}
          </div>
          <div
            className={`input-group ${css.inputWidth}  col-md-6  has-validation`}
          >
            <span className="input-group-text rounded-0 rounded-end">
              <FaPhoneFlip
                className="fs-6"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="text"
                id="guardianPhone"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.guardianPhone &&
                  formik.errors.guardianPhone &&
                  "is-invalid"
                }`}
                placeholder=""
                {...formik.getFieldProps("guardianPhone")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="guardianPhone right-0"
              >
                ادخل رقم هاتف ولي الامر
              </label>
            </div>
            {formik.touched.guardianPhone && formik.errors.guardianPhone && (
              <div className="invalid-feedback ">
                {formik.errors.guardianPhone}
              </div>
            )}
          </div>
          <div className="input-group has-validation col-md-12">
            <span className="input-group-text rounded-0 rounded-end">
              <MdOutlineLocationCity
                className="fs-5"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid">
              <select
                id="address"
                style={{ color: "var(--gray-color)" }}
                className={`form-select form-select-md rounded-0 rounded-start ${
                  formik.touched.address &&
                  formik.errors.address &&
                  "is-invalid"
                } `}
                aria-label="Large select example"
                {...formik.getFieldProps("address")}
                value={formik.values.address}
              >
                <option value="" disabled>
                  اختر المحافظة
                </option>
                {country?.governorates?.map((e) => (
                  <option key={e.id} value={e.name}>
                    {e.name}
                  </option>
                ))}
              </select>
            </div>
            {formik.touched.address && formik.errors.address && (
              <div className="invalid-feedback ">{formik.errors.address}</div>
            )}
          </div>

          <div className="input-group has-validation col-md-12">
            <span className="input-group-text rounded-0 rounded-end">
              <GiLevelEndFlag
                className="fs-5"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid">
              <select
                style={{ color: "var(--gray-color)" }}
                id="grade"
                className={`form-select form-select-md rounded-0 rounded-start ${
                  formik.touched.grade && formik.errors.grade && "is-invalid"
                }`}
                aria-label="Large select example"
                {...formik.getFieldProps("grade")}
                value={formik.values.grade}
              >
                <option value="" disabled>
                  اختر الصف الدراسي
                </option>
                <option value="first">الصف الاول الثانوي</option>
                <option value="second">الصف الثاني الثانوي</option>
                <option value="third">الصف الثالث الثانوي</option>
              </select>
            </div>
            {formik.touched.grade && formik.errors.grade && (
              <div className="invalid-feedback ">{formik.errors.grade}</div>
            )}
          </div>
          <div
            className={`input-group ${css.inputWidth}  col-md-6  has-validation`}
          >
            <span className="input-group-text rounded-0 rounded-end">
              <RiLockPasswordFill
                className="fs-5"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.password &&
                  formik.errors.password &&
                  "is-invalid"
                }`}
                id="password"
                placeholder=""
                {...formik.getFieldProps("password")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="password right-0"
              >
                ادخل كلمة السر
              </label>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="invalid-feedback ">{formik.errors.password}</div>
            )}
          </div>
          <div
            className={`input-group ${css.inputWidth}  col-md-6  has-validation`}
          >
            <span className="input-group-text rounded-0 rounded-end">
              <RiLockPasswordFill
                className="fs-5"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm &&
                  "is-invalid"
                }`}
                id="passwordConfirm"
                placeholder=""
                {...formik.getFieldProps("passwordConfirm")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="passwordConfirm right-0"
              >
                تأكيد الباسورد
              </label>
            </div>
            {formik.touched.passwordConfirm &&
              formik.errors.passwordConfirm && (
                <div className="invalid-feedback ">
                  {formik.errors.passwordConfirm}
                </div>
              )}
          </div>

          <button type="submit" className="btn btn-primary my-3 px-4 py-2 fs-5">
            انشاء حساب
            {loading && (
              <>
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden" role="status">
                  Loading...
                </span>
              </>
            )}
          </button>

          <div>
            لدي حساب؟{" "}
            <span>
              <Link className="text-decoration-none" to="/login">
                تسجيل دخول
              </Link>
            </span>
          </div>
        </form>
        {/* {error && (
          <AlertsModel
            error={error}
            msg={"حدث خطاء في انشاء الحساب"}
            place="انشاء حساب"
          />
        )} */}
        {/* {complete && (
          <AlertsModel
            error={error}
            msg="تم انشاء الحساب بنجاح"
            place="انشاء حساب"
          />
        )} */}
      </div>
    </div>
  );
}
