import React from "react";
import { Outlet } from "react-router-dom";
import DashboardHeader from "../../Components/Header/DashboardHeader/DashboardHeader";
import AdminSidePar from "../../Components/SidePars/AdminSidePar/AdminSidePar";
import css from "./Dashboard.module.css";
import AlertsModel from "../../Model/AlertsModel/AlertsModel";
import { useSelector } from "react-redux";
import UploadVideoModel from "../../Model/UploadVideoModel/UploadVideoModel";

export default function Dashboard() {
  const errors = useSelector((state) => state.errors);
  const {loading,uploadProgress} = useSelector((state) => state.video);
  return (
    <div className={css.container}>
      <div className={css.header}>
        <DashboardHeader />
      </div>
      <div className={css.sidebar}>
        <AdminSidePar />
      </div>
      <div className={css.content}>
        <Outlet />
      </div>
      {errors && <AlertsModel data={errors} />}
      {loading && <UploadVideoModel progress={uploadProgress} />}
    </div>
  );
}
