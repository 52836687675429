import React, { useEffect } from "react";
import { RiUserShared2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getUserByPhone } from "../../../Store/UserSlice";
import { searchUserFromPhone } from "../../../Util/VallationForms";
import { useFormik } from "formik";

export default function AdminSearchUser() {
  const { loading } = useSelector((state) => state.users);
  const { complete } = useSelector((state) => state.points);

  const dispatch = useDispatch();

  useEffect(() => {
    if (complete) {
      dispatch(getUserByPhone(+formik.values.phone));
    }
  }, [complete, dispatch]);

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: searchUserFromPhone,
    onSubmit: async (values) => {
      try {
        dispatch(getUserByPhone(+values.phone));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <div
      className="p-2 mb-3 rounded"
      style={{ backgroundColor: "var(--light-color)" }}
    >
      <h3 className="text-center py-3">ابحث برقم الهاتف</h3>
      <form className={`d-flex flex-column`} onSubmit={formik.handleSubmit}>
        <div className="row gx-4">
          <div className="col-lg-6 col-md-12 mb-3">
            <div className={`input-group has-validation`}>
              <span className="input-group-text rounded-0 rounded-end">
                <RiUserShared2Fill
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.phone && formik.errors.phone && "is-invalid"
                  }`}
                  id="phone"
                  placeholder="ادخل رقم هاتف المستخدم"
                  {...formik.getFieldProps("phone")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="phone right-0"
                >
                  ادخل رقم هاتف المستخدم
                </label>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className="invalid-feedback ">{formik.errors.phone}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary w-100 px-4 py-2 fs-5"
            >
              {loading ? "يتم البحث" : " ابحث"}
              {loading && (
                <>
                  <span
                    className="spinner-border spinner-border-sm mx-2"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
