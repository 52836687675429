import { Outlet } from "react-router-dom";
import css from "./UserDetails.module.css";
import UserSidePar from "../../Components/SidePars/UserSidePar/UserSidePar";

export default function UserDetails() {
  return (
    <div className={css.container}>
      <div className={css.sidebar}>
        <UserSidePar />
      </div>
      <div className={css.content}>
        <Outlet />
      </div>
    </div>
  );
}
