import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import { errorFunction } from "../hooks/ErrorFunction";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
  resnedCode: false,
  verfiyCodeComplete: false,
};

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  const { rejectWithValue, dispatch } = thunkAPI;
  try {
    const response = await axiosInstance.post(`/auth/login`, user);
    dispatch(
      setError({
        type: "complete",
        message: "تم تسجيل الدخول بنجاح",
        location: "تسجيل الدخول",
      })
    );
    return response.data;
  } catch (error) {
    dispatch(
      setError({
        type: "error",
        message: errorFunction(error, " خطا في الايميل او كلمه السر "),
        location: "تسجيل الدخول",
      })
    );
    return rejectWithValue(error.response.data || error.message);
  }
});

export const signup = createAsyncThunk(
  "auth/signup",
  async (user, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.post(`/auth/signup`, user);
      dispatch(
        setError({
          type: "complete",
          message: `تم انشاء حساب باسم ( ${user.name} )`,
          location: "انشاء حساب",
        })
      );
      return response.data;
    } catch (error) {
      console.log(error?.response?.data);
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في انشاء الحساب"),
          location: "انشاء حساب",
        })
      );
      return rejectWithValue(error?.response?.data || error?.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (userPass, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.put(
        `/users/changeUserPassword`,
        userPass
      );
      dispatch(
        setError({
          type: "complete",
          message: " تم تغير كلمة السر بنجاح",
          location: " تغير كلمة السر",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في تغير كلمة السر"),
          location: " تغير كلمة السر",
        })
      );
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const verifyCode = createAsyncThunk(
  "auth/verifyCode",
  async (code, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.post(`/auth/restCode`, code);
      dispatch(
        setError({
          type: "complete",
          message: "الكود صحيح تم تأكيد حسابك بنجاح",
          location: "اعادة ارسال الكود",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في ارسال كود التأكيد ",
          location: "كود التأكيد",
        })
      );
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

export const resnedVerifyCode = createAsyncThunk(
  "auth/resnedVerifyCode",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.post(`/auth/resendVerifycode`);
      dispatch(
        setError({
          type: "complete",
          message: "تم ارسال الكود الي اميلك بنجاح",
          location: "اعادة ارسال الكود",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في اعادة ارسال الكود",
          location: "اعادة ارسال الكود",
        })
      );
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    resetData(state) {
      state.error = false;
      state.complete = false;
      state.resnedCode = false;
      state.verfiyCodeComplete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // login
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message || action.payload}`;
        state.complete = false;
      })

      // signup
      .addCase(signup.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(signup.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message || action.payload}`;
        state.complete = false;
      })

      // changePassword
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message || action.payload}`;
        state.complete = false;
      })
      // verifyCode
      .addCase(verifyCode.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.verfiyCodeComplete = false;
      })
      .addCase(verifyCode.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.records = action.payload;
        state.verfiyCodeComplete = true;
      })
      .addCase(verifyCode.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message || action.payload}`;
        state.verfiyCodeComplete = false;
      })
      // resnedVerifyCode
      .addCase(resnedVerifyCode.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.resnedCode = false;
      })
      .addCase(resnedVerifyCode.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.records = action.payload;
        state.resnedCode = true;
      })
      .addCase(resnedVerifyCode.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message || action.payload}`;
        state.resnedCode = false;
      });
  },
});

export const { resetData } = authSlice.actions;
export default authSlice.reducer;
