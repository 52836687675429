import React, { useEffect, useState } from "react";
import { RiDiscountPercentLine } from "react-icons/ri";
import { FaRegCalendarDays } from "react-icons/fa6";
import css from "./CreateCoupons.module.css";
import { MdOutlineNumbers } from "react-icons/md";
import PrintCoupons from "../../../Components/PrintCoupons/PrintCoupons";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  getAllCoupons,
  createCoupons,
  resetError,
} from "../../../Store/CouponsSlice.js";
import { getAllLecture } from "../../../Store/LectureSlice.js";
import { getAllSections } from "../../../Store/SectionSlice.js";
import { createCouponsValid } from "../../../Util/VallationForms.js";
import { GrChapterAdd } from "react-icons/gr";
import { FaChalkboardTeacher } from "react-icons/fa";
import FilterDataForCoupons from "../../../Components/Serches/FilterDataForTeacher/FilterDataForTeacher.jsx";

export default function CreateCoupons() {
  const { records, loading, complete, error } = useSelector(
    (state) => state.coupons
  );
  const sectionsReducer = useSelector((state) => state.section);
  const lectureReducer = useSelector((state) => state.lecture);
  const [lectureData, setLectureData] = useState(lectureReducer?.records?.data);
  const [sectionsData, setSectionsData] = useState(
    sectionsReducer?.records?.data
  );
  const [view, setView] = useState("create");
  const [chickState, setChickState] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getAllLecture());
      dispatch(getAllSections());
    } catch (err) {
      console.error(err);
    }
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      count: "",
      discount: "",
      expires: "",
      section: "",
      lecture: "",
    },
    validationSchema: createCouponsValid,

    onSubmit: async (values) => {
      try {
        if (chickState) {
          delete values.lecture;
        } else {
          delete values.section;
        }
        dispatch(createCoupons(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(resetError());
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, dispatch]);

  const handelGetAllCoupons = async () => {
    setView("");
    try {
      await dispatch(getAllCoupons());
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className="d-flex align-item-center justify-content-center gap-3 p-3 mb-2 ">
        <button
          onClick={() => setView("create")}
          className={`btn btn-${
            view === "create" ? "" : "outline-"
          }primary fs-6`}
        >
          انشاء كوبونات
        </button>
        <button
          onClick={handelGetAllCoupons}
          className={`btn btn-${
            view === "create" ? "outline-" : ""
          }primary fs-6`}
        >
          احضار جميع الكوبونات
          {loading && view === "" && (
            <span
              className="spinner-border spinner-border-sm me-1"
              aria-hidden="true"
            ></span>
          )}
        </button>
      </div>
      {view && (
        <div
          className="p-2 pb-3 rounded text-center mb-3"
          style={{ backgroundColor: "var(--light-color)" }}
        >
          <h3 className="text-center py-3">اختر المدرس</h3>
          <FilterDataForCoupons
            setLectureData={setLectureData}
            setSectionsData={setSectionsData}
            lectureData={lectureReducer?.records?.data}
            sectionsData={sectionsReducer?.records?.data}
          />
        </div>
      )}

      {view === "create" ? (
        <div
          className="text-center border-1 border-warning p-3 rounded "
          style={{ backgroundColor: "var(--light-color)" }}
        >
          <h3 className="pb-3" style={{ color: "var(--gray-color)" }}>
            انشاء كوبونات
          </h3>
          <form className="row g-2 g-md-3" onSubmit={formik.handleSubmit}>
            <div
              className={`input-group ${css.inputWidth30}  col-md-4  has-validation`}
            >
              <span className="input-group-text rounded-0 rounded-end">
                <MdOutlineNumbers
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="number"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.count && formik.errors.count && "is-invalid"
                  }`}
                  id="passwordConfirm"
                  placeholder=""
                  {...formik.getFieldProps("count")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="passwordConfirm right-0"
                >
                  ادخل عدد الكوبونات
                </label>
              </div>
              {formik.touched.count && formik.errors.count && (
                <div className="invalid-feedback ">{formik.errors.count}</div>
              )}
            </div>
            <div
              className={`input-group has-validation ${css.inputWidth30}  col-md-4`}
            >
              <span className="input-group-text rounded-0 rounded-end">
                <RiDiscountPercentLine
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <select
                  style={{ color: "var(--gray-color)" }}
                  id="grade"
                  className={`form-select form-select-md rounded-0 rounded-start ${
                    formik.touched.discount &&
                    formik.errors.discount &&
                    "is-invalid"
                  }`}
                  aria-label="Large select example"
                  {...formik.getFieldProps("discount")}
                  value={formik.values.discount}
                >
                  <option value="" disabled>
                    اختر الخصم
                  </option>
                  <option value="0">بدون خصم</option>
                  <option value="25">25%</option>
                  <option value="50">50%</option>
                  <option value="75">75%</option>
                  <option value="100">100%</option>
                </select>
              </div>
              {formik.touched.discount && formik.errors.discount && (
                <div className="invalid-feedback ">
                  {formik.errors.discount}
                </div>
              )}
            </div>
            <div
              className={`input-group has-validation ${css.inputWidth30}  col-md-4`}
            >
              <span className="input-group-text rounded-0 rounded-end">
                <FaRegCalendarDays
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <select
                  style={{ color: "var(--gray-color)" }}
                  id="grade"
                  className={`form-select form-select-md rounded-0 rounded-start ${
                    formik.touched.expires &&
                    formik.errors.expires &&
                    "is-invalid"
                  }`}
                  aria-label="Large select example"
                  {...formik.getFieldProps("expires")}
                  value={formik.values.expires}
                >
                  <option value="" disabled>
                    {" "}
                    اختر مدة الكوبونات
                  </option>
                  <option value="1">1 ايام</option>
                  <option value="3">3 ايام</option>
                  <option value="5"> 5 ايام</option>
                  <option value="7"> 7 ايام</option>
                  <option value="10"> 10 ايام</option>
                  <option value="15"> 15 يوم</option>
                </select>
              </div>
              {formik.touched.expires && formik.errors.expires && (
                <div className="invalid-feedback ">{formik.errors.expires}</div>
              )}
            </div>
            <div className={css.containerSwitch}>
              <span className="fw-bold">{chickState ? "باب" : "محاضره"}</span>
              <label className={css.switch}>
                <input
                  type="checkbox"
                  onChange={(e) => setChickState(e.target.checked)}
                />
                <span className={css.slider}></span>
              </label>
            </div>
            {chickState ? (
              <div className={`input-group has-validation col-md-12`}>
                <span className="input-group-text rounded-0 rounded-end">
                  {sectionsReducer.loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <GrChapterAdd
                      className="fs-5"
                      style={{ color: "var(--main-color)" }}
                    />
                  )}
                </span>
                <div className="form-floating is-invalid">
                  <select
                    style={{ color: "var(--gray-color)" }}
                    id="section"
                    className={`form-select form-select-md rounded-0 rounded-start ${
                      formik.touched.section &&
                      formik.errors.section &&
                      "is-invalid"
                    }`}
                    aria-label="Large select example"
                    {...formik.getFieldProps("section")}
                    value={formik.values.section}
                  >
                    <option value="" disabled>
                      اختر الباب
                    </option>
                    {sectionsData?.map((section) => (
                      <option key={section?._id} value={section?._id}>
                        {`باب = (${section.name}) - مستر = (${section?.class?.teacher?.name})`}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.section && formik.errors.section && (
                  <div className="invalid-feedback ">
                    {formik.errors.section}
                  </div>
                )}
              </div>
            ) : (
              <div className={`input-group has-validation  col-md-12`}>
                <span className="input-group-text rounded-0 rounded-end">
                  {lectureReducer.loading ? (
                    <span
                      className="spinner-border spinner-border-sm me-1"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <FaChalkboardTeacher
                      className="fs-5"
                      style={{ color: "var(--main-color)" }}
                    />
                  )}
                </span>
                <div className="form-floating is-invalid">
                  <select
                    style={{ color: "var(--gray-color)" }}
                    id="lecture"
                    className={`form-select form-select-md rounded-0 rounded-start`}
                    aria-label="Large select example"
                    {...formik.getFieldProps("lecture")}
                    value={formik.values.lecture}
                  >
                    <option value="" disabled>
                      اختر المحاضره
                    </option>
                    {lectureData?.map((lecture) => (
                      <option
                        style={{ fontSize: "14px" }}
                        key={lecture?._id}
                        value={lecture?._id}
                      >
                        {`  محاضرة = ( ${lecture?.lecture} ) - لباب = ( ${lecture?.section?.name} ) - مستر = ( ${lecture?.section?.class?.teacher?.name} )`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="col-12">
              <button
                className="btn btn-primary px-4 py-2 fs-5 col-md-2 "
                type="submit"
              >
                انشاء
                {loading && view === "create" && (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
      {complete && (
        <PrintCoupons coupons={records?.data || []} type={chickState} />
      )}
    </>
  );
}
