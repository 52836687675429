import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import img from "../../../dist/img/Avatar-page.png"
import { useSelector } from 'react-redux';
export default function TeacherPage() {
    const [how, setHow] = useState("/me-teacher");
  const { records } = useSelector((state) => state.me)

    useEffect(() => {
      if (records.role === "manager") {
        setHow("/dashboard");
      }
    }, [records.role]);

  return (
    <Link className=" border  rounded-5 border-1 border-primary" to={how}>
      <img
        src={records.image || img}
        style={{ width: "45px", borderRadius: "50%" }}
        alt="teacher-img"
      />
    </Link>
  );
}
