import React, { useEffect, useState } from "react";
import css from "./Abda3Card.module.css";
import { useSelector } from "react-redux";
import chip from "../../dist/img/chip.png";
import logoLight from "../../dist/img/logo-abda3-leigth.png";
import logoDark from "../../dist/img/logo-abda3-dark.png";

export default function Abda3Card() {
  const [logoImg, setLogoImg] = useState();
  const { records } = useSelector((state) => state.me);
  const { theme } = useSelector((state) => state.theme);


  useEffect(() => {
    if (theme === "dark") {
      setLogoImg(logoDark);
    } else {
      setLogoImg(logoLight);
    }
  }, [theme]);
  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    return formattedDate;
  };

  return (
    <div className={css.blockCard}>
      <div className={css.card}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <div className={css.cardContent}>
          <div className={css.brand}>
            <img
              src={logoImg}
              alt="logo-abda3"
              style={{ width: "90px" }}
              loading="lazy"
            />
            <p>{records.name}</p>
          </div>
          <div className={css.points}>
            <h3 className="text-center  text-warning ">{`${records.point}  جنيه`}</h3>
          </div>
          <div className={css.info}>
            <div className={css.number}>
              <p>{`0${records.phone}`}</p>
              <img
                src={chip}
                alt="chip"
                style={{ width: "40px" }}
                loading="lazy"
              />
            </div>
            <div className={css.flexInfo}>
              <p>{handleExpiryTime(records.createdAt)}</p>
              <p>تاريخ الانشاء</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${css.circle} ${css.first}`}></div>
      <div className={`${css.circle} ${css.second}`}></div>
    </div>
  );
}
