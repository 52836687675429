import React, { useEffect, useState } from "react";
import imgLight from "../../dist/img/logo-abda3-leigth.png";
import imgDark from "../../dist/img/logo-abda3-dark.png";
import { useSelector } from "react-redux";
import css from "./LogoImg.module.css";

export default function LogoImg() {
  const { theme } = useSelector((state) => state.theme);
  const [img, setImg] = useState(imgLight);


  useEffect(() => {
    if (theme === "dark") {
      setImg(imgDark);
    } else {
      setImg(imgLight);
    }
  }, [theme]);
  return <img className={css.img} src={img} alt="logo-img" loading="lazy" />;
}
