import React from "react";
import css from "./Policies.module.css";
export default function TermsAndConditions() {
  return (
    <div className={css.container}>
      <div className="container">
        <h1 className="text-center">الشروط والاحكام</h1>
        <h4>
          أهلاً بكم في الموقع الإلكتروني لمنصة إبداع التعليمية -abda3Acadmy.com
          و/أو مواقعها الفرعية.
        </h4>
        <p>
          تم إبرام شروط وأحكام الاستخدام هذه ("شروط الاستخدام") بينك وبين منصة
          إبداع التعليمية ("إبداع"، "نحن"، "نا"). تقدم منصة إبداع التعليمية لك
          الموقع، بما في ذلك جميع المعلومات والخدمات التعليمية المتاحة، بشرط
          قبولك شروط الاستخدام هذه وسياسة الخصوصية الخاصة بنا.
        </p>
        <p>
          المعلومات التي يتم الحصول عليها من خلال هذا الموقع لا تغير أو تعدّل أو
          تحلّ محل أي أحكام أو شروط قد تكون موجودة في أي اتفاق آخر بينك وبيننا
          أو بين أي من شركائنا أو مزودي الخدمات.
        </p>
        <p>
          تحدد شروط الاستخدام هذه -إلى جانب أي مستندات مشار إليها هنا- الشروط
          التي بموجبها يمكنك الوصول إلى والاستفادة من خدماتنا، سواء كزائر أو
          مستخدم مسجل:
        </p>
        <ul>
          <li>موقعنا الإلكتروني</li>
          <li>تطبيق الهاتف المحمول الخاص بنا</li>
          <li>صفحاتنا على منصات التواصل الاجتماعي</li>
          <li>منصاتنا التقنية الأخرى، مهما كان شكلها.</li>
        </ul>
        <p>
          هذه هي وثيقة قانونية تحدد الحقوق والالتزامات بينك وبين منصة إبداع
          التعليمية، ويجب أن تُقرأ جنبًا إلى جنب مع سياسة الخصوصية الخاصة بنا.
          تحدد سياسة الخصوصية الخاصة بنا الشروط التي على أساسها نقوم بمعالجة أي
          بيانات شخصية يتم جمعها منك أو التي تقدمها لنا. يرجى قراءة شروط
          الاستخدام وسياسة الخصوصية بعناية قبل البدء في استخدام منصاتنا، حيث إن
          هذه الوثائق ستنطبق على استخدامك لتلك المنصات، بما في ذلك الدخول إليها،
          وتصفحها، والتسجيل فيها. باستخدام منصاتنا، فإنك تقر بالموافقة على شروط
          الاستخدام وسياسة الخصوصية الخاصة بنا، وتوافق على الالتزام بها. وفي حال
          عدم موافقتك على شروط الاستخدام وسياسة الخصوصية، يُرجى عدم استخدام
          منصاتنا أو الدخول إلى الموقع.
        </p>
        <h4>قيود السن</h4>
        <p>
          إذا كان عمرك أقل من 18 عاماً، فلا يجوز لك استخدام منصاتنا إلا تحت
          إشراف شخص يمارس سلطة الوالدين أو الوصاية عليك -أي أحد الوالدين أو
          الوصي-.
        </p>
        <h4>استخدام المحتويات على المنصات</h4>
        <p>
          يمكنك الدخول إلى المحتوى الموجود على المنصات ونسخه وطباعة المحتوى
          للاستخدام الشخصي غير التجاري، شريطة عدم تعديل أو حذف أي حقوق طبع ونشر،
          أو علامة تجارية، أو غير ذلك من إشعارات الملكية التي تظهر على المحتوى
          الذي تقوم بالدخول إليه أو نسخه أو طباعته. ويُحظَّر أي استخدام آخر
          للمحتوى الموجود على منصاتنا، بما في ذلك، على سبيل المثال لا الحصر،
          التعديل أو التغيير أو إنشاء أعمال مشتقة، والتوزيع، والإرسال، والتشغيل،
          والبث، والنشر، والرفع، والتخزين للاستخدام اللاحق، والترخيص أو الترخيص
          من الباطن كليًا أو جزئيًا بأي طريقة كانت دون الحصول على موافقة كتابية
          مُسبقة منا.
        </p>
        <p>
          هذا الترخيص المحدود المُشار إليه أعلاه قابل للإلغاء في أي وقت وحسب
          تقدير ابداع التعليمية وحدها. ابداع التعليمية لا تضمن ولا تقر بأن
          استخدامك للمحتوى على المنصات لا ينتهك حقوق أطراف أخرى غير تابعة ابداع
          التعليمية.
        </p>
        <p>
          عند تحميل أو تسجيل المحتوى، فإنك توافق على أنك تتحمل المسؤولية الكاملة
          عن أي استخدام غير مصرح به أو أي انتهاك للحقوق المتعلقة بالمحتوى. وتكون
          مسؤولًا قانونيًا عن أي أضرار أو عواقب قد تترتب على ذلك، كما يحق لنا
          اتخاذ الإجراءات القانونية اللازمة لحماية حقوقنا ومصالحنا.
        </p>
        <p>
          بهذا توافق على الالتزام بجميع القيود الإضافية المعروضة على منصاتنا،
          كما قد يتم تحديثها من وقت لآخر.
        </p>
        <p>
          لا يجوز لك استخدام المعلومات المقدمة على منصاتنا لأغراض غير مصرح بها
          أو غير قانونية أو غير مناسبة، بما في ذلك، على سبيل المثال لا الحصر،
          التسويق، أو إرسال رسائل غير مرغوب بها، أو رسائل تحرش، أو افتراء، أو
          رسائل تحريضية، أو للترويج لأعمال أو أنشطة غير قانونية، أو أي نشاط آخر
          يهدد سلامة أو أداء النظام الحاسوبي لأي شخص أو كيان آخر، أو ينتهك
          المعايير المقبولة عمومًا لاستخدام الإنترنت.
        </p>
        <p>
          كما يُمنع عليك استخدام أي معدات أو برامج تهدف إلى إلحاق الضرر أو
          التدخل في التشغيل السليم للمنصات، أو اعتراض أي نظام أو بيانات أو
          معلومات شخصية من المنصات سرًّا. أنت توافق أيضًا على عدم تعطيل أو
          محاولة تعطيل تشغيل الموقع بأي شكل من الأشكال. من خلال استخدامك للموقع،
          فإنك تقر بأنك لن تقوم بتعطيل أو محاولة تعطيل تشغيله بأي طريقة كانت.
        </p>
        <p>
          باستخدام منصاتنا، فإنك تضمن أنك تمتلك أي بيانات أو معلومات أو مواد
          -"المواد"- تقدمها لنا. كما أنك تضمن وتقر بأنه يحق لك نشر التعليقات
          والمواد على موقعنا، وأن لديك جميع التراخيص والموافقات اللازمة للقيام
          بذلك. تضمن ألا تحتوي أي تعليقات أو مواد على أي محتوى تشهيري أو افترائي
          أو مسيء أو غير لائق أو غير قانوني بأي شكل آخر، والذي يُعتبر انتهاكًا
          للخصوصية.
        </p>
        <p>
          نحن لا نشجعك على إرسال أو نشر أي معلومات تعتبرها ملكية خاصة بك أو سرية
          على الموقع. إن أي معلومات ترسلها أو تنشرها، سنفترض أنها ليست سرية ولا
          تنتهك أي حقوق ملكية فكرية لطرف ثالث. كما أنك تمنحنا بموجب شروط
          الاستخدام هذه ترخيصًا غير مقيّد وغير قابل للإلغاء لاستخدام المواد
          واستنساخها وعرضها وتنفيذها وتعديلها ونقلها وتوزيعها. وتوافق على أن لنا
          الحرية في استخدام أي أفكار أو مفاهيم أو معارف فنية أو تقنيات ترسلها أو
          تنشرها لأي غرض.
        </p>
        <h4>الوصول إلى منصاتنا</h4>
        <p>
          منصاتنا وأي محتوى فيها متاحة دائمًا أو دون انقطاع. كما نحتفظ بالحق في
          إيقاف جميع منصاتنا أو أي جزء منها مؤقتًا أو نهائيًا، أو سحبها أو
          تغييرها، دون إشعار مسبق. لن نكون مسؤولين تجاهك عن أي عدم توفر للمنصات
          لأي سبب أو في أي وقت أو لمدة زمنية معينة.
        </p>
        <p>
          تحتفظ العليمية بالحق في رفض أو إلغاء تسجيل أي شخص في منصاتها، بالإضافة
          إلى إزالة أو حظر أي شخص من استخدامها، وفقًا لتقديرها الخاص، في أي وقت
          ودون إشعار. لن يُعتبر إنهاء دخولك أو استخدامك للمنصات تنازلًا عن أي
          حق، ولن يؤثر على أي حق أو إجراء يحق ابداع التعليمية ممارسته قانونيًا
          ضدك.
        </p>
        <h4>خدمات الطرف الثالث</h4>
        <p>
          تؤدي بعض الروابط على منصاتنا إلى محتوى وموارد موجودة على مواقع
          إلكترونية وخوادم لا تخضع لسيطرة ابداع التعليمية أو أي مؤسسات أخرى غير
          تابعة لها. ويُشار إلى هذه المواقع الإلكترونية والموارد وأي منتجات أو
          خدمات مقدمة من خلالها بـ"خدمات الطرف الثالث". تعتبر أي روابط لخدمات
          الطرف الثالث مقدمة لك لغرض التسهيل فقط. لا تتحمل ابداع التعليمية أي
          مسؤولية عن صحة أو محتوى أو أي خاصية أخرى لأي من خدمات الطرف الثالث،
          سواء كانت مرتبطة بمنصاتنا أم لا، وتخلي أي وكل من مسؤوليتها عنها، وكذلك
          عن أي تبعات تنتج عن قرارك باستخدام أي من خدمات الطرف الثالث.
        </p>
        <p>إنك تقر وتوافق على ما يلي:</p>
        <ul>
          <li>
            لا يحق لك رفع دعوى أو تقديم مطالبة ضد إبداع التعليمية، أو شركائها،
            أو موظفيها، أو وكلائها، أو مقاوليها، أو مورديها، أو أيٍ منهم، وذلك
            عن أي مطالبات ناشئة عن أو مرتبطة بشرائك أو استخدامك للموارد أو
            المنتجات أو الخدمات من خلال أيٍ من خدمات الطرف الثالث.
          </li>
          <li>
            يحق لإبداع التعليمية أو مقدّم الخدمة للطرف الثالث تقييد أو إنهاء
            دخولك لخدمات الطرف الثالث في أي وقت.
          </li>
          <li>
            يخضع استخدامك لأيٍ من خدمات الطرف الثالث من خلال منصاتنا للشروط
            والأحكام والقيود التي قد تفرضها تلك الخدمة كشرط لاستخدامك لها،
            وتوافق على التقيد بجميع تلك القيود.
          </li>
        </ul>
        <h4>التعديلات والتحديثات على شروط الاستخدام</h4>
        <p>
          تؤدي بعض الروابط على منصاتنا إلى محتوى وموارد موجودة على مواقع
          إلكترونية وخوادم غير خاضعة لسيطرة إبداع التعليمية، أو أي مؤسسات أخرى
          غير تابعة لإبداع التعليمية. ويُشار إلى هذه المواقع الإلكترونية
          والموارد وأي منتجات وخدمات مقدمة من خلالها بـ "خدمات الطرف الثالث".
          تعتبر أي روابط لخدمات الطرف الثالث مقدمة لك بغرض التسهيل فقط.
        </p>
        <p>
          لا تتحمل إبداع التعليمية أي مسؤولية عن صحة أو محتوى أو أي خاصية أخرى
          لأي من خدمات الطرف الثالث، سواءً كانت مرتبطة بمنصاتنا أم لا، وتخلي أي
          وكل من مسؤوليتها عنها، وعن أي تبعات لقرارك باستخدام أي من خدمات الطرف
          الثالث.
        </p>
        <p>إنك تقر وتوافق على ما يلي: التغييرات والتحديثات على المنصات.</p>

        <h4>الفيروسات</h4>
        <p>
          لا نقر أو نضمن أن منصاتنا ستكون آمنة أو خالية من الفيروسات أو الأخطاء
          البرمجية. أنت مسؤول عن تهيئة تقنية المعلومات، وبرامج الكمبيوتر، ونظام
          التشغيل لديك من أجل الدخول إلى منصاتنا. وينبغي عليك استخدام أحد برامج
          الحماية من الفيروسات الخاصة بك.
        </p>
        <p>
          تتضمن الفيروسات، أحصنة طروادة، الديدان، القنابل المنطقية أو غيرها من
          المواد التي تعتبر ضارة تقنياً. ويجب ألا تحاول الحصول على دخول غير مصرح
          به إلى منصاتنا، أو الخادم الذي تم تخزين منصاتنا عليه، أو أي خادم أو
          كمبيوتر أو قاعدة بيانات متصلة بمنصاتنا. وبمخالفة كل ما سبق، فأنت ترتكب
          جريمة بموجب أنظمة المملكة العربية السعودية ودولٍ أخرى. وسوف نقوم
          بإبلاغ سلطات تطبيق النظام المختصة بأي مخالفة من هذا النوع والتنسيق
          معها للكشف عن هويتك. وفي حال ارتكاب هذه المخالفة، سيتوقف حقك في
          استخدام منصاتنا فوراً.
        </p>
        <p>
          لا نقر أو نضمن أن منصاتنا ستكون آمنة من الهجمات الإلكترونية التي قد
          تحاول تعطيل الدخول إلى منصاتنا أو الاستيلاء على البيانات الموجودة
          عليها. لذا فإن دخولك إلى منصاتنا وتقديم أي معلومات/ بيانات عليها،
          سيكون على مسؤوليتك الخاصة بالكامل.
        </p>
        <h4>الإعفاء من المسؤوليات</h4>
        <p>
          الإعفاء من المسؤوليات ابداع التعليمية غير مسؤولة إلى الحد المسموح به
          في جمهورية مصر العربية و الوطن العربي عن دخولك إلى منصاتنا واستخدامك
          لها. نحن لا نتحمل أي مسؤولية ولا نقدّم أي إقرارات فيما يتعلق بدقة أي
          معلومات تُعرض على الموقع. لذلك، يتم عرض جميع المواد والمعلومات كما هي،
          ونخلي مسؤوليتنا صراحةً من أي ضماناتٍ ضمنية أو صريحة لصلاحية العرض في
          السوق أو الملاءمة لغرض معيّن أو عدم التعدي على الملكية الفكرية
          المتعلقة بهذه المادة. ولن نكون مسؤولين بأي حال من الأحوال تجاهك أو
          تجاه أي شخص، سواءً مسؤولية تعاقدية أو تقصيرية أو غير ذلك، عن أي خسارة،
          أو مسؤولية، أو تكلفة، أو ضرر، أو إصابة أخرى أيا كان نوعها، بما في ذلك
          الأضرار التبعية، أو العرضية أو غير المباشرة أو الخاصة أو العقابية، بما
          في ذلك الأرباح المفقودة، وتعطل العمل، وفقدان البرامج أو البيانات
          الموجودة على أي أنظمة معالجة معلومات أو غير ذلك،
        </p>
        <p>
          والتي تكون ناتجة عن: أي دخول أو استخدام للمنصات أو المحتوى، أو نظراً
          لأي مشاكل اتصال قد تحدث في مقرك، حتى في حال لو تم إخطارك باحتمالية
          وقوعها. تعذر من قِبَلك في الدخول إلى المنصات أو المحتوى لأي سبب كان.
          أي هجوم إلكتروني على المنصات أو المحتوى، بما في ذلك أي سرقة أو فقدان
          للبيانات من المنصات أو المحتوى.{" "}
        </p>
        <p>
          أي فيروس، أو هجوم إلكتروني يهدف إلى حجب الخدمة، أو غير ذلك من المواد
          الضارة تقنياً التي قد تصيب أجهزة أو برامج الكمبيوتر لديك، أو البيانات
          أو غيرها من المواد مسجلة الملكية نتيجة استخدامك للمنصات أو المحتوى أو
          تحميلك أي محتوى عليها، أو على أي تطبيق، أو موقع إلكتروني، أو محتوى
          مرتبط بها.
        </p>
        <p>
          دون الإخلال بأيٍ مما سبق، وإلى أقصى حد تسمح به الأنظمة المعمول بها،
          تعفى ابداع التعليمية من أي مسؤولية بالنيابة عنها وعن أيٍ من الكيانات
          التابعة لها تجاه أي مطالبات أو مسائل تنشأ بموجب أو فيما يتعلق بشروط
          الاستخدام هذه. ولا يحق لك التقدم بمطالبة أو رفع دعوى فيما يتعلق
          بالمنصات أو المحتوى ضد أي كيان تابع ابداع التعليمية إلى الحد الذي لا
          يوجد فيه أي علاقة تعاقدية مباشرة بينك وبين تلك الكيانات. ولا يجوز لك
          التقدم بمطالبة أو رفع دعوى ضد ابداع التعليمية إلا على أساس أنها الطرف
          الذي تعاقدت معه بموجب شروط الاستخدام هذه. أنت توافق على حمايتنا
          والدفاع عنا ضد جميع المطالبات الناشئة عن المواد التي تزودنا بها. لن
          نكون مسؤولين عن أي محتوى يظهر على موقع الويب الخاص بك. يجب تقديم أي
          مطالبة متعلقة بالمنصات أو المحتوى أو غير ذلك بموجب شروط الاستخدام هذه
          خلال مدة لا تزيد عن (12) شهراً من تاريخ الفعل أو الإهمال الذي يزعم أنه
          تسبب في المطالبة.
        </p>
        <h4>الاتفاقية الكاملة</h4>
        <p>
          تمثل شروط الاستخدام هذه الاتفاقية الكاملة بينك وبين ابداع التعليمية
          لاستخدام هذا الموقع، وتسبق أي إخلاء مسئولية و/أو إخطار مرتبط بأي اتصال
          و/أو معلومات واردة منك
        </p>
      </div>
    </div>
  );
}
