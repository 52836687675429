import { useSelector } from "react-redux";
import AdminAddPoints from "./AdminAddPoints/AdminAddPoints";
import ManagerAddPoints from "./ManagerAddPoints/ManagerAddPoints";
export default function AddPoints() {
  const role = useSelector((state) => state.me.records.role);
  return (
    <>
      {role === "admin" ? (
        <AdminAddPoints />
      ) : role === "manager" ? (
        <ManagerAddPoints />
      ) : (
        ""
      )}
    </>
  );
}
