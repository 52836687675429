import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import { errorFunction } from "../hooks/ErrorFunction";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllSections = createAsyncThunk(
  "section/getAllSections",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/section");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث  خطاء في جلب الابواب",
          location: "الابواب",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSection = createAsyncThunk(
  "section/deleteSection",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/section/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف الباب بنجاح",
          location: "الابواب",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث  حذف في تعديل الباب",
          location: "الابواب",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createSection = createAsyncThunk(
  "section/createSection",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/section", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء الباب بنجاح",
          location: "الابواب",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث  خطاء في انشاء الباب"),
          location: "الابواب",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editSection = createAsyncThunk(
  "section/editSection",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/section/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل الباب بنجاح",
          location: "الابواب",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث  خطاء في تعديل الباب",
          location: "الابواب",
        })
      );
      return rejectWithValue(error);
    }
  }
);
const sectionSlice = createSlice({
  name: "section",
  initialState: initState,
  reducers: {
    resetSectionsData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getAllSections.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllSections.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllSections.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteSection
      .addCase(deleteSection.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createSection
      .addCase(createSection.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createSection.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createSection.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editSection
      .addCase(editSection.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editSection.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editSection.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetSectionsData } = sectionSlice.actions;
export default sectionSlice.reducer;
