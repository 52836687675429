import React, { useState, useEffect, useRef } from "react";
import { clearError } from "../../Store/ErrorSlice";
import { useDispatch } from "react-redux";

export default function AlertsModel({ data }) {
  const [showToast, setShowToast] = useState(true);
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (showToast) {
      intervalRef.current = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(intervalRef.current);
            return 100;
          }
          return prev + 1;
        });
      }, 30);

      const timeout = setTimeout(() => {
        setShowToast(false);
        setProgress(0);
        dispatch(clearError());
      }, 3000);
      return () => {
        clearInterval(intervalRef.current);
        clearTimeout(timeout);
      };
    }
  }, [showToast]);

  return (
    <div
      style={{ zIndex: "9999999" }}
      className="toast-container position-fixed bottom-0 start-0 p-3 "
    >
      <div
        id="liveToast"
        className={`toast ${showToast ? "show" : "hide"} border border-${
          data?.type === "error" ? "danger" : "success"
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div
          className={`toast-header blur  text-${
            data?.type === "error" ? "danger" : "success"
          } `}
        >
          <strong className="ms-auto">{data?.location}</strong>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowToast(false)}
            aria-label="Close"
          ></button>
        </div>
        <div className="toast-body p-0">
          <div
            className={`alert alert-${
              data?.type === "error" ? "danger" : "success"
            } h-100 mb-0 rounded-0`}
            role="alert"
          >
            <span>{data?.message?.message || data?.message}</span>
          </div>
        </div>
        <div
          style={{ height: "5px" }}
          className="progress"
          role="progressbar"
          aria-label="Success striped example"
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className={`progress-bar progress-bar-striped bg-${
              data?.type === "error" ? "danger" : "success"
            }`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}
