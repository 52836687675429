import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import css from "../ResetPasswordForm.module.css";
import { RiLockPasswordFill } from "react-icons/ri";
import { newPasswordValid } from "../../../../Util/VallationForms";
import { setNewPasswordStore } from "../../../../Store/ForgetPassword";

export default function SetNewPasswordCom() {
  const {  loading, emailChanged } = useSelector(
    (state) => state.forget
  );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: emailChanged,
      setNewPassword: "",
    },
    validationSchema: newPasswordValid,
    onSubmit: async (values) => {
      try {
        dispatch(setNewPasswordStore(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <div className={css.container}>
      <form
        className="d-flex gap-4 flex-column p-3"
        onSubmit={formik.handleSubmit}
      >
        <h6 className="text-center mb-4"> ادخل كلمة السر الجديدة </h6>
        <div className="input-group has-validation w-100">
          <span className="input-group-text rounded-0 rounded-end">
            <RiLockPasswordFill
              className="fs-4"
              style={{ color: "var(--main-color)" }}
            />
          </span>
          <div className="form-floating is-invalid">
            <input
              type="password"
              className={`form-control rounded-0 rounded-start ${
                formik.touched.setNewPassword &&
                formik.errors.setNewPassword &&
                "is-invalid"
              }`}
              // id="floatingInputGroup2"
              placeholder=""
              {...formik.getFieldProps("setNewPassword")}
            />
            <label
              style={{ color: "var(--gray-color)" }}
              htmlFor="floatingInputGroup2"
            >
              ادخل كلمة السر
            </label>
          </div>
          {formik.touched.setNewPassword && formik.errors.setNewPassword && (
            <div className="invalid-feedback">
              {formik.errors.setNewPassword}
            </div>
          )}
        </div>
        <button
          disabled={loading}
          type="button"
          onClick={formik.handleSubmit}
          className="btn btn-primary px-4 py-2 fs-5"
        >
          {loading ? "يتم الارسال" : "تأكيد كلمة السر "}
          {loading && (
            <>
              <span
                className="spinner-border spinner-border-sm mx-2"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden" role="status">
                Loading...
              </span>
            </>
          )}
        </button>
      </form>
    </div>
  );
}
