import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import { MdDelete, MdEdit } from "react-icons/md";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import {
  getAllLecture,
  resetLectureData,
  deleteLecture,
} from "../../../../Store/LectureSlice";
import LectureForm from "../../../../Components/Forms/LectureForm/LectureForm";
import AddVideoForm from "../../../../Components/Forms/AddVideoForm/AddVideoForm";
import { RiVideoAddLine } from "react-icons/ri";
import FilterDataForCoupons from "../../../../Components/Serches/FilterDataForTeacher/FilterDataForTeacher";
export default function CreateLecture() {
  const { records, loading, complete } = useSelector((state) => state.lecture);
  const meRedux = useSelector((state) => state.me);
  const dispatch = useDispatch();
  const [modelDelete, setModelDelete] = useState(false);
  const [addVideoModel, setAddVideoModel] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);
  const [lectureData, setLectureData] = useState(records?.data);

  useEffect(() => {
    dispatch(getAllLecture());
    return () => {
      dispatch(resetLectureData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllLecture());
    }
  }, [complete, dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  const handleAddVideo = useCallback((row) => {
    setSendData(row.original);
    setAddVideoModel((prevModel) => !prevModel);
  }, []);

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.lecture}) `);
      const dispatchData = () => {
        dispatch(deleteLecture([row?.original?.bunny, row?.original?._id]));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "lecture",
        header: "اسم المحاضره",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.lecture}</span>
          </>
        ),
      },
      {
        accessorKey: "price",
        header: "سعر المحاضره",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{`${row?.original?.price} ج.م`}</span>
          </>
        ),
      },
      {
        accessorKey: "description",
        header: " نبذة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{`${row?.original?.description?.slice(0, 20)}...`}</span>
          </>
        ),
      },
      {
        accessorKey: "pdf",
        header: "الpdf",
        flex: 1,
        Cell: ({ row }) => (
          <>
            {row?.original?.pdf ? (
              <span className="text-success">يوجد PDF</span>
            ) : (
              <span className="text-danger">لا يوجد PDF</span>
            )}
          </>
        ),
      },
      {
        accessorKey: "video",
        header: "فديو",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>
              {row?.original?.video ? (
                <span className="text-success">يوجد فديو</span>
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <MainBtn
                    btn={<RiVideoAddLine onClick={() => handleAddVideo(row)} />}
                  />
                </div>
              )}
            </span>
          </>
        ),
      },
      {
        accessorKey: "section",
        header: "اسم الباب",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.section?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "teacher",
        header: "اسم المدرس",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.section?.class?.teacher.name}</span>
          </>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex algin-items-center justify-content-center gap-2">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            {meRedux.records.role === "manager" && (
              <MainBtn
                btn={<MdDelete onClick={() => handleDeleteClick(row)} />}
              />
            )}
          </div>
        ),
      },
    ],
    [handleAddVideo, meRedux.records.role, handleEditClick, handleDeleteClick]
  );
  return (
    <>
      <div
        className="p-2 pb-3 rounded text-center mb-3"
        style={{ backgroundColor: "var(--light-color)" }}
      >
        <h3 className="text-center py-3">اختر المدرس</h3>
        <FilterDataForCoupons
          lectureData={records?.data}
          setLectureData={setLectureData}
        />
      </div>
      <h3
        style={{
          color: "var(--dark-color)",
          textShadow: "var(--main-color) 0px 0px 10px",
        }}
        className="text-center py-1"
      >
        {" "}
        المحاضرات
      </h3>
      <MainTable
        data={lectureData || records?.data || []}
        columns={columns}
        isLoading={loading}
        form={<LectureForm type="add" SendData={[]} />}
      />
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<LectureForm type="edit" SendData={sendData} />}
        />
      )}
      {addVideoModel && (
        <MainModel
          setModel={setAddVideoModel}
          content={<AddVideoForm type="add" SendData={sendData} />}
        />
      )}
    </>
  );
}
