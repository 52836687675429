import { useEffect, useMemo } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTransactionsData,
  getMeReceiver,
} from "../../../Store/TransactionsSlice.js";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel.jsx";
import img from "../../../dist/img/notTransaction.webp";
import MainTable from "../../../Components/tables/MainTable/MainTable.jsx";
import { Link } from "react-router-dom";

export default function USerRechargeBalance() {
  const meRedux = useSelector((state) => state.me);
  const { records, loading, error } = useSelector((state) => state.transaction);

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(getMeReceiver(meRedux?.records?._id));
    } catch (err) {
      console.error(err);
    }
    return () => {
      dispatch(resetTransactionsData());
    };
  }, [dispatch, meRedux._id]);

  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    let formattedTime = date.toLocaleTimeString("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedTime} - ${formattedDate}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "sender",
        header: "الراسل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.sender?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "receiver",
        header: "المستلم",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.receiver?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "pointsSent",
        header: "النقاط المرسلة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.pointsSent}</span>
            <span>{" جنيه"}</span>
          </>
        ),
      },
      {
        accessorKey: "date",
        header: "التاريخ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{handleExpiryTime(row?.original?.date)}</span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="container d-flex align-items-center justify-content-end w-100 pt-1">
        <Link
          to="/checkout"
          className="btn btn-primary d-flex align-items-center justify-content-center gap-1"
        >
          شحن المحفظة <IoLogoWhatsapp className="fs-5" />
        </Link>
      </div>
      <h3
        style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
        className="text-center py-4 px-2"
      >
        جميع التحويلات التي تم استقبالها علي المحفظة
      </h3>
      {records?.data?.length > 0 ? (
        <MainTable
          data={records?.data || []}
          columns={columns}
          isLoading={loading}
          screen={true}
        />
      ) : (
        <div className="text-center">
          <h6
            style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
            className="text-center pt-4  p-2"
          >
            ليس هناك معاملا علي المحفظة بعد ...
          </h6>
          <img alt="" src={img} style={{ width: "200px" }} loading="lazy" />
        </div>
      )}
      {error && (
        <AlertsModel
          error={error}
          msg={" حدث خطاء في جلب المعاملات التي استلمتها"}
          place="شحن المحفظه"
        />
      )}
    </>
  );
}
