import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthBtn from "../../Buttons/AuthBtn/AuthBtn";
import BtnFullscreen from "../../Buttons/BtnFullscreen/BtnFullscreen";
import DarkLight from "../../Buttons/DarkLigth/DarkLigth";
import Wallet from "../../Buttons/Wallet/Wallet";
import Cookies from "js-cookie";
import { getMe } from "../../../Store/getMeSlice.js";
import { useDispatch, useSelector } from "react-redux";
import LogoImg from "../../LogoImg/LogoImg.jsx";
import TeacherPage from "../../Buttons/TeacherPage/TeacherPage.jsx";
import { resetCourseData } from "../../../Store/CourseSlice.js";

export default function HomeHeader() {
  const token = Cookies.get("access_token");
  const { records, loading, complete, error } = useSelector(
    (state) => state.me
  );
  const courseRedux = useSelector((state) => state.course);

  const dispatch = useDispatch();
  useEffect(() => {
    if (token && !complete && !loading && !error) {
      dispatch(getMe());
    }
  if (courseRedux.complete) {
      dispatch(resetCourseData());
      dispatch(getMe());
    }
  }, [complete, courseRedux.complete, dispatch, error, loading, token]);

  const [scrollY, setScrollY] = useState(0);
  const location = useLocation();

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [location.pathname]);

  const headerOpacity =
    location.pathname === "/" ? Math.min(1, scrollY / 10) : 1;

  return (
    <nav
      className="position-fixed w-100 navbar navbar-expand-lg p-1"
      style={{
        opacity: headerOpacity,
        backgroundColor: "var(--light-color)",
        boxShadow: "var(--header-box-shadow)",
        zIndex: "1000",
        transition: "var(--main-transition)",
      }}
    >
      <div className="container">
        <Link
          style={{ color: "var(--main-color)" }}
          className="navbar-brand"
          to="/"
        >
          <LogoImg />
        </Link>
        <div className="d-flex justify-content-end align-items-center flex-grow-1 gap-1  gap-md-3 ps-2 ps-md-3">
          {token && (records?.role === "admin" || records?.role === "user") && (
            <Wallet />
          )}
          <DarkLight />
          <BtnFullscreen />
        </div>
        {token &&
          (records?.role === "teacher" || records?.role === "manager") && (
            <TeacherPage />
          )}
        <AuthBtn />
      </div>
    </nav>
  );
}
