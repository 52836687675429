import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import {errorFunction} from "../hooks/ErrorFunction"

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllClass = createAsyncThunk(
  "class/getAllClass",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/class");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب الصفوف",
          location: "الصفوف",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteClass = createAsyncThunk(
  "class/deleteClass",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/class/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف الصف بنجاح",
          location: "الصفوف",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف الصف",
          location: "الصفوف",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createClass = createAsyncThunk(
  "class/createClass",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/class", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء الصف بنجاح",
          location: "الصفوف",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في انشاء الصف"),
          location: "الصفوف",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editClass = createAsyncThunk(
  "class/editClass",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/class/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل الصف بنجاح",
          location: "الصفوف",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل الصف",
          location: "الصفوف",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const classSlice = createSlice({
  name: "class",
  initialState: initState,
  reducers: {
    resetClassData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllClass
      .addCase(getAllClass.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllClass.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllClass.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteClass
      .addCase(deleteClass.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteClass.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createClass
      .addCase(createClass.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createClass.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createClass.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editClass
      .addCase(editClass.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editClass.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editClass.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetClassData } = classSlice.actions;
export default classSlice.reducer;
