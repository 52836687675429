import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setError } from "./ErrorSlice";
import axios from "axios";
import { editLecture } from "./LectureSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
  uploadProgress: 0,
};

export const UploadVideo = createAsyncThunk(
  "video/UploadVideo",
  async ([bunny, video, id], { rejectWithValue, dispatch }) => {
    const libraryID = `${bunny.videoLibraryId}`;
    const videoID = `${bunny.guid}`;
    const ApiKey = `${bunny.key}`;
    try {
      const response = await axios
        .put(
          `https://video.bunnycdn.com/library/${libraryID}/videos/${videoID}`,
          video,
          {
            headers: {
              "Content-Type": "application/octet-stream",
              AccessKey: ApiKey,
            },
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              dispatch(setUploadProgress(percentCompleted));
            },
          }
        )
        .then((EditVideo) => {
          dispatch(editLecture([id, { video: "true" }]));
          return EditVideo;
        })
        .then((d) => {
          dispatch(
            setError({
              type: "complete",
              message: "تم رفع الفيديو بنجاح",
              location: " رفع الفيديو",
            })
          );
          return d;
        });
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في رفع الفيديو  علي  السيرفر  ",
          location: " رفع الفيديو",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const VideoSlice = createSlice({
  name: "video",
  initialState: initState,
  reducers: {
    resetVideoData(state) {
      state.error = false;
      state.complete = false;
      state.uploadProgress = 0; // إعادة تعيين التقدم
    },
    setUploadProgress(state, action) {
      state.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(UploadVideo.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(UploadVideo.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
        state.uploadProgress = 100; // التقدم مكتمل
      })
      .addCase(UploadVideo.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
        state.uploadProgress = 0;
      });
  },
});
export const { resetVideoData, setUploadProgress } = VideoSlice.actions;
export default VideoSlice.reducer;
