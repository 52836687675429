import React from "react";
import css from "./WatchLectureBtn.module.css";
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function WatchLectureBtn({ LectureId, courseId }) {
  return (
    <Link
      to={`/lecture-Watch/${LectureId}/${courseId}`}
      type="button"
      className={css.btn}
    >
      <strong>
        <FaRegEye className="fs-6 me-1" />
        {"  "}
        مشاهدة
      </strong>
      <div className={css.containerStars}>
        <div className={css.stars}></div>
      </div>
      <div className={css.glow}>
        <div className={css.circle}></div>
        <div className={css.circle}></div>
      </div>
    </Link>
  );
}
