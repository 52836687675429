import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import {errorFunction} from "../hooks/ErrorFunction"

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllLecture = createAsyncThunk(
  "lecture/getAllLecture",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/lecture?limit=3000");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب المحاضرات ",
          location: "  المحاضرات",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getLectureByID = createAsyncThunk(
  "lecture/getLectureByID",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/lecture/${id}`);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب المحاضره ",
          location: "  المحاضرات",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteLecture = createAsyncThunk(
  "lecture/deleteLecture",
  async ([bunny, id], thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    const libraryID = `${bunny.videoLibraryId}`;
    const videoID = `${bunny.guid}`;
    const ApiKey = `${bunny.key}`;
    try {
      await axiosInstance.delete(`/lecture/${id}`).then(() => {
        try {
          const options = {
            method: "DELETE",
            headers: {
              accept: "application/json",
              AccessKey: ApiKey,
            },
          };
          fetch(
            `https://video.bunnycdn.com/library/${libraryID}/videos/${videoID}`,
            options
          )
            .then((response) => response.json())
            .then((response) => console.log(response))
            .catch((err) => console.error(err));
        } catch (err) {
          dispatch(
            setError({
              type: "error",
              message: "حدث خطاء ما في حذف الفيديو من قاعدة البيانات ",
              location: "  المحاضرات",
            })
          );
          console.log(err);
        }
      });
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف المحاضره بنجاح",
          location: " المحاضرات",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف المحاضره ",
          location: "  المحاضرات",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createLecture = createAsyncThunk(
  "lecture/createLecture",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/lecture", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء المحاضره بنجاح",
          location: " المحاضرات",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في انشاء المحاضره "),
          location: "  المحاضرات",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editLecture = createAsyncThunk(
  "lecture/editLecture",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/lecture/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل المحاضره بنجاح",
          location: " المحاضرات",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل المحاضره ",
          location: "  المحاضرات",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const lectureSlice = createSlice({
  name: "lecture",
  initialState: initState,
  reducers: {
    resetLectureData(state) {
      state.error = false;
      state.complete = false;
      state.records = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllLecture
      .addCase(getAllLecture.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllLecture.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllLecture.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // getLectureByID
      .addCase(getLectureByID.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getLectureByID.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getLectureByID.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteLecture
      .addCase(deleteLecture.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteLecture.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteLecture.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createLecture
      .addCase(createLecture.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createLecture.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createLecture.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editLecture
      .addCase(editLecture.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editLecture.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editLecture.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetLectureData } = lectureSlice.actions;
export default lectureSlice.reducer;
