import css from "./TeacherProfile.module.css";
import { IoHandRightSharp } from "react-icons/io5";
import HonorBoardSlider from "../../../Components/Sliders/HonorBoardSlider/HonorBoardSlider";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getTeacherByID } from "../../../Store/TeacherSlice.js";
import LoadingModel from "../../../Model/Loading/Loading.jsx";
import { useParams } from "react-router-dom";
import TeacherClasses from "../../../Components/TeacherClasses/TeacherClasses.jsx";
import { Link } from "react-scroll";

export default function TeacherProfile() {
  const { records, loading } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });
      dispatch(getTeacherByID(id));

    } catch (err) {
      console.error(err);
    }
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <LoadingModel />
      ) : (
        <div className={` ${css.container}`}>
          <div className={`container`}>
            <div className={`row ${css.profile}`}>
              <div className={`col-lg-6 ${css.imgContainer}`}>
                <img
                  src={records?.teacher?.image}
                  alt="profile-teacher"
                  loading="lazy"
                />
              </div>
              <div className={`col-lg-6 ${css.infoContainer}`}>
                <h1>
                  مرحبا <IoHandRightSharp className={css.hiIcone} />
                </h1>
                <h2>
                  الاسم: <span>{records?.teacher?.name}</span>
                </h2>
                <h3>
                  مدرس مادة : <span>{records?.teacher?.subject}</span>
                </h3>
                <p
                  className={css.paragraph}
                  title={records?.teacher?.description}
                >
                  {`${records?.teacher?.description?.slice(0, 650)}....`}
                </p>
                <Link to="class" smooth={true} duration={500}>
                  <div className="text-center">
                    <button className="btn btn-primary">
                      {" "}
                      فصولي الدراسيه{" "}
                    </button>
                  </div>
                </Link>
              </div>
            </div>
            <HonorBoardSlider data={records?.gallery} />
            <div id="class">
              <TeacherClasses data={records} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
