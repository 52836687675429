import * as Yup from "yup";

export const loginFormValid = Yup.object().shape({
  email: Yup.string().required("الاميل مطلوب").email("ادخل اميل صحيح"),
  password: Yup.string().required("كلمة السر مطلوبه"),
});

// ResetPasswordValid

export const ResetPasswordValid = Yup.object().shape({
  email: Yup.string().required("الاميل مطلوب").email("ادخل اميل صحيح"),
});

// VerifyCodeForgetPasswordValid;

export const VerifyCodeForgetPasswordValid = Yup.object({
  code: Yup.array()
    .required("جميع الحقول مطلوبة")
    .test(
      "all-filled",
      "جميع الحقول مطلوبة",
      (value) =>
        value &&
        value.every(
          (item) => item !== null && item !== undefined && item !== ""
        )
    ),
});

// newPasswordValid

export const newPasswordValid = Yup.object().shape({
  setNewPassword: Yup.string()
    .required("كلمة السر مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),
});

// signupFormValid

export const signupFormValid = Yup.object().shape({
  name: Yup.string()
    .required("الاسم مطلوب")
    .min(6, "الاسم ثنائي لا يجب أن يقل عن 6 أحرف")
    .test("is-full-name", "يجب إدخال الاسم ثنائي", function (value) {
      return value && value.trim().split(/\s+/).length > 1;
    }),
  email: Yup.string().required("الاميل مطلوب").email("ادخل اميل صحيح"),
  password: Yup.string()
    .required("كلمة السر مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),

  passwordConfirm: Yup.string()
    .required("تأكيد كلمة السر مطلوبة")
    .test(
      "passwords-match",
      "يجب ان تكون كلمة السر متشابها ",
      function (value) {
        return this.parent.password === value;
      }
    ),
  phone: Yup.string()
    .required("ادخل رقم الهاتف للمستخدم")
    .matches(/^01\d{9}$/, "ادخل رقم هاتف صحيح")
    .max(11, "ادخل رقم هاتف صحيح")
    .min(11, "ادخل رقم هاتف صحيح"),
  guardianPhone: Yup.string()
    .required("ادخل رقم الهاتف للمستخدم")
    .matches(/^01\d{9}$/, "ادخل رقم هاتف صحيح")
    .max(11, "ادخل رقم هاتف صحيح")
    .min(11, "ادخل رقم هاتف صحيح"),
  address: Yup.string().required("المحافظه مطلوبة"),
  grade: Yup.string().required("الصف الدراسي مطلوب"),
});

export const createCouponsValid = Yup.object().shape({
  count: Yup.number()
    .required("العدد مطلوب")
    .max(10000, "العدد يجب أن لا يزيد عن 10000"),
  expires: Yup.number().required("مدة الانتهاء مطلوبة"),
  discount: Yup.number().required(" نسبة الخصم مطلوبة"),
});

// searchUserFromPhone

export const searchUserFromPhone = Yup.object().shape({
  phone: Yup.string()
    .required("ادخل رقم الهاتف للمستخدم")
    .matches(/^01\d{9}$/, "ادخل رقم هاتف صحيح")
    .max(11, "ادخل رقم هاتف صحيح")
    .min(11, "ادخل رقم هاتف صحيح"),
});

// addAdminPointsToUser

export const addAdminPointsToUser = Yup.object().shape({
  point: Yup.number().required(" هذا الحقل مطلوب"),
});

//addManagerPointsToAdmin

export const addManagerPointsToAdmin = Yup.object().shape({
  point: Yup.number().required(" هذا الحقل مطلوب"),
  admin: Yup.string().required(" هذا الحقل مطلوب"),
});

//createAdminFormValid

export const createAdminFormValid = Yup.object().shape({
  name: Yup.string()
    .required("الاسم مطلوب")
    .min(6, "الاسم ثنائي لا يجب ان يقل عن 6 احرف")
    .test("is-full-name", "يجب ادخال الاسم ثنائي", function (value) {
      return value && value.trim().split(/\s+/).length >= 2;
    }),
  email: Yup.string().required("الاميل مطلوب").email("ادخل اميل صحيح"),
  password: Yup.string()
    .required("كلمة السر مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),
  passwordConfirm: Yup.string()
    .required("تأكيد كلمة السر مطلوبة")
    .test(
      "passwords-match",
      "يجب ان تكون كلمة السر متشابها ",
      function (value) {
        return this.parent.password === value;
      }
    ),
  phone: Yup.string()
    .required("ادخل رقم الهاتف للمستخدم")
    .matches(/^01\d{9}$/, "ادخل رقم هاتف صحيح")
    .max(11, "ادخل رقم هاتف صحيح")
    .min(11, "ادخل رقم هاتف صحيح"),
  image: Yup.mixed()
    .required("يرجى إدخال صورة")
    .test("fileType", "يرجى رفع صورة فقط", (value) => {
      return (
        value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
      );
    }),
});
//createTeacherFormValid

export const createTeacherFormValid = Yup.object().shape({
  name: Yup.string()
    .required("الاسم مطلوب")
    .min(6, "الاسم ثنائي لا يجب ان يقل عن 6 احرف")
    .test("is-full-name", "يجب ادخال الاسم ثنائي", function (value) {
      return value && value.trim().split(/\s+/).length >= 2;
    }),
  email: Yup.string().required("الاميل مطلوب").email("ادخل اميل صحيح"),
  phone: Yup.string()
    .required("ادخل رقم الهاتف للمستخدم")
    .matches(/^01\d{9}$/, "ادخل رقم هاتف صحيح")
    .max(11, "ادخل رقم هاتف صحيح")
    .min(11, "ادخل رقم هاتف صحيح"),
  image: Yup.mixed().required("يرجى إدخال صورة"),
  description: Yup.string()
    .required("النبذة عن المدرس مطلوبة")
    .min(20, "النبذة لايجب ان تقل عن 20 احرف  "),
  subject: Yup.string().required(" تخصص لبمدرس مطلوب"),
});

// classFormValid

export const classFormValid = Yup.object().shape({
  name: Yup.string().required("  الاسم مطلوب"),
  image: Yup.mixed().required("يرجى إدخال صورة"),
  // teacher: Yup.string().required("المدرس مطلوب"),
});

// chapterFormValid
export const sectionFormValid = Yup.object().shape({
  name: Yup.string().required("  الاسم مطلوب"),
  image: Yup.mixed().required("يرجى إدخال صورة"),
  class: Yup.string().required("الفصل مطلوب"),
  price: Yup.number().required("السعر مطلوب"),
  description: Yup.string().required("النبذة مطلوبه"),
});

// LectureFormValid
export const LectureFormValid = Yup.object().shape({
  lecture: Yup.string().required("  الاسم مطلوب"),
  price: Yup.number().required("السعر مطلوب"),
  description: Yup.string().required("النبذة مطلوبه"),
  section: Yup.string().required("الفصل مطلوب"),
  // pdf: Yup.mixed().test(
  //   "fileSize",
  //   "الحجم الأقصى المسموح به للملف هو 25 ميجا بايت",
  //   (value) => {
  //     if (!value || typeof value === "string") return true;
  //     return value.size <= 25 * 1024 * 1024;
  //   }
  // ),
});

// UserChangePasswordValidation
export const UserChangePasswordValidation = Yup.object().shape({
  currentPasword: Yup.string()
    .required("كلمة السر القديمة مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),
  password: Yup.string()
    .required("كلمة السر الجديدة مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),
  passwordConfirm: Yup.string()
    .required(" تأكيد كلمة السر الجديدة مطلوبة")
    .min(8, "كلمة السر لايجب ان تقل عن 8 احرف او ارقام"),
});

// galleryTeacherFormValid
export const galleryTeacherFormValid = Yup.object().shape({
  teacher: Yup.string().required("  الاسم مطلوب"),
  image: Yup.mixed().required("يرجى إدخال صورة"),
});
// honourSliderFormValid
export const honourSliderFormValid = Yup.object().shape({
  name: Yup.string().required("  الاسم مطلوب"),
  image: Yup.mixed().required("يرجى إدخال صورة"),
});
// videoUploadFormValid
export const videoUploadFormValid = Yup.object().shape({
  video: Yup.mixed().required("يرجى إدخال فيديو"),
});

export const sliderHeaderHomeFormValid = Yup.object().shape({
  image: Yup.mixed().required("يرجى إدخال صورة"),
});
export const AddManagerAndAdminImgFormValid = Yup.object().shape({
  image: Yup.mixed().required("يرجى إدخال صورة"),
});
