import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSlider,
  resetSliderData,
} from "../../../../Store/HeaderSliderSlice";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import { deleteSlider } from "../../../../Store/HeaderSliderSlice";
import css from "./CreateHeaderSlider.module.css";
import { IoMdAddCircleOutline } from "react-icons/io";
import MainModel from "../../../../Model/mainModel/MainModel";
import HeaderSliderForm from "../../../../Components/Forms/HeaderSliderForm/HeaderSliderForm";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
export default function CreateHeaderSlider() {
  const { records, complete } = useSelector((state) => state.slider);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [modelDelete, setModelDelete] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(null);
  const [openAddModel, setOpenAddModel] = useState(null);
  const [sendData, setSendData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getAllSlider());
    } catch (err) {
      console.error(err);
    }
    return () => {
      dispatch(resetSliderData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllSlider());
    }
  }, [complete, dispatch]);

  const handleEditClick = (item) => {
    setSendData(item);
    setOpenEditModel((prevModel) => !prevModel);
  };
  const handleAddClick = () => {
    setSendData([]);
    setOpenAddModel((prevModel) => !prevModel);
  };

  const handleDeleteClick = (item) => {
    setModelDelete((prevModelDelete) => !prevModelDelete);
    setDeleteMessage(`هل انت متأكد من حذف الصوره`);
    const dispatchData = () => {
      dispatch(deleteSlider(item?._id));
    };
    setSelectedRowForDelete(() => dispatchData);
  };

  return (
    <>
      <h3
        className="text-center py-3"
        style={{ textShadow: "var(--main-color) 0px 0px 10px" }}
      >
        سليدر الصفحة الرئيسيه
      </h3>
      <div className="d-flex align-items-center justify-content-center py-3">
        <button className={css.button} onClick={handleAddClick}>
          اضف صوره جديدة <IoMdAddCircleOutline className="fs-3" />
        </button>
      </div>
      <div className="container">
        <div className={"row"}>
          {records?.data?.map((item, key) => (
            <div
              key={key}
              className={`col-lg-4 col-md-6 col-sm-12 mb-4 ${css.container}`}
            >
              <div
                className={`card ${css.card}`}
                style={{ width: "100%", height: "100%" }}
              >
                <img
                  src={item.image}
                  className="w-100 h-auto"
                  alt="aa"
                  loading="lazy"
                />
                <div
                  className={`d-flex align-items-center justify-content-center gap-4 ${css.buttons}`}
                >
                  <MainBtn
                    btn={<MdEdit onClick={() => handleEditClick(item)} />}
                  />
                  <MainBtn
                    btn={<MdDelete onClick={() => handleDeleteClick(item)} />}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {openEditModel && (
        <MainModel
          setModel={setOpenEditModel}
          content={<HeaderSliderForm type="edit" SendData={sendData} />}
        />
      )}
      {openAddModel && (
        <MainModel
          setModel={setOpenAddModel}
          content={<HeaderSliderForm type="add" SendData={sendData} />}
        />
      )}
    </>
  );
}
