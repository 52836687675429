import React, { useEffect, useState } from "react";
import css from "./SectionFreeVideoComponent.module.css";
import logoDark from "../../dist/img/logo-abda3-dark.png";
import logoLight from "../../dist/img/logo-abda3-leigth.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function SectionFreeVideoComponent() {
  const { theme } = useSelector((state) => state.theme);
  const meRedux = useSelector((state) => state.me);

  const [img, setImg] = useState(logoLight);

  useEffect(() => {
    if (theme === "dark") {
      setImg(logoDark);
    } else {
      setImg(logoLight);
    }
  }, [theme]);

  return (
    <div className="container">
      <div className={`my-4  row gap-4 rounded-2 ${css.container}`}>
        <div className="col-12 col-md-5 order-2 order-md-1  d-flex align-items-center justify-content-center flex-column gap-2 ">
          <h4 style={{ color: "var(--main-color)" }}>
            يمكنك الانتقال الي الفيديوهات من هنا
          </h4>
          <h1 style={{ fontSize: "40px" }} className="fw-bold">
            الفيديوهات
          </h1>
          <div className=" d-flex align-items-center justify-content-center gap-2">
            {meRedux.records.role === "user" && (
              <Link
                to="/me/subscriptions"
                className="btn  btn-outline-warning "
              >
                فيديوهاتي
              </Link>
            )}
            <Link to="free-videos" className="btn btn-danger">
              الفيديوهات المجانيه
            </Link>
          </div>
        </div>
        <div className="col-12 col-md-6 order-1 order-md-2  d-flex align-items-center justify-content-center">
          <img
            className="w-100 h-auto"
            src={img}
            alt="logo-img"
            style={{ transition: "0.5s" }}
          />
        </div>
      </div>
    </div>
  );
}
