import React, { useState } from "react";
import css from "./BtnBuy.module.css";
import { HiShoppingCart } from "react-icons/hi";
import MainModel from "../../../Model/mainModel/MainModel";

import BuyFormModel from "../../Forms/BuyFormModel/BuyFormModel";
import Cookies from "js-cookie";
import DeleteBtn from "../DeleteBtn/DeleteBtn";
import { useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";

export default function BtnBuy({ data, type }) {
  const [openModel, setOpenModel] = useState(false);
  // const { records } = useSelector((state) => state.me);
  const cookie = Cookies.get("access_token");
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };
  // const goToVerify = () => {
  //   navigate("/signup/verify");
  // };

  return (
    <>
      <button
        onClick={() => setOpenModel(true)}
        type="button"
        className={css.btn}
      >
        <strong>
          {`شراء ال${type === "section" ? "باب" : "محاضرة"}`}{" "}
          <HiShoppingCart className="fs-6" />
        </strong>
        <div className={css.containerStars}>
          <div className={css.stars}></div>
        </div>
        <div className={css.glow}>
          <div className={css.circle}></div>
          <div className={css.circle}></div>
        </div>
      </button>
      {openModel &&
        cookie &&
          <MainModel
            setModel={setOpenModel}
            content={<BuyFormModel type={type} data={data} />}
          />
      }
      {openModel && !cookie && (
        <MainModel
          setModel={setOpenModel}
          content={
            <DeleteBtn
              setModel={setOpenModel}
              msg={`يجب تسجيل الدخول اولا`}
              deleteData={goToLogin}
            />
          }
        />
      )}
    </>
  );
}
