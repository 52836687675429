import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../../../../Store/getMeSlice";

export default function AdminMangerInfo() {
  const { records } = useSelector((state) => state.me);
  const { complete } = useSelector((state) => state.points);
  const dispatch = useDispatch();

  useEffect(() => {
    if (complete) {
      try {
        dispatch(getMe());
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }, [complete, dispatch]);

  return (
    <div
      className="p-2 rounded mb-3 text-center"
      style={{ backgroundColor: "var(--light-color)" }}
    >
      <h3>
        مرحبا بك{" "}
        <span style={{ color: "var(--main-color)" }}>{records?.name}</span>
      </h3>
      <h5>
        النقاط التي لديك{" "}
        <span style={{ color: "var(--main-color)" }}>{records?.point}</span>
      </h5>
    </div>
  );
}
