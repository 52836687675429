import { useDispatch, useSelector } from "react-redux";
import Abda3Card from "../../../Components/Abda3Card/Abda3Card";
import {
  getAllTransactions,
  resetTransactionsData,
} from "../../../Store/TransactionsSlice";

import { useEffect, useMemo } from "react";
import MainTable from "../../../Components/tables/MainTable/MainTable";
import img from "../../../dist/img/notTransaction.webp";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel";
export default function WalletUser() {
  const meRedux = useSelector((state) => state.me);
  const { records, loading, error } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();


  useEffect(() => {
    try {
      dispatch(getAllTransactions());
    } catch (err) {
      console.error(err);
    }
    return () => {
      dispatch(resetTransactionsData());
    };
  }, [dispatch]);

const handleExpiryTime = (expires) => {
  let date = new Date(expires);
  let formattedDate = date.toLocaleDateString("ar-EG");
  let formattedTime = date.toLocaleTimeString("ar-EG", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${formattedTime} - ${formattedDate}`;
};

  const columns = useMemo(
    () => [
      {
        accessorKey: "sender",
        header: "الراسل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              className={`${
                row?.original?.sender?.name === meRedux.records.name
                  ? " text-danger fs-6"
                  : ""
              }`}
            >
              {row?.original?.sender?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "receiver",
        header: "المستلم",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              className={`${
                row?.original?.receiver?.name === meRedux.records.name
                  ? " text-success fs-6"
                  : ""
              }`}
            >
              {row?.original?.receiver?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "pointsSent",
        header: "النقاط المرسلة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>
              {row?.original?.pointsSent}
              {" جنيه"}
            </span>
          </>
        ),
      },
      {
        accessorKey: "date",
        header: "التاريخ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{handleExpiryTime(row?.original?.date)}</span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Abda3Card />
      <h3
        style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
        className="text-center py-4 px-2"
      >
        جميع التحويلات علي المحفظة
      </h3>
      {records?.data?.length > 0 ? (
        <MainTable
          data={records?.data || []}
          columns={columns}
          isLoading={loading}
          screen={true}
        />
      ) : (
        <div className="text-center">
          <h6
            style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
            className="text-center pt-4  p-2"
          >
            ليس هناك معاملا علي المحفظة بعد ...
          </h6>
          <img alt="" src={img} style={{ width: "200px" }} loading="lazy" />
        </div>
      )}
      {error && (
        <AlertsModel
          error={error}
          msg={"حدث خطاء في جلب المعاملات"}
          place="محفظتي"
        />
      )}
    </>
  );
}
