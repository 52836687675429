import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdAdd } from "react-icons/md";

import AdminSearchUser from "../../../../Components/Forms/AdminSearchUser/AdminSearchUser";
import AdminMangerInfo from "../AdminMangerInfo/AdminMangerInfo";
import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";
import { addPointsAdmin, resetDataPoint } from "../../../../Store/AddPoints";
import { resetDataUser } from "../../../../Store/UserSlice";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import MainModel from "../../../../Model/mainModel/MainModel";
import {setError} from "../../../../Store/ErrorSlice"

export default function AdminAddPoints() {
  const dispatch = useDispatch();
  const { records, complete } = useSelector((state) => state.users);
  const [point, setPoint] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [sendFunction, setSEndFunction] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetDataPoint());
      dispatch(resetDataUser());
    };
  }, [dispatch]);

  const handelAddPoints = (e) => {
    try {
      if (point >= 1) {
        setOpenModel((prev) => !prev);
        const sendDispatch = () => {
          dispatch(addPointsAdmin({ receiver: e._id, points: point }));
        };
        setSEndFunction(() => sendDispatch);
      } else {
            dispatch(
              setError({
                type: "error",
                message:"يجب اضافة نقاط لأرسالها  ",
                location: "اضافة النقاط",
              })
            );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <AdminMangerInfo />
      <AdminSearchUser />
      {complete && records?.data.length !== 0 && (
        <div
          className="p-2 rounded text-center"
          style={{ backgroundColor: "var(--light-color)" }}
        >
          <table
            id="myTable"
            className="table table-striped table-hover table-bordered"
          >
            <thead className="thead-dark">
              <tr className="text-center">
                <th>الاسم</th>
                <th>النقاط</th>
                <th>اضافة</th>
              </tr>
            </thead>
            <tbody>
              {complete &&
                records?.data?.map((row, iRow) => (
                  <tr key={iRow}>
                    <td>{row.name}</td>
                    <td>{row.point}</td>
                    <td style={{padding:"1rem 5px"}}>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="flex-grow-1">
                          <input
                            type="number"
                            className="form-control text-center rounded-0 rounded-end"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            onChange={(e) => setPoint(e.target.value)}
                          />
                        </div>
                        <button
                          className="btn btn-primary rounded-0 rounded-start"
                          onClick={() => handelAddPoints(row)}
                        >
                          <MdAdd />
                          {/* ارسال */}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      {complete && records?.data.length === 0 && (
        <AlertsModel
          data={{
            type: "error",
            message: "لا يوجد مستخدم بهذا الرقم",
            location: "اضافة نقط",
          }}
        />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={
            <DeleteBtn
              setModel={setOpenModel}
              deleteData={sendFunction}
              msg={`هل انت متأكد من ارسال (${point}) نقاط  الي (${records?.data[0]?.name})`}
            />
          }
        />
      )}
    </>
  );
}
