import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClass,
  resetClassData,
  // deleteClass,
} from "../../../../Store/ClassSlice";

import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdEdit } from "react-icons/md";
// import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";
import MainModel from "../../../../Model/mainModel/MainModel";
// import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import ClassForm from "../../../../Components/Forms/ClassForm/ClassForm";

export default function CreateClass() {
  const { records, loading, complete } = useSelector((state) => state.class);
  // const meRedux = useSelector((state) => state.me);
  const dispatch = useDispatch();
  // const [modelDelete, setModelDelete] = useState(false);
  // const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  // const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    dispatch(getAllClass());
    return () => {
      dispatch(resetClassData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllClass());
    }
  }, [complete, dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  // const handleDeleteClick = useCallback(
  //   (row) => {
  //     setModelDelete((prevModelDelete) => !prevModelDelete);
  //     setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.name}) `);
  //     const dispatchData = () => {
  //       dispatch(deleteClass(row?.original?._id));
  //     };
  //     setSelectedRowForDelete(() => dispatchData);
  //   },
  //   [dispatch]
  // );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "صورة الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "45px", height: "45px", borderRadius: "25%" }}
              src={row?.original?.image}
              alt={`teacher-img`}
            />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "اسم الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "teacher",
        header: "اسم المدرس",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.teacher?.name}</span>
          </>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex algin-items-center justify-content-center gap-2">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            {/* {meRedux.records.role === "manager" && (
              <MainBtn
                btn={<MdDelete onClick={() => handleDeleteClick(row)} />}
              />
            )} */}
          </div>
        ),
      },
    ],
    [handleEditClick]
  );

  return (
    <>
      <h3
        style={{
          color: "var(--dark-color)",
          textShadow: "var(--main-color) 0px 0px 10px",
        }}
        className="text-center py-1"
      >
        {" "}
        الصفوف
      </h3>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
        // form={<ClassForm type="add" SendData={[]} />}
      />
{/* 
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )} */}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<ClassForm type="edit" SendData={sendData} />}
        />
      )}
    </>
  );
}
