import { useSelector } from "react-redux";
import MainTable from "../../../Components/tables/MainTable/MainTable";
import { useMemo } from "react";


export default function UserSafety() {
  const { records, loading } = useSelector((state) => state.me);


const handleExpiryTime = (expires) => {
  let date = new Date(expires);
  let formattedDate = date.toLocaleDateString("ar-EG");
  let formattedTime = date.toLocaleTimeString("ar-EG", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${formattedTime} - ${formattedDate}`;
};
  const columns = useMemo(
    () => [
      {
        accessorKey: "browser",
        header: "اسم المتصفح",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.browser}</span>
          </>
        ),
      },
      {
        accessorKey: "os",
        header: " نظام التشغيل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.os}</span>
          </>
        ),
      },
      {
        accessorKey: "pc",
        header: " اسم الجهاز ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.pc}</span>
          </>
        ),
      },
      {
        accessorKey: "data",
        header: "تاريخ التسجيل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{handleExpiryTime(row?.original?.data)}</span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <h2
        className="text-center py-3"
        style={{ textShadow: "var(--main-color) 0px 0px 10px" }}
      >
        جميع عمليات الدخول من اميلك علي الموقع
      </h2>
      <MainTable
        data={records?.operatingSystem || []}
        columns={columns}
        isLoading={loading}
        screen={true}
      />
    </>
  );
}
