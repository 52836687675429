import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import css from "./SliderTeacher.module.css";
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import img from "../../../dist/img/Avatar-page.png";

export default function TeacherSlider({ data }) {
  const [defaultImg, setDefaultImg] = useState(data);
  useEffect(() => {
    !data || data?.length < 1
      ? setDefaultImg([{ image: img, name: "اسم المدرس", subject: "المادة" }])
      : setDefaultImg(data);
  }, [data]);
  return (
    <div className="container">
      <div className={css.body}>
        <h5
          style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
          className="text-center pt-3 px-2"
        >
          اضغط علي المدرس لمشاهدة الفيديوهات الخاصة بالمدرس{" "}
        </h5>
        <Swiper
          effect="coverflow"
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={2}
          spaceBetween={0}
          preventClicks={true}
          preventClicksPropagation={false}
          loop={true}
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
            reverseDirection: true,
          }}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          breakpoints={{
            778: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          className={`mySwiper ${css.mySwiper}`}
        >
          {defaultImg?.map((MR, i) => (
            <SwiperSlide key={i} className={css.SwiperSlide}>
              <Link to={`teacher-profile/${MR?._id}`}>
                <div className={css.containerImg}>
                  <div className={css.backgroundImg}>
                    <img
                      className="w-100 h-100"
                      src={MR.image}
                      alt="TEACHER-IMG"
                    />
                  </div>
                  <h4 className="text-light">{MR.name}</h4>
                  <p className="text-light">{MR.subject}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
