import css from "./ResetPasswordForm.module.css";
import { useSelector } from "react-redux";
import VerifyCodeForgetPassword from "./VerifyCodeForgetPassword/VerifyCodeForgetPassword";
import SetNewPasswordCom from "./SetNewPassword/SetNewPassword";
import EmailResetPassword from "./EmailResetPassword/EmailResetPassword";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function ResetPasswordForm({ emailValue }) {
  const {
    records,
    error,
    completeForgetPassword,
    completeVerifyCodeForgetPassword,
    completeSetNewPassword,
  } = useSelector((state) => state.forget);

  const [content, setContent] = useState(
    <EmailResetPassword emailValue={emailValue} />
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      completeForgetPassword &&
      completeVerifyCodeForgetPassword &&
      completeSetNewPassword
    ) {
      Cookies.set("access_token", `${records.token}`);
      setMessage("تم تغير كلمة السر بنجاح");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else if (completeForgetPassword && completeVerifyCodeForgetPassword) {
      setContent(<SetNewPasswordCom />);
      setCurrentSlide(2);
      setMessage(" تم ادخال الكود الصحيح");
    } else if (completeForgetPassword) {
      setContent(<VerifyCodeForgetPassword />);
      setCurrentSlide(1);
      setMessage("تم ارسال الكود الي بريدك الالكتروني بنجاج");
    }
    if (!completeForgetPassword && error) {
      setMessage("حدث خطاء في ارسال الكود الي البريد الالكتروني");
    } else if (completeForgetPassword && error) {
      setMessage("ادخل الكود الصحيح المرسل أليك");
    } else if (
      completeForgetPassword &&
      completeVerifyCodeForgetPassword &&
      error
    ) {
      setMessage(" حدث خطاء في تغير كلمة المرور");
    }
  }, [
    completeForgetPassword,
    completeSetNewPassword,
    completeVerifyCodeForgetPassword,
    error,
    navigate,
    records.token,
  ]);

  return (
    <div className={css.container}>
      <div className={css.progressPar} dir="ltr">
        <span className={`${currentSlide >= 0 && css.passed}`}>1</span>
        <span className={`${currentSlide >= 1 && css.passed}`}>2</span>
        <span className={`${currentSlide >= 2 && css.passed}`}>3</span>
      </div>
      {content}
      <div
        className={`alert ${
          message && (error ? "alert-danger" : "alert-success")
        } ${css.messageContainer} px-3 py-2`}
        role="alert"
      >
        <span className={`${error ? css.errorMessage : css.succussMessage}`}>
          {message}
        </span>
      </div>
    </div>
  );
}
