import React, { useEffect } from "react";
import css from "../Auth.module.css";
import SignupForm from "../../../Components/Forms/SignupForm/SignupForm";

export default function Signup() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className={css.container}>
      <div className={css.imgContainer}></div>
      <div className={css.formContainer}>
        <SignupForm />
      </div>
    </div>
  );
}
