export const errorFunction = (error, defaultMsg) => {
  console.log(error);
  
  if (error.response.data.errors) {
    return error.response.data.errors[0].msg;
  } else if (error.response.data.msg) {
    return error.response.data.msg;
  } else {
    return defaultMsg;
  }
};
