import React, { useEffect } from "react";
import css from "./FreeVideoPage.module.css";
import SectionsSeparator from "../../../Components/SectionsSeparator/SectionsSeparator";
import { getAllPlaylist, getAllPlaylistVideos } from "../../../Store/FreeVideo";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-scroll";

export default function FreeVideoPage() {
  const { records, videos } = useSelector((state) => state.freeVideos);
  const dispatch = useDispatch();
  // console.log(records);

  useEffect(() => {
    try {
      dispatch(getAllPlaylist());
    } catch (err) {
      console.error("Error fetching playlists:", err);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [dispatch]);

  const getPlaylistId = async (e) => {
    try {
      await dispatch(getAllPlaylistVideos(e.id));
    } catch (err) {
      console.error("Error fetching playlists:", err);
    }
  };

  return (
    <div className={`${css.container}`}>
      <SectionsSeparator name="فيديوهات مجانيه" />
      {records?.items?.filter(
        (e) =>
          e.snippet.thumbnails.default.url !==
          "https://i.ytimg.com/img/no_thumbnail.jpg"
      ).length === 0 ? (
          <div className="p-5 text-center">
        <h1 className="">لا يوجد فيديوهات مجانية حتي الان !! </h1>
          </div>
      ) : (
        <>
          <h3
            style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
            className="text-center pt-3 px-2"
          >
            نخبة من افضل المدرسين
          </h3>
          <div className="container py-3">
            <div className="row">
              {records?.items?.map(
                (item) =>
                  // item.snippet.thumbnails.default.url !==
                  //   "https://i.ytimg.com/img/no_thumbnail.jpg" && (
                    <div
                      key={item.id}
                      className="col-lg-4 col-md-6 col-sm-12 mb-4 d-flex align-items-center justify-content-center"
                    >
                      <div className={css.cardForm}>
                        <div className={css.cardItem}>
                          <img
                            src={
                              item.snippet.thumbnails?.maxres?.url ||
                              item.snippet.thumbnails?.high?.url
                            }
                            className={css.cardItemBg}
                            alt="Card"
                          />
                        </div>
                        <div className={`${css.cardFormInner} text-center`}>
                          <div className={`${css.cardTitle} py-3 `}>
                            {item.snippet.title}
                          </div>
                          <div className={css.cardDescription}>
                            <p>{item.snippet.description}</p>
                          </div>
                          <Link to="video" smooth={true} duration={500}>
                            <button
                              onClick={() => getPlaylistId(item)}
                              className={`${css.button} btn btn-primary`}
                            >
                              اضغط لمشاهدة الفيديوهات
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  // )
              )}
            </div>
            <h3
              style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
              className="text-center py-3 px-2"
            >
              الفيديوهات
            </h3>
            <div id="video">
              {videos.length < 1 ? (
                <h2
                  className="text-center"
                  style={{ textShadow: "var(--main-color) 0px 0px 10px" }}
                >
                  اضغط علي المدرس لمشاهدة الفيديوهات
                </h2>
              ) : (
                <div className="row ">
                  {videos?.items
                    ?.slice(videos?.items.length - 4, videos?.items.length)
                    .map((item) => (
                      <div
                        key={item.id}
                        className="col-lg-6 col-md-6 col-sm-12 mb-4"
                      >
                        <div className="card" style={{ width: "100%" }}>
                          <iframe
                            style={{ width: "100%", height: "300px" }}
                            src={`https://www.youtube.com/embed/${item.snippet.resourceId.videoId}`}
                            frameBorder="0"
                            allowFullScreen
                            title={item.snippet.title}
                          ></iframe>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
