import React from "react";
import css from "./Policies.module.css";

export default function RefundPolicy() {
  return (
    <div className={css.container}>
      <div className="container">
        <div className={css.content}>
          <h1>سياسة الاسترجاع</h1>
          <h5> سياسة الاسترجاع لا يوجد استرجاع والا في حالات استثنائية</h5>
          <h3>------------------------------------ </h3>
          <h5>
            Refund Policy: No refunds are allowed, not even in exceptional
            cases.
          </h5>
          <h3>------------------------------------ </h3>
          <h5>
            “Fawry responsibility is limited to payment collection. For any
            related issue please contact us”
          </h5>
        </div>
      </div>
    </div>
  );
}
