import React, { useState } from "react";
import { BsArrowsFullscreen, BsFullscreenExit } from "react-icons/bs";
import MainBtn from "../MainBtn/MainBtn";
const BtnFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const toggleFullscreen = () => {
    const element = document.documentElement;

    if (document.fullscreenElement) {
      exitFullscreen();
    } else {
      requestFullscreen(element);
    }

    setIsFullscreen(!isFullscreen);
  };

  const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  return (
    <div
      onClick={toggleFullscreen}
    >
      <MainBtn
        btn={isFullscreen ? <BsFullscreenExit /> : <BsArrowsFullscreen />}
      />
    </div>
  );
};

export default BtnFullscreen;
