import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../../Store/TransactionsSlice";
import MainTable from "../../../Components/tables/MainTable/MainTable";
import ChangePasswordBtn from "../../../Components/Buttons/ChangePasswordBtn/ChangePasswordBtn";

export default function HomeDashboard() {
  const meRedux = useSelector((state) => state.me);
  const { records, loading } = useSelector((state) => state.transaction);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(getAllTransactions());
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    let formattedTime = date.toLocaleTimeString("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedTime} - ${formattedDate}`;
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "sender",
        header: "الراسل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              className={`${
                row?.original?.sender?.name === meRedux.records.name
                  ? " text-danger fs-6"
                  : ""
              }`}
            >
              {row?.original?.sender?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "receiver",
        header: "المستلم",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              className={`${
                row?.original?.receiver?.name === meRedux.records.name
                  ? " text-success fs-6"
                  : ""
              }`}
            >
              {row?.original?.receiver?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "pointsSent",
        header: "النقاط المرسلة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>
              {row?.original?.pointsSent}
              {" جنيه"}
            </span>
          </>
        ),
      },
      {
        accessorKey: "date",
        header: "التاريخ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{handleExpiryTime(row?.original?.date)}</span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <ChangePasswordBtn/>
      <h3
        style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
        className="text-center p-3"
      >
        تحويلاتي
      </h3>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
      />
    </>
  );
}
