import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import css from "./Wallet.module.css";
import { useDispatch, useSelector } from "react-redux";
import img from "../../../dist/img/Avatar-page.png";
import { useEffect, useState } from "react";
import MainModel from "../../../Model/mainModel/MainModel";
import DeleteBtn from "../DeleteBtn/DeleteBtn";
import {
  MdOutlineDomainVerification,
  MdAccountBalanceWallet,
  MdMonitorHeart,
  MdSubscriptions,
} from "react-icons/md";
import { TfiLayoutSlider } from "react-icons/tfi";
import { MdDriveFileMoveRtl, MdControlPoint } from "react-icons/md";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaUserGroup } from "react-icons/fa6";

import { AiFillSafetyCertificate } from "react-icons/ai";
import { MdLogout } from "react-icons/md";

import { resetData } from "../../../Store/getMeSlice";
export default function Wallet() {
  const [how, setHow] = useState(false);

  const { records } = useSelector((state) => state.me);
  useEffect(() => {
    if (records.role === "admin") {
      setHow(true);
    }
  }, [records.role]);

  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    const logoutSure = () => {
      Cookies.remove("access_token");
      navigate("/");
      dispatch(resetData());
    };
    setSelectedRowForDelete(() => logoutSure);
    setModelDelete(true);
  };

  const dashboardDroDown = () => (
    <>
      <li>
        <Link to="/dashboard" className={`dropdown-item ${css.dropdownItme}`}>
          <span>
            <MdOutlineDomainVerification />
          </span>
          <span>الرئسية</span>
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/users"
          className={`dropdown-item ${css.dropdownItme}`}
        >
          <span>
            <FaUserGroup />
          </span>
          <span>المستخدمين</span>
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/print-coupons"
          className={`dropdown-item ${css.dropdownItme}`}
        >
          <span>
            <RiCoupon3Fill />
          </span>
          <span> كوبونات </span>
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/add-points"
          className={`dropdown-item ${css.dropdownItme}`}
        >
          <span>
            <MdControlPoint />
          </span>
          <span>النقاط</span>
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/sliders"
          className={`dropdown-item ${css.dropdownItme}`}
        >
          <span>
            <TfiLayoutSlider />
          </span>
          <span>سليدرات</span>
        </Link>
      </li>
      <li>
        <Link
          to="/dashboard/create-files"
          className={`dropdown-item ${css.dropdownItme}`}
        >
          <span>
            <MdDriveFileMoveRtl />
          </span>
          <span>مجلدات</span>
        </Link>
      </li>
    </>
  );

  return (
    <>
      <div
        className={`${css.container} d-flex align-items-center justify-content-center`}
      >
        <div className="btn-group">
          <button
            type="button"
            dir="ltr"
            className={` rounded-3 dropdown-toggle dropdown-toggle-split ${css.mo}`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ border: "none", backgroundColor: "var(--dark-color)" }}
          >
            {" "}
            <img
              className=" rounded-5 "
              style={{ width: "25px" }}
              src={records.image || img}
              alt="user-img"
            />
          </button>
          <ul className={`dropdown-menu ${css.dropdownMenu}`}>
            {how ? (
              dashboardDroDown()
            ) : (
              <>
                <li>
                  <Link
                    to="/me"
                    className={`dropdown-item ${css.dropdownItme}`}
                  >
                    <span>
                      <MdOutlineDomainVerification />
                    </span>
                    <span>ملفي</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/me/wallet"
                    className={`dropdown-item ${css.dropdownItme}`}
                  >
                    <span>
                      <MdAccountBalanceWallet />
                    </span>
                    <span>محفظتي</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/me/recharge-balance"
                    className={`dropdown-item ${css.dropdownItme}`}
                  >
                    <span>
                      <MdMonitorHeart />
                    </span>
                    <span>شحن النقاط</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/me/subscriptions"
                    className={`dropdown-item ${css.dropdownItme}`}
                  >
                    <span>
                      <MdSubscriptions />
                    </span>
                    <span>فيديوهاتي</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/me/safety"
                    className={`dropdown-item ${css.dropdownItme}`}
                  >
                    <span>
                      <AiFillSafetyCertificate />
                    </span>
                    <span>الامان</span>
                  </Link>
                </li>
              </>
            )}
            <li className="dropdown-divider"></li>
            <li>
              <span
                onClick={() => logOut()}
                className={`dropdown-item ${css.dropdownItme}`}
              >
                <span>
                  <MdLogout />
                </span>
                <span>تسجيل الخروج</span>
              </span>
            </li>
          </ul>
        </div>
        <Link
          to={how ? "/dashboard" : "/me"}
          className={`${css.containerW} d-flex align-items-center justify-content-center  text-decoration-none`}
        >
          {`${records?.point} ج.م`}
        </Link>
      </div>
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              deleteData={selectedRowForDelete}
              msg="هل تريد تسجيل الخروج ؟"
            />
          }
        />
      )}
    </>
  );
}
