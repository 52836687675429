import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserByRole } from "../../../../Store/UserSlice";
import { addPointsManager, resetDataPoint } from "../../../../Store/AddPoints";
import { MdOutlineControlPointDuplicate } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { addManagerPointsToAdmin } from "../../../../Util/VallationForms";
import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";


export default function ManagerAddPoints() {
  const { records,loading } = useSelector((state) => state.users);
  const pointsRedux = useSelector((state) => state.points);
  const [openModel, setOpenModel] = useState(false);
  const [sendFunction, setSEndFunction] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserByRole());
  }, [dispatch]);

  useEffect(() => {
    if (pointsRedux.complete) {
      dispatch(getUserByRole());
    }

    return () => {
      setTimeout(() => {
        dispatch(resetDataPoint());
      }, 3000);
    };
  }, [dispatch, pointsRedux.complete]);
  const formik = useFormik({
    initialValues: {
      point: "",
      admin: "",
    },
    validationSchema: addManagerPointsToAdmin,
    onSubmit: async (values) => {
      try {
        setOpenModel((prev) => !prev);
        const sendDispatch = () => {
          dispatch(
            addPointsManager({ receiver: values.admin, points: values.point })
          );
        };
        setSEndFunction(() => sendDispatch);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const adminName = (id) => {
    const names = records.data.filter((name) => name._id === id);
    return names[0]?.name;
  };
  return (
    <>
      <div
        className="p-2  pb-3 rounded text-center mb-3"
        style={{ backgroundColor: "var(--light-color)" }}
      >
        <h3 className="text-center py-3">اضف نقاط الي الادمن</h3>
        <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
          <div className="row gx-4">
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group has-validation flex-grow-1 me-md-2">
                <span className="input-group-text rounded-0 rounded-end">
                  <MdOutlineControlPointDuplicate
                    className="fs-5"
                    style={{ color: "var(--main-color)" }}
                  />
                </span>
                <div className="form-floating is-invalid">
                  <input
                    type="text"
                    className={`form-control rounded-0 rounded-start ${
                      formik.touched.point &&
                      formik.errors.point &&
                      "is-invalid"
                    }`}
                    id="point"
                    placeholder="ادخل عدد النقاط"
                    {...formik.getFieldProps("point")}
                  />
                  <label style={{ color: "var(--gray-color)" }} htmlFor="point">
                    ادخل عدد النقاط
                  </label>
                </div>
                {formik.touched.point && formik.errors.point && (
                  <div className="invalid-feedback">{formik.errors.point}</div>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mb-3">
              <div className="input-group has-validation flex-grow-1 ms-md-2">
                <span className="input-group-text rounded-0 rounded-end">
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm mx-2"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    <RiAdminFill
                      className="fs-5"
                      style={{ color: "var(--main-color)" }}
                    />
                  )}
                </span>
                <div className="form-floating is-invalid">
                  <select
                    style={{ color: "var(--gray-color)" }}
                    id="admin"
                    className={`form-select form-select-md rounded-0 rounded-start ${
                      formik.touched.admin &&
                      formik.errors.admin &&
                      "is-invalid"
                    }`}
                    aria-label="Large select example"
                    {...formik.getFieldProps("admin")}
                    value={formik.values.admin}
                  >
                    <option value="" disabled>
                      اختر الادمن
                    </option>
                    {records?.data?.map((admin, i) => (
                      <option key={i} value={admin?._id}>
                        الاسم: {admin?.name} - النقاط: {admin?.point}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.admin && formik.errors.admin && (
                  <div className="invalid-feedback">{formik.errors.admin}</div>
                )}
              </div>
            </div>
            <div className="col-12">
              <button
                type="submit"
                disabled={pointsRedux.loading}
                className="btn btn-primary px-4 py-2 fs-5 col-md-3 "
              >
                {pointsRedux.loading ? "يتم الاضافة" : " اضف"}
                {pointsRedux.loading && (
                  <>
                    <span
                      className="spinner-border spinner-border-sm mx-2"
                      aria-hidden="true"
                    ></span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        className="p-3 rounded text-center"
        style={{ backgroundColor: "var(--light-color)" }}
      >
        <table
          id="myTable"
          className="table table-striped table-hover table-bordered"
        >
          <thead className="thead-dark">
            <tr className="text-center">
              <th>م</th>
              <th>الاسم</th>
              <th>النقاط</th>
            </tr>
          </thead>
          <tbody>
            {records?.data?.map((row, iRow) => (
              <tr key={iRow}>
                <td>{iRow + 1}</td>
                <td>
                  <span className="ms-2">
                    <img
                      src={row.image}
                      alt="admin-img"
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                  {row?.name}
                </td>
                <td>{row.point}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={
            <DeleteBtn
              setModel={setOpenModel}
              deleteData={sendFunction}
              msg={`هل انت متأكد من ارسال ${
                formik.values.point
              } نقاط الي ${adminName(formik.values.admin)}`}
            />
          }
        />
      )}
      {pointsRedux.complete && (
        <AlertsModel
          error={pointsRedux.error}
          msg={` تم اضافة نقاط ${formik.values.point} الي ${adminName(
            formik.values.admin
          )}`}
          place="النقاط"
        />
      )}
    </>
  );
}
