import React from "react";
import css from "./LevelsHome.module.css";
import { Link } from "react-router-dom";

export default function LevelsHome({ data, text }) {
  return (
    <div className="container py-5">
      <h3
        style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
        className="text-center py-3 px-2 "
      >
        {text}
      </h3>
      <div className="row align-items-center justify-content-center">
        {data?.map((item, i) => (
          <div
            key={i}
            className="col-lg-4 col-md-6 col-sm-12 d-flex mb-4 align-items-center justify-content-center"
          >
            <div className={`${css.cardForm} w-100`}>
              <div className={css.cardItem}>
                <img
                  src={item.image}
                  className={css.cardItemBg}
                  alt="Card"
                  loading="lazy"
                />
              </div>
              <div className={`${css.cardFormInner} text-center`}>
                <div className={`${css.cardTitle} py-3 `}>
                  {item?.title || item?.name}
                </div>
                <Link
                  to={item?._id ? `${item?._id}` : `/login`}
                  className={`btn btn-primary ${css.button}`}
                >
                  اضغط هنا للمشاهده
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
