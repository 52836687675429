import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSlider, editSlider } from "../../../Store/HeaderSliderSlice";
import css from "../MainStyleForms.module.css";
import { IoAdd } from "react-icons/io5";
import { sliderHeaderHomeFormValid } from "../../../Util/VallationForms";

export default function HeaderSliderForm({ type, SendData }) {
  const { loading } = useSelector((state) => state.slider);
  const [img, setImg] = useState(SendData?.image || null);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      image: SendData?.image || null,
    },
    validationSchema: sliderHeaderHomeFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("image", values.image);
      try {
        if (type === "edit") {
          dispatch(editSlider([SendData._id, formData]));
        } else if (type === "add") {
          dispatch(createSlider(formData));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div
          className={css.imageOnly}
        >
          {img && (
            <img
              src={img}
              style={{ width: "100%", height: "auto", padding: "5px" }}
              alt="MyPhoto"
            />
          )}
          <div>
            <label className={css.customFileInput} htmlFor="image">
              <IoAdd />
            </label>
            <input
              type="file"
              id="image"
              name="image"
              accept=".png, .jpg, .jpeg"
              onChange={fileChange}
            />
            {formik.touched.image && formik.errors.image && (
              <div className="text-danger">{formik.errors.image}</div>
            )}
          </div>
        </div>
        <div className="col-12 mt-3  d-flex justify-content-center align-content-center">
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary px-4 py-2 fs-5"
          >
            {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </form>
    </>
  );
}
