import css from "../Auth.module.css";
import LoginForm from "../../../Components/Forms/LoginForm/LoginForm";
import { useEffect } from "react";

export default function Login() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className={css.container}>
      <div className={css.imgContainer}></div>
      <div className={css.formContainer}>
        <LoginForm />
      </div>
    </div>
  );
}
