import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainBtn from "../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete } from "react-icons/md";
import MainTable from "../../../Components/tables/MainTable/MainTable";
import {
  deleteUser,
  getAllUsers,
  resetDataUser,
} from "../../../Store/UserSlice";
import MainModel from "../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../Components/Buttons/DeleteBtn/DeleteBtn";

export default function GetAllUser() {
  const { records, loading } = useSelector((state) => state.users);
  const meRedux = useSelector((state) => state?.me?.records?.role);
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const dispatch = useDispatch();

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(`هل انت متأكد من حذف المستخدم ${row?.original?.name}`);
      const dispatchData = () => {
        dispatch(deleteUser(row?.original?._id));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const managerColumn =
    meRedux === "manager"
      ? [
          {
            accessorKey: "actions",
            header: "Actions",
            size: 100,
            Cell: ({ row }) => (
              <div className="d-flex align-content-center justify-content-center gap-1">
                {/* {row?.original?.userVerify && ( */}
                <MainBtn
                  btn={
                    <MdDelete
                      className="mainBtnIcon"
                      onClick={() => handleDeleteClick(row)}
                    />
                  }
                />
                {/* )} */}
              </div>
            ),
          },
        ]
      : [];

  useEffect(() => {
    dispatch(getAllUsers());
    return () => {
      dispatch(resetDataUser());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "الاسم",
        flex: 1,
      },
      {
        accessorKey: "email",
        header: "الاميل",
        flex: 1,
      },
      {
        accessorKey: "phone",
        header: "الهاتف",
        flex: 1,
      },
      {
        accessorKey: "point",
        header: "النقاط",
        size: 50,
      },
      {
        accessorKey: "role",
        header: "الدور",
        size: 50,
      },
      // {
      //   accessorKey: "userVerify",
      //   header: "تأكيد الاميل",
      //   flex: 1,
      //   Cell: ({ row }) => (
      //     <span
      //       style={{
      //         color: `${
      //           row?.original?.userVerify
      //             ? "var(--main-color)"
      //             : "var(--danger-color)"
      //         }`,
      //       }}
      //     >
      //       {row?.original?.userVerify ? "اميل مؤكد" : "اميل غير مؤكد"}
      //     </span>
      //   ),
      // },
      ...managerColumn,
    ],
    [handleDeleteClick, meRedux]
  );

  return (
    <>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
      />
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
    </>
  );
}
