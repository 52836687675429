import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import {errorFunction} from "../hooks/ErrorFunction"

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllTeachers = createAsyncThunk(
  "teacher/getAllTeachers",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/teacher");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب المدرسين ",
          location: " المدرسين",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getTeacherByID = createAsyncThunk(
  "teacher/getTeacherByID",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axiosInstance.get(`/teacher/${id}`);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب المدرس ",
          location: "  المدرسين",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
// export const deleteTeacher = createAsyncThunk(
//   "teacher/deleteTeacher",
//   async (id, thunkAPI) => {
//     const { rejectWithValue } = thunkAPI;
//     try {
//       const response = await axiosInstance.delete(`/teacher/${id}`);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

// export const createTeacher = createAsyncThunk(
//   "teacher/createTeacher",
//   async (formData, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post("/teacher", formData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const editTeacher = createAsyncThunk(
  "teacher/editTeacher",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/teacher/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "  تم تعديل المدرس بنجاح ",
          location: "  المدرسين",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطاء ما في تعديل المدرس "),
          location: "  المدرسين",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const resetPointTeacher = createAsyncThunk(
  "teacher/resetPointTeacher",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/teacher/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "  تم حذف نقاط المدرس بنجاح ",
          location: "  المدرسين",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف نقاط المدرس ",
          location: "  المدرسين",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const teacherSlice = createSlice({
  name: "teacher",
  initialState: initState,
  reducers: {
    resetTeacherData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllTeachers
      .addCase(getAllTeachers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllTeachers.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllTeachers.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // getTeacherByID
      .addCase(getTeacherByID.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getTeacherByID.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload.data;
        state.complete = false;
      })
      .addCase(getTeacherByID.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteTeacher
      // .addCase(deleteTeacher.pending, (state) => {
      //   state.loading = true;
      //   state.error = false;
      //   state.complete = false;
      // })
      // .addCase(deleteTeacher.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.records.data = state.records.data.filter(
      //     (e) => e._id !== action.payload
      //   );
      //   state.complete = true;
      // })
      // .addCase(deleteTeacher.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = `Error: ${action.payload}`;
      //   state.complete = false;
      // })
      // // add createTeacher
      // .addCase(createTeacher.pending, (state) => {
      //   state.loading = true;
      //   state.error = null;
      //   state.complete = false;
      // })
      // .addCase(createTeacher.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.records.data = [...state.records.data, action.payload];
      //   state.complete = true;
      // })
      // .addCase(createTeacher.rejected, (state, action) => {
      //   state.loading = false;
      //   state.error = `${action.payload}`;
      //   state.complete = false;
      // })
      //  editTeacher
      .addCase(editTeacher.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editTeacher.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      //  resetPointTeacher
      .addCase(resetPointTeacher.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(resetPointTeacher.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(resetPointTeacher.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetTeacherData } = teacherSlice.actions;
export default teacherSlice.reducer;
