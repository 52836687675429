import css from "../MainStyleForms.module.css";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail, MdDriveFileRenameOutline } from "react-icons/md";
import { FaPhoneFlip } from "react-icons/fa6";
import { createAdminFormValid } from "../../../Util/VallationForms";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { addAdmin, resetDataUser } from "../../../Store/UserSlice";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel";

export default function CreateAdmin() {
  const { records, error, complete, loading } = useSelector(
    (state) => state.users
  );
  const [img, setImg] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetDataUser());
    };
  }, [dispatch]);

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      image: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: createAdminFormValid,
    onSubmit: async (values) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("email", values.email);
        formData.append("image", values.image);
        formData.append("role", "admin");
        formData.append("userVerify", true);
        formData.append("phone", values.phone);
        formData.append("password", values.password);
        formData.append("passwordConfirm", values.passwordConfirm);
        dispatch(addAdmin(formData));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  useEffect(() => {
    if (complete) {
      formik.resetForm();
      setImg(null);
    }
  }, [complete]);

  return (
    <div className={`d-flex gap-2 flex-column p-2 rounded ${css.container}`}>
      <h4
        style={{
          color: "var(--dark-color)",
          textShadow: "var(--main-color) 0px 0px 10px",
        }}
        className="text-center py-1"
      >
        {" "}
        {`انشاء أدمن`}
      </h4>
      <div className="container ">
        <form className="row g-2" onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center justify-content-center mb-2">
            <div className={css.image}>
              {img && <img src={img} alt="MyPhoto" loading="lazy" />}
              <div>
                <label className={css.customFileInput} htmlFor="image">
                  <IoAdd />
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={fileChange}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="text-danger">{formik.errors.image}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <MdDriveFileRenameOutline
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  id="name"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  placeholder=""
                  {...formik.getFieldProps("name")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="name">
                  ادخل الاسم ثنائي
                </label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <MdEmail
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  id="email"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.email && formik.errors.email && "is-invalid"
                  }`}
                  placeholder=""
                  {...formik.getFieldProps("email")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="email">
                  ادخل الاميل
                </label>
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <FaPhoneFlip
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.phone && formik.errors.phone && "is-invalid"
                  }`}
                  id="phone"
                  placeholder=""
                  {...formik.getFieldProps("phone")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="phone">
                  ادخل رقم الهاتف
                </label>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className="invalid-feedback">{formik.errors.phone}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <RiLockPasswordFill
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="password"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.password &&
                    formik.errors.password &&
                    "is-invalid"
                  }`}
                  id="password"
                  placeholder=""
                  {...formik.getFieldProps("password")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="password right-0"
                >
                  ادخل كلمة السر
                </label>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="invalid-feedback ">
                  {formik.errors.password}
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation ">
              <span className="input-group-text rounded-0 rounded-end">
                <RiLockPasswordFill
                  className="fs-5"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="password"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm &&
                    "is-invalid"
                  }`}
                  id="passwordConfirm"
                  placeholder=""
                  {...formik.getFieldProps("passwordConfirm")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="passwordConfirm right-0"
                >
                  تأكيد الباسورد
                </label>
              </div>
              {formik.touched.passwordConfirm &&
                formik.errors.passwordConfirm && (
                  <div className="invalid-feedback ">
                    {formik.errors.passwordConfirm}
                  </div>
                )}
            </div>
          </div>
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-primary my-3 px-4 py-2 fs-5"
            >
              {loading ? "جاري انشاء حساب" : " انشاء حساب"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
        {error && (
          <AlertsModel
            error={error}
            msg={"حدث خطاء في انشاء الحساب"}
            place="انشاء حساب"
          />
        )}
        {complete && (
          <AlertsModel
            error={error}
            msg={`تم انشاء Admin بأسم ${records[0]?.data?.name} بنجاح`}
            place="انشاء حساب"
          />
        )}
      </div>
    </div>
  );
}
