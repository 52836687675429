
import React, { useEffect } from "react";
import css from "./DarkLight.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../Store/GlobalSlice";

export default function DarkLight() {
  const THEMES = {
    LIGHT: "light",
    DARK: "dark",
  };

  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  useEffect(() => {
    const userPrefersDark = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;

    const initialTheme =
      localStorage.getItem("currentMode") ||
      (userPrefersDark ? THEMES.DARK : THEMES.LIGHT);
    dispatch(setTheme(initialTheme));

    document.body.className = initialTheme;

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = (e) => {
      const newTheme = e.matches ? THEMES.DARK : THEMES.LIGHT;
      dispatch(setTheme(newTheme));
      localStorage.setItem("currentMode", newTheme);
    };

    mediaQuery.addEventListener("change", handleChange);

    return () => {
      mediaQuery.removeEventListener("change", handleChange);
    };
  }, [THEMES.DARK, THEMES.LIGHT, dispatch]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const toggleTheme = () => {
    const newTheme = theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK;

    dispatch(setTheme(newTheme));
    localStorage.setItem("currentMode", newTheme);
  };

  return (
    <div className={css.containerMode}>
      <input
        className={css.inputMode}
        type="checkbox"
        id="toggle"
        checked={theme === THEMES.DARK}
        onChange={toggleTheme}
      />
      <label className={css.labelMode} htmlFor="toggle"></label>
    </div>
  );
}
