import React from "react";
import css from "./NotFoundPage.module.css";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  return (
    <div className={css.container}>
      <div className={css.img}></div>
      <Link title="back" to="/" replace>
        <div className={css.message}>لا يوجد صفحة بهذا الاسم اضغط هنا للرجوع </div>
      </Link>
    </div>
  );
}
