import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import { errorFunction } from "../hooks/ErrorFunction";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const addPointsAdmin = createAsyncThunk(
  "points/adminAddPoints",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/transaction/admin/sendPoints`,
        { receiver: data.receiver, points: data.points }
      );
      dispatch(
        setError({
          type: "complete",
          message: `تم اضافة نقط ${data.points} بنجاح`,
          location: "اضافة نقط",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطء ما في اضافة النقط للمستخدم"),
          location: "اضافة نقط",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const addPointsManager = createAsyncThunk(
  "points/addPointsManager",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(
        `/transaction/manager/sendPoints`,
        { receiver: data.receiver, points: data.points }
      );
      dispatch(
        setError({
          type: "complete",
          message: `تم اضافة نقط ${data.points} بنجاح`,
          location: "اضافة نقط",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error, "حدث خطء ما في اضافة النقط للأدمن"),
          location: "اضافة نقط",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const pointsSlice = createSlice({
  name: "points",
  initialState: initState,
  reducers: {
    resetDataPoint(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // addPointsAdmin
      .addCase(addPointsAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(addPointsAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(addPointsAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      // addPointsManager
      .addCase(addPointsManager.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(addPointsManager.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(addPointsManager.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetDataPoint } = pointsSlice.actions;
export default pointsSlice.reducer;
