import css from "../MainStyleForms.module.css";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdEmail, MdDriveFileRenameOutline } from "react-icons/md";
import {  editTeacher } from "../../../Store/TeacherSlice";

import { FaPhoneFlip, FaBookOpenReader } from "react-icons/fa6";
import { createTeacherFormValid } from "../../../Util/VallationForms";
import { useFormik } from "formik";
import { useState } from "react";

import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel";
export default function CreateTeacher({ type, SendData }) {
  const { error, complete, loading } = useSelector((state) => state.teacher);

  const [img, setImg] = useState(SendData?.image || null);
  const dispatch = useDispatch();





  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: SendData?.name || "",
      email: SendData?.email || "",
      phone: SendData?.phone ?`0${SendData?.phone }` : "",
      image: SendData?.image || "",
      // picture: SendData?.picture || "",
      // avater: SendData?.avater || "",
      subject: SendData?.subject || "",
      description: SendData?.description || "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: createTeacherFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("phone", values.phone);
      formData.append("password", values.password);
      formData.append("passwordConfirm", values.passwordConfirm);
      formData.append("image", values.image);
      // formData.append("picture", values.picture);
      // formData.append("avater", values.avater);
      formData.append("subject", values.subject);
      formData.append("description", values.description);
      try {
        if (type === "edit") {
          formData.delete("password");
          formData.delete("passwordConfirm");
          typeof values.image === "string" && formData.delete("image");
          // typeof values.avater === "string" && formData.delete("avater");
          // typeof values.picture === "string" && formData.delete("picture");
          dispatch(editTeacher([SendData._id, formData]));
        } else if (type === "add") {
          // dispatch(createTeacher(formData));
          console.log("مغلق");
          
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <div className="d-flex gap-2 flex-column mt-1 p-2 rounded overflow-hidden ">
      <div className={`container ${css.container}`}>
        <h4
          style={{
            color: "var(--dark-color)",
            textShadow: "var(--main-color) 0px 0px 10px",
          }}
          className="text-center py-1"
        >
          {" "}
          {`${type === "add" ? "اضف" : "عدل"} مدرس`}
        </h4>
        <form className="row g-2" onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center justify-content-center flex-column flex-md-row gap-2 mb-2">
            <div className={css.image}>
              {img && <img src={img} alt="MyPhoto" loading="lazy" />}
              <div>
                <label className={css.customFileInput} htmlFor="image">
                  <IoAdd />
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={(e) => fileChange(e, "image")}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="text-danger" style={{ fontSize: "12px" }}>
                    {formik.errors.image}
                  </div>
                )}
              </div>
            </div>
            {/* <div className={css.gifImage}>
              <div className={css.gif}>
                {images.picture && <img src={images.picture} alt="myPicture" />}
                <div>
                  <label className={css.customFileInput} htmlFor="picture">
                    <IoAdd />
                  </label>
                  <input
                    type="file"
                    id="picture"
                    name="picture"
                    accept=".gif"
                    onChange={(e) => handleChange(e, "picture")}
                  />

                  {formik.touched.picture && formik.errors.picture && (
                    <div className="text-danger" style={{ fontSize: "12px" }}>
                      {formik.errors.picture}
                    </div>
                  )}
                </div>
              </div>
              <div className={css.gif}>
                {images.avater && <img src={images.avater} alt="myAvater" />}
                <div>
                  <label className={css.customFileInput} htmlFor="avater">
                    <IoAdd />
                  </label>
                  <input
                    type="file"
                    id="avater"
                    name="avater"
                    accept=".gif"
                    onChange={(e) => handleChange(e, "avater")}
                  />
                  {formik.touched.avater && formik.errors.avater && (
                    <div className="text-danger" style={{ fontSize: "12px" }}>
                      {formik.errors.avater}
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <MdDriveFileRenameOutline
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  id="name"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  placeholder="ادخل الاسم ثنائي"
                  {...formik.getFieldProps("name")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="name">
                  ادخل الاسم ثنائي
                </label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <MdEmail
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  id="email"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.email && formik.errors.email && "is-invalid"
                  }`}
                  placeholder=""
                  {...formik.getFieldProps("email")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="email">
                  ادخل الاميل
                </label>
              </div>
              {formik.touched.email && formik.errors.email && (
                <div className="invalid-feedback">{formik.errors.email}</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <FaPhoneFlip
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.phone && formik.errors.phone && "is-invalid"
                  }`}
                  id="phone"
                  placeholder=""
                  {...formik.getFieldProps("phone")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="phone">
                  ادخل رقم الهاتف
                </label>
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className="invalid-feedback">{formik.errors.phone}</div>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group has-validation">
              <span className="input-group-text rounded-0 rounded-end">
                <FaBookOpenReader
                  className="fs-6"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.subject &&
                    formik.errors.subject &&
                    "is-invalid"
                  }`}
                  id="subject"
                  placeholder=""
                  {...formik.getFieldProps("subject")}
                />
                <label style={{ color: "var(--gray-color)" }} htmlFor="subject">
                  الماده الدراسية
                </label>
              </div>
              {formik.touched.subject && formik.errors.subject && (
                <div className="invalid-feedback">{formik.errors.subject}</div>
              )}
            </div>
          </div>

          <div className="col-12 mb-3">
            <div className="form-floating has-validation">
              <textarea
                className={`form-control ${
                  formik.touched.description &&
                  formik.errors.description &&
                  "is-invalid"
                }`}
                placeholder="Leave a comment here"
                id="description"
                style={{ height: "100px" }}
                {...formik.getFieldProps("description")}
              ></textarea>
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="description"
              >
                اكتب نبذة عن المدرس
              </label>
            </div>
            {formik.touched.description && formik.errors.description && (
              <div className="text-danger" style={{ fontSize: "12px" }}>
                {formik.errors.description}.
              </div>
            )}
          </div>

          {type === "add" && (
            <>
              <div className="col-12 col-md-6">
                <div className="input-group has-validation">
                  <span className="input-group-text rounded-0 rounded-end">
                    <RiLockPasswordFill
                      className="fs-5"
                      style={{ color: "var(--main-color)" }}
                    />
                  </span>
                  <div className="form-floating is-invalid ">
                    <input
                      type="password"
                      className={`form-control rounded-0 rounded-start ${
                        formik.touched.password &&
                        formik.errors.password &&
                        "is-invalid"
                      }`}
                      id="password"
                      placeholder=""
                      {...formik.getFieldProps("password")}
                    />
                    <label
                      style={{ color: "var(--gray-color)" }}
                      htmlFor="password right-0"
                    >
                      ادخل كلمة السر
                    </label>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <div className="invalid-feedback ">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="input-group has-validation ">
                  <span className="input-group-text rounded-0 rounded-end">
                    <RiLockPasswordFill
                      className="fs-5"
                      style={{ color: "var(--main-color)" }}
                    />
                  </span>
                  <div className="form-floating is-invalid ">
                    <input
                      type="password"
                      className={`form-control rounded-0 rounded-start ${
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm &&
                        "is-invalid"
                      }`}
                      id="passwordConfirm"
                      placeholder=""
                      {...formik.getFieldProps("passwordConfirm")}
                    />
                    <label
                      style={{ color: "var(--gray-color)" }}
                      htmlFor="passwordConfirm right-0"
                    >
                      تأكيد الباسورد
                    </label>
                  </div>
                  {formik.touched.passwordConfirm &&
                    formik.errors.passwordConfirm && (
                      <div className="invalid-feedback ">
                        {formik.errors.passwordConfirm}
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
          <div className="col-12 text-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary px-4 py-2 fs-5"
            >
              {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
        {error && (
          <AlertsModel
            error={error}
            msg={"حدث خطاء في انشاء الحساب"}
            place="انشاء حساب"
          />
        )}
        {complete && (
          <AlertsModel
            error={error}
            msg="تم انشاء الحساب بنجاح"
            place="انشاء حساب"
          />
        )}
      </div>
    </div>
  );
}
