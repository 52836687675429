import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllHonour,
  resetHonourData,
  deleteHonour,
} from "../../../../Store/HonourSlice";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import MainTable from "../../../../Components/tables/MainTable/MainTable";

import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import HonourForm from "../../../../Components/Forms/HonourForm/HonourForm";

export default function HonourSlider() {
  const { records, loading, complete } = useSelector((state) => state.honour);
  const dispatch = useDispatch();

  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    dispatch(getAllHonour());
    return () => {
      dispatch(resetHonourData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllHonour());
    }
  }, [complete, dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.name}) `);
      const dispatchData = () => {
        dispatch(deleteHonour(row?.original?._id));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "صورة الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "100px", height: "auto", borderRadius: "6px" }}
              src={row?.original?.image}
              alt={`teacher-img`}
            />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "اسم الطالب",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.name}</span>
          </>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex algin-items-center justify-content-center gap-2">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            <MainBtn
              btn={<MdDelete onClick={() => handleDeleteClick(row)} />}
            />
          </div>
        ),
      },
    ],
    [handleEditClick, handleDeleteClick]
  );

  return (
    <>
      <h4 className="text-center py-2">لوحة الشرف </h4>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
        form={<HonourForm type="add" SendData={[]} />}
      />

      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<HonourForm type="edit" SendData={sendData} />}
        />
      )}
    </>
  );
}
