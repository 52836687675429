import React, { useState } from "react";
import css from "./DeleteBtn.module.css";

export default function DeleteBtn({ setModel, deleteData, msg }) {
  const [opening, setOpening] = useState(true);
  const [massage, ] = useState(
    msg || "هل انت متأكد من حذف هذا العنصر ؟"
  );

  const NoHandler = () => {
    setModel(false);
    setOpening(false);
  };

  const handlePopup = () => {
    deleteData();
    setModel(false);
    setOpening(false);
  };

  return (
    opening && (
      <div className={css.bodyDelete}>
        <h4 className="text-center">{massage}</h4>
        <div className={css.btns}>
          <button className="btn btn-danger" onClick={NoHandler}>
            لا
          </button>
          <button className="btn btn-success" onClick={handlePopup}>
            نعم
          </button>
        </div>
      </div>
    )
  );
}
