import React, { useEffect, useState } from "react";
import "./PrintCoupons.css";
import logo from "../../dist/img/logo-copouns.png";

export default function PrintCoupons({ coupons }) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (coupons) {
      let newRows = [];
      for (let i = 0; i < coupons.length; i += 2) {
        newRows.push(coupons.slice(i, i + 2));
      }
      setRows(newRows);
    }
  }, [coupons]);

  const printTable = () => {
    const table = document.getElementById("myTable").outerHTML;
    const styles = Array.from(
      document.querySelectorAll(
        'style, link[rel="stylesheet"], link[rel="./PrintCoupons.css"]'
      )
    )
      ?.map((style) => style.outerHTML)
      .join("");

    const newWindow = window.open("", "_blank");
    newWindow.document.write("<html><head><title>Print Table</title>");
    newWindow.document.write(styles);
    newWindow.document.write("</head><body>");
    newWindow.document.write(table);
    newWindow.document.write("</body></html>");
    newWindow.document.close();
    newWindow.print();
  };
  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    return formattedDate;
  };

  return (
    <div className="container mt-5">
      <button onClick={printTable} className="btn btn-primary mb-3">
        طباعة الجدول
      </button>
      <div className="w-100 h-100 overflow-x-auto">
        <table
          id="myTable"
          className="table table-striped table-hover table-bordered"
        >
          <thead className="thead-dark">
            <tr className="text-center">
              <th>العمود الأول</th>
              <th>العمود الثاني</th>
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, iRow) => (
              <tr key={iRow}>
                {row.map((cell, iCell) => (
                  <td key={iCell}>
                    <div className="d-flex align-items-center justify-content-around position-relative">
                      <div
                        className="d-flex  justify-content-around  flex-column g-0 watermark fw-bold"
                        style={{ fontSize: "14px", minWidth: "250px" }}
                      >
                        <span>
                          <span className="head">الكود:</span>
                          {cell?.code}
                        </span>
                        <span>
                          <span className="head">الخصم:</span>
                          {cell?.discount === 0 ? (
                            "بدون خصم"
                          ) : (
                            <>
                              {`${cell?.discount}% - ( `}
                              <del>
                                {cell?.section
                                  ? cell?.section?.price
                                  : cell?.lecture?.price}
                              </del>
                              {"  =  "}
                              {cell?.section ? (
                                <span>
                                  {cell?.section?.price -
                                    (cell?.section?.price * cell?.discount) /
                                      100}
                                </span>
                              ) : (
                                <span>
                                  {cell?.lecture?.price -
                                    (cell?.lecture?.price * cell?.discount) /
                                      100}
                                </span>
                              )}
                              {"  ) "}
                            </>
                          )}
                        </span>
                        <span>
                          <span className="head">المدرس:</span>
                          {cell?.section ? (
                            <span>{cell?.section?.class?.teacher?.name}</span>
                          ) : (
                            <span>
                              {cell?.lecture?.section?.class?.teacher?.name}
                            </span>
                          )}
                        </span>
                        <span>
                          <span className="head">
                            {cell?.section ? "باب" : "محاضره"}:
                          </span>
                          {cell?.section
                            ? cell?.section?.name
                            : cell?.lecture?.lecture}
                        </span>
                        <span>
                          <span className="head">انتهاء:</span>{" "}
                          {handleExpiryTime(cell?.expires)}
                        </span>
                      </div>
                      <div>
                        <img
                          src={logo}
                          alt="logo-img"
                          style={{ width: "70px", borderRadius: "7px" }}
                        />
                        <div
                          style={{
                            fontSize: "14px",
                            marginTop: "10px",
                            position: "absolute",
                            bottom: "-10px",
                            left: "-10px",
                          }}
                        >
                          <span
                            style={{
                              color: "var(--main-color)",
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            by:
                          </span>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "10px",
                            }}
                          >
                            {cell?.createdBy?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
