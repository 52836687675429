import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoLogInSharp } from "react-icons/io5";
import { FaUserGraduate } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineClose } from "react-icons/md";
import Cookies from "js-cookie";
import "./AuthBtn.css";

export default function AuthBtn() {
  const token = Cookies.get("access_token");
  const [iconToggled, setIconToggled] = useState(false);

  const handleClosePopup = () => {
    setIconToggled(false);
  };

  return (
    <>
      {!token && (
        <>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setIconToggled(!iconToggled)}
          >
            <span className="fs-3">
              {iconToggled ? <MdOutlineClose /> : <GiHamburgerMenu />}
            </span>
          </button>
          <div
            className={`collapse navbar-collapse flex-grow-0 ${
              iconToggled ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item mx-2">
                <Link
                  className="nav-link"
                  to="login"
                  onClick={handleClosePopup}
                >
                  <IoLogInSharp
                    className="mx-2 fs-4"
                    style={{ color: "var(--main-color)" }}
                  />
                  تسجيل الدخول
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className="nav-link"
                  to="signup"
                  onClick={handleClosePopup}
                >
                  <FaUserGraduate
                    className="mx-2 fs-5"
                    style={{ color: "var(--main-color)" }}
                  />
                  انشاء حساب جديد
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}
