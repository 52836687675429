import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MdOutlineDomainVerification,
  MdAccountBalanceWallet,
  MdMonitorHeart,
  MdSubscriptions,
} from "react-icons/md";

import { MdLogout } from "react-icons/md";
import css from "./UserSidePar.module.css";

import { useSelector } from "react-redux";
import { AiFillSafetyCertificate } from "react-icons/ai";

import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import MainModel from "../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../Components/Buttons/DeleteBtn/DeleteBtn";
import { resetData } from "../../../Store/getMeSlice";
import { useDispatch } from "react-redux";

export default function UserSidePar() {
  const { records } = useSelector((state) => state.me);

  const location = useLocation();

  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    const logoutSure = () => {
      Cookies.remove("access_token");
      navigate("/");
      dispatch(resetData());
    };
    setSelectedRowForDelete(() => logoutSure);
    setModelDelete(true);
  };
  return (
    <div className={css.container}>
      <div className={css.GoPages}>
        <Link
          to="/me"
          className={location.pathname === "/me" ? `${css.active}` : ""}
          title="me"
        >
          <span>
            <MdOutlineDomainVerification />
          </span>
          <span className={css.name}>ملفي</span>
        </Link>
        {/* {records?.userVerify && ( */}
          <>
            <Link
              to="wallet"
              title="wallet"
              className={
                location.pathname === "/me/wallet" ? `${css.active}` : ""
              }
            >
              <span>
                <MdAccountBalanceWallet />
              </span>
              <span className={css.name}>محفظتي</span>
            </Link>
            <Link
              to="recharge-balance"
              title="شحن النقاط"
              className={
                location.pathname === "/me/recharge-balance"
                  ? `${css.active}`
                  : ""
              }
            >
              <span>
                <MdMonitorHeart />
              </span>
              <span className={css.name}>شحن النقاط</span>
            </Link>
            <Link
              to="subscriptions"
              title="فيديوهاتي"
              className={
                location.pathname === "/me/subscriptions" ? `${css.active}` : ""
              }
            >
              <span>
                <MdSubscriptions />
              </span>
              <span className={css.name}> فيديوهاتي</span>
            </Link>
            <Link
              to="safety"
              title=" الامان"
              className={
                location.pathname === "/me/safety" ? `${css.active}` : ""
              }
            >
              <span>
                <AiFillSafetyCertificate />
              </span>
              <span className={css.name}>الامان</span>
            </Link>
            <span
              onClick={() => logOut()}
              className={css.logout}
              title="تسجيل الخروج"
            >
              <span>
                <MdLogout />
              </span>
              <span className={css.name}>تسجيل الخروج</span>
            </span>
          </>
        {/* // )} */}
        {modelDelete && (
          <MainModel
            setModel={setModelDelete}
            content={
              <DeleteBtn
                setModel={setModelDelete}
                deleteData={selectedRowForDelete}
                msg="هل تريد تسجيل الخروج ؟"
              />
            }
          />
        )}
      </div>
    </div>
  );
}
