import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllGallery = createAsyncThunk(
  "gallery/getAllGallery",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/gallery");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب معرض صور المدرسين ",
          location: "معرض صور المدرس",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getForOneTeacherGallery = createAsyncThunk(
  "gallery/getForOneTeacherGallery",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/gallery?teacher=${id}`);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب معرض صور المدرس ",
          location: "معرض صور المدرس",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/gallery/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف الصورة بنجاح",
          location: "معرض المدرس",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف معرض صور المدرس ",
          location: "معرض صور المدرس",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createGallery = createAsyncThunk(
  "gallery/createGallery",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/gallery", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم اضافة الصورة بنجاح",
          location: "معرض المدرس",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في انشاء معرض صور المدرس ",
          location: "معرض صور المدرس",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editGallery = createAsyncThunk(
  "gallery/editGallery",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/gallery/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل الصورة بنجاح",
          location: "معرض المدرس",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل معرض صور المدرس ",
          location: "معرض صور المدرس",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const gallerySlice = createSlice({
  name: "gallery",
  initialState: initState,
  reducers: {
    resetGallerySliceData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllGallery
      .addCase(getAllGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllGallery.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // getForOneTeacherGallery
      .addCase(getForOneTeacherGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getForOneTeacherGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getForOneTeacherGallery.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteGallery
      .addCase(deleteGallery.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createGallery
      .addCase(createGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editClass
      .addCase(editGallery.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editGallery.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editGallery.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetGallerySliceData } = gallerySlice.actions;
export default gallerySlice.reducer;
