import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setError } from "./ErrorSlice";

const API_KEY = "AIzaSyAeOQfCk8X7ArY06UrB-gIp9YNHewLLBRM";
const CHANNEL_ID = "UCvONu2aNaXXUgYz8J-njcfw";
const API_URL_Chanel = `https://www.googleapis.com/youtube/v3/playlists?part=snippet&channelId=${CHANNEL_ID}&maxResults=20&key=${API_KEY}`;

const API_URL_playlist = (PLAYLIST_ID) => {
  return `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=20&key=${API_KEY}`;
};

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
  videos: [],
};

export const getAllPlaylist = createAsyncThunk(
  "freeVideos/getAllPlaylist",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(API_URL_Chanel);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب القوائم ",
          location: "  الفيديوهات المجانية",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getAllPlaylistVideos = createAsyncThunk(
  "freeVideos/getAllPlaylistVideos",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      const response = await axios.get(API_URL_playlist(id));
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب الفيديوهات من القائمه ",
          location: "  الفيديوهات المجانية",
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const freeVideosSlice = createSlice({
  name: "freeVideos",
  initialState: initState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get playlists
      .addCase(getAllPlaylist.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllPlaylist.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllPlaylist.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // get  get playlists videos
      .addCase(getAllPlaylistVideos.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllPlaylistVideos.fulfilled, (state, action) => {
        state.loading = false;
        state.videos = action.payload;
        state.complete = false;
      })
      .addCase(getAllPlaylistVideos.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      });
  },
});
export default freeVideosSlice.reducer;
