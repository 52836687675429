import React, { useEffect, useRef, useState } from "react";
import css from "./LecutreWatchPage.module.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLectureByID, resetLectureData } from "../../../Store/LectureSlice";
import { CountWatch } from "../../../Store/CourseSlice";
import LoadingModel from "../../../Model/Loading/Loading";
import PDFViewer from "../../../Components/PDFViewer/PDFViewer";

export default function LectureWatchPage() {
  const { records, loading } = useSelector((state) => state.lecture);
  const meRedux = useSelector((state) => state.me);
  const playerRef = useRef(null);
  const [watch, setWatch] = useState();
  const { LectureId, courseId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      dispatch(getLectureByID(LectureId));
    } catch (err) {
      console.error(err);
    }

    return () => {
      dispatch(resetLectureData());
    };
  }, [LectureId, dispatch]);

  useEffect(() => {
    const wall = setInterval(() => {
      if (playerRef.current) {
        playerRef.current.style.display = "block";
      }
    }, 1000);

    if (
      meRedux?.records?.role === "manager" ||
      meRedux?.records?.role === "teacher"
    ) {
      clearInterval(wall);
      console.log(playerRef.current);
      if (playerRef.current) {
        playerRef.current.style.display = "none";
      }
    }

    if (watch) {
      try {
        dispatch(CountWatch(courseId)).then(() => {
          clearInterval(wall);
          playerRef.current.style.display = "none";
        });
      } catch (err) {
        console.error(err);
      }
    }

    return () => clearInterval(wall);
  }, [courseId, dispatch, meRedux.records.role, watch]);

  return (
    <>
      {loading ? (
        <LoadingModel />
      ) : (
        <div className={`${css.container}`}>
          <div className="container">
            <h3
              className="text-center mt-3 mb-4"
              style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
            >
              {`مرحبا بك في محاضرة ( ${records?.data?.lecture} )`}
            </h3>
            {records?.data?.video && (
              <div
                className="container p-1 my-2 rounded-3"
                style={{
                  minWidth: "320px",
                  maxWidth: "850px",
                  backgroundColor: "#29aae25e",
                  boxShadow: "var(--main-box-shadow)",
                }}
              >
                <h5 className="text-center py-2">الفيديو الخاص بالمحاضره</h5>
                <div style={{ position: "relative", paddingTop: "56.25%" }}>
                  {meRedux.records.role === "user" && (
                    <div
                      onClick={() => setWatch(true)}
                      ref={playerRef}
                      className={css.CountWatch}
                    ></div>
                  )}
                  <iframe
                    src={`https://iframe.mediadelivery.net/embed/${records.data.bunny.videoLibraryId}/${records.data.bunny.guid}?autoplay=false&loop=false&muted=false&preload=false&responsive=true`}
                    loading="lazy"
                    title="video"
                    style={{
                      border: 0,
                      position: "absolute",
                      top: 0,
                      height: "100%",
                      width: "100%",
                      borderRadius: "8px",
                    }}
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
                    allowFullScreen={true}
                    playsInline={true}
                  ></iframe>
                </div>
              </div>
            )}
            {records?.data?.pdf && (
              <>
                <h5 className="my-5 text-center">ال pdf الخاص بالمحاضره </h5>
                <div className="my-3">
                  <PDFViewer url={records?.data?.pdf} />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
