import React, { useEffect, useState } from "react";
import "./HomeHeadSlider.css";
import AAAA from "../../../dist/img/1600-X-900.jpg";

export default function HomeHeadSlider({ data }) {
  const [defaultImg, setDefaultImg] = useState(data);
  useEffect(() => {
    !data || data?.length < 1
      ? setDefaultImg([{ image: AAAA }])
      : setDefaultImg(data)
  }, [data]);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide carousel-fade"
      data-bs-ride="carousel"
      data-bs-interval="2000"
    >
      <div className="carousel-indicators">
        {defaultImg?.map((_, index) => (
          <button
            key={`indicator-${index}`}
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : "false"}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {defaultImg?.map((img, index) => (
          <div
            key={`slide-${index}`}
            className={`carousel-item ${index === 0 ? "active" : ""} `}
            // style={{maxHeight:"100vh"}}
          >
            <img
              className="slider_img"
              src={img.image}
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
