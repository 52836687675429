import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  getAllTransactions,
  resetTransactionsData,
} from "../../Store/TransactionsSlice";
import { getTeacherByID } from "../../Store/TeacherSlice";
import MainTable from "../../Components/tables/MainTable/MainTable";
import css from "./TeacherPage.module.css";
import TeacherDetails from "../../Components/TeacherDetails/TeacherDetails";
import MainModel from "../../Model/mainModel/MainModel";
// import UserChangePassword from "../../Components/Forms/UserChangePassword/UserChangePasswordForm";
import { useNavigate } from "react-router-dom";
import { resetData } from "../../Store/getMeSlice";
import DeleteBtn from "../../Components/Buttons/DeleteBtn/DeleteBtn";
import ChangePasswordBtn from "../../Components/Buttons/ChangePasswordBtn/ChangePasswordBtn";

export default function TeacherPage() {
  const { records, loading } = useSelector((state) => state.transaction);
  const meRedux = useSelector((state) => state.me);
  const TeacherRedux = useSelector((state) => state.teacher);
  // const [openModel, setOpenModel] = useState(null);
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    const logoutSure = () => {
      Cookies.remove("access_token");
      navigate("/");
      dispatch(resetData());
    };
    setSelectedRowForDelete(() => logoutSure);
    setModelDelete(true);
  };

  useEffect(() => {
    dispatch(getAllTransactions());
    dispatch(getTeacherByID(meRedux?.records?._id));
    window.scrollTo({ top: 0, behavior: "smooth" });
    return () => {
      resetTransactionsData();
    };
  }, [dispatch, meRedux?.records?._id]);
  const handleExpiryTime = (expires) => {
    let date = new Date(expires);
    let formattedDate = date.toLocaleDateString("ar-EG");
    let formattedTime = date.toLocaleTimeString("ar-EG", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${formattedTime} - ${formattedDate}`;
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "sender",
        header: "الراسل",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              className={`${
                row?.original?.sender?.name === meRedux.records.name
                  ? " text-danger fs-6"
                  : ""
              }`}
            >
              {row?.original?.sender?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "receiver",
        header: "المستلم",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
            // className={`${
            //   row?.original?.receiver?.name === meRedux.records.name
            //     ? " text-success fs-6"
            //     : ""
            // }`}
            >
              {row?.original?.receiver?.name}
            </span>
          </>
        ),
      },
      {
        accessorKey: "pointsSent",
        header: "النقاط المرسلة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
            className=" text-success fs-6"
            >
              {row?.original?.pointsSent}
              {" جنيه"}
            </span>
          </>
        ),
      },
      {
        accessorKey: "date",
        header: "التاريخ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{handleExpiryTime(row?.original?.date)}</span>
          </>
        ),
      },
    ],
    [meRedux.records.name]
  );

  return (
    <div className={css.container}>
      <div className={`container `}>
        <div className=" d-flex align-items-center justify-content-evenly mt-5">
          <ChangePasswordBtn/>
          <div onClick={() => logOut()} className="btn btn-primary">
            {" "}
            تسجيل الخروج{" "}
          </div>
        </div>
        <h3
          style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
          className="text-center p-3 mt-5"
        >
          {`  الفلوس المستلمه من بيع المحتوي ( ${meRedux?.records?.point} ) جنيه`}
        </h3>
        <MainTable
          data={records?.data || []}
          columns={columns}
          isLoading={loading}
          // transaction={records?.data?.totalPoints}
        />
        <h3
          style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
          className="text-center p-3 mt-5"
        >
          معلوماتي
        </h3>
        <div className={css.details}>
          <TeacherDetails data={TeacherRedux.records} />
        </div>
      </div>
      {/* {openModel && (
        <MainModel setModel={setOpenModel} content={<UserChangePassword />} />
      )} */}
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              deleteData={selectedRowForDelete}
              msg="هل تريد تسجيل الخروج ؟"
            />
          }
        />
      )}
    </div>
  );
}
