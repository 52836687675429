import React, { useEffect, useState } from "react";

export default function TodayTime() {
  const [clock, setClock] = useState(new Date().toLocaleTimeString("ar-EG"));
  const [date, setDate] = useState(
    new Date().toLocaleDateString("ar-EG", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      setClock(currentTime.toLocaleTimeString("ar-EG"));
      setDate(
        currentTime.toLocaleDateString("ar-EG", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      dir="rtl"
      className="d-flex align-items-center justify-content-center flex-column blur rounded p-1"
      style={{fontSize:"10px"}}
    >
      <div>{date}</div>
      <div>{clock}</div>
    </div>
  );
}
