import React, { useEffect } from "react";
import { resetVideoData } from "../../Store/VideoSlice.js";
import { useDispatch } from "react-redux";

export default function UploadVideoModel({ progress }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (+progress === 100) {
      try {
        dispatch(resetVideoData());
      } catch (err) {
        console.error(err);
      }
    }
  }, [dispatch, progress]);

  return (
    <>
      <div
        style={{
          zIndex: "999999999999",
          position: "fixed",
          top: "75px",
          left: "5px",
          backgroundColor: "#29aae250",
          boxShadow: " var(--main-box-shadow)",
          borderRadius: "  6px",
          border: "1px solid var(--main-color)",
        }}
      >
        <div className="d-flex align-items-center justify-content-center w-100 p-2">
          <span style={{ fontSize: "16px", color: "#fff" }}>
            جاري الان رفع الفيديو {progress}%
          </span>
        </div>
        <div
          style={{ height: "5px" }}
          className="progress"
          role="progressbar"
          aria-label="Success striped example"
          aria-valuenow={`${progress}`}
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            className={`progress-bar progress-bar-striped bg-primary`}
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </>
  );
}
