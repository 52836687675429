import { useFormik } from "formik";
import React, { useEffect } from "react";
import { RiAdminFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeachers, resetTeacherData } from "../../../Store/TeacherSlice";
import { getForOneTeacherGallery } from "../../../Store/GalleryTeacherSlice";

export default function ShercheTeacharGallery() {
  const { records, loading } = useSelector((state) => state.teacher);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllTeachers());
    return () => {
      dispatch(resetTeacherData());
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      id: "",
    },

    onSubmit: async (values) => {
      try {
        dispatch(getForOneTeacherGallery(values.id));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  useEffect(() => {
    if (formik.values.id) {
      formik.handleSubmit();
    }
  }, [formik.values.id]); 

  return (
    <div
      className="p-2  pb-3 rounded text-center mb-3"
      style={{ backgroundColor: "var(--light-color)" }}
    >
      <h3 className="text-center py-3"> ابحث عن صور المدرس </h3>
      <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="row gx-4">
          <div className="col-12 mb-3">
            <div className="input-group has-validation flex-grow-1 ms-md-2">
              <span className="input-group-text rounded-0 rounded-end">
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    aria-hidden="true"
                  ></span>
                ) : (
                  <RiAdminFill
                    className="fs-5"
                    style={{ color: "var(--main-color)" }}
                  />
                )}
              </span>
              <div className="form-floating is-invalid">
                <select
                  style={{ color: "var(--gray-color)" }}
                  id="id"
                  className={`form-select form-select-md rounded-0 rounded-start ${
                    formik.touched.id && formik.errors.id && "is-invalid"
                  }`}
                  aria-label="Large select example"
                  {...formik.getFieldProps("id")}
                >
                  <option value="" disabled>
                    اختر المدرس
                  </option>
                  {records?.data?.map((id, i) => (
                    <option key={i} value={id?._id}>
                      {id?.name}
                    </option>
                  ))}
                </select>
              </div>
              {formik.touched.id && formik.errors.id && (
                <div className="invalid-feedback">{formik.errors.id}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
