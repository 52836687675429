import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "../MainStyleForms.module.css";
import { useFormik } from "formik";
import { IoAdd } from "react-icons/io5";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { createHonour, editHonour } from "../../../Store/HonourSlice";
import { honourSliderFormValid } from "../../../Util/VallationForms";
// import { FaChalkboardTeacher } from "react-icons/fa";
// import AlertsModel from "../../../Model/AlertsModel/AlertsModel";

export default function HonourForm({ type, SendData }) {
  const { loading } = useSelector((state) => state.honour);
  const [img, setImg] = useState(SendData?.image || null);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: SendData?.name || "",
      image: SendData?.image || null,
    },
    validationSchema: honourSliderFormValid,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("image", values.image);

      try {
        if (type === "edit") {
          if (typeof values.image === "object") {
            dispatch(editHonour([SendData._id, formData]));
          } else if (typeof values.image === "string") {
            formData.delete("image");
            dispatch(editHonour([SendData._id, formData]));
          }
        } else if (type === "add") {
          dispatch(createHonour(formData));
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const fileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imgs = URL.createObjectURL(file);
      setImg(imgs);
      formik.setFieldValue("image", file);
    }
  };

  return (
    <>
      <div className={`container ${css.container} `}>
        <h3
          style={{ color: "var(--dark-color)" }}
          className="text-center py-3"
        >{`${type === "add" ? "اضف" : "عدل"}  الصوره في لوحة الشرف  `}</h3>
        <form className="row g-3" onSubmit={formik.handleSubmit}>
          <div className="col-12 col-md-4 d-flex align-items-center justify-content-center ">
            <div className={css.image}>
              {img && <img src={img} alt="MyPhoto" loading="lazy"  />}
              <div>
                <label className={css.customFileInput} htmlFor="image">
                  <IoAdd />
                </label>
                <input
                  type="file"
                  id="image"
                  name="image"
                  accept=".png, .jpg, .jpeg"
                  onChange={fileChange}
                />
                {formik.touched.image && formik.errors.image && (
                  <div className="text-danger">{formik.errors.image}</div>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-8 d-flex align-items-center justify-content-between">
            <div className="input-group has-validation mb-2">
              <span className="input-group-text rounded-0 rounded-end">
                <MdDriveFileRenameOutline
                  className="fs-4"
                  style={{ color: "var(--main-color)" }}
                />
              </span>
              <div className="form-floating is-invalid ">
                <input
                  type="text"
                  className={`form-control rounded-0 rounded-start ${
                    formik.touched.name && formik.errors.name && "is-invalid"
                  }`}
                  id="name"
                  placeholder=""
                  {...formik.getFieldProps("name")}
                />
                <label
                  style={{ color: "var(--gray-color)" }}
                  htmlFor="name right-0"
                >
                  ادخل اسم الطالب
                </label>
              </div>
              {formik.touched.name && formik.errors.name && (
                <div className="invalid-feedback">{formik.errors.name}</div>
              )}
            </div>
          </div>
          <div className="col-12  d-flex justify-content-center align-content-center">
            <button
              type="submit"
              disabled={loading}
              className="btn btn-primary px-4 py-2 fs-5"
            >
              {!loading ? (type === "add" ? "اضافة" : "تعديل") : "جاري الارسال"}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm mx-2"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
