import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourse, resetCourseData } from "../../../Store/CourseSlice";
import LectureComponents from "../../../Components/LectureComponents/LectureComponents";

export default function UserSubscriptions() {
  const { records } = useSelector((state) => state.course);
  const [showCorses, setShowCorses] = useState([]);
  const [teacherName, setTeacherName] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCourse());

    return () => {
      dispatch(resetCourseData());
    };
  }, [dispatch]);

  const handelClick = (teacher) => {
    const filteredCourses = records?.data.couresItems?.filter(
      (course) => course.teacherID === teacher.teacherID
    );
    setShowCorses(filteredCourses);
    setTeacherName(teacher.name);
  };


  return (
    <>
      <div className="w-100 overflow-auto">
        <div
          className="btn-group d-flex align-items-center justify-content-center pt-3"
          role="group"
          aria-label="Basic radio toggle button group"
          dir="ltr"
        >
          {records?.data?.teacher ? (
            records.data.teacher.map((teacher, i) => (
              <React.Fragment key={teacher._id}>
                <input
                  type="radio"
                  className="btn-check"
                  name="btnradio"
                  id={`btnradio1${i}`}
                  autoComplete="off"
                  onClick={() => handelClick(teacher)}
                />
                <label
                  className="btn btn-primary"
                  htmlFor={`btnradio1${i}`}
                  style={{ minWidth: "100px" }}
                >
                  {teacher.name}
                </label>
              </React.Fragment>
            ))
          ) : (
            <h3
              style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
              className="text-center mt-5 py-5 px-2"
            >
              لم يتم شراء محاضرات بعد
            </h3>
          )}
        </div>
      </div>
      {showCorses.length < 1 ? (
        <h3
          style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
          className="text-center mt-5 py-5 px-2"
        >
          اضغط علي اسم المدرس لظهور المحاضرات التي تم شرائها
        </h3>
      ) : (
        <>
          <h3
            style={{ textShadow: "var(--dark-color) 0px 0px 10px" }}
            className="text-center py-5 px-2"
          >
            {`دروس استاذ ${teacherName} `}
          </h3>
          <LectureComponents data={showCorses} me={true} />
        </>
      )}
    </>
  );
}
