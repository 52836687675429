import { useDispatch, useSelector } from "react-redux";
import LevelsHome from "../../../Components/LevelsHome/LevelsHome";
import SectionsSeparator from "../../../Components/SectionsSeparator/SectionsSeparator";
import HomeHeadSlider from "../../../Components/Sliders/HomeHeadSlider/HomeHeadSlider";
import HonorBoardSlider from "../../../Components/Sliders/HonorBoardSlider/HonorBoardSlider";

import SliderTeacher from "../../../Components/Sliders/SliderTeacher/SliderTeacher";
import css from "./HomePage.module.css";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { getAllSlider } from "../../../Store/HeaderSliderSlice";
import { getAllHonour } from "../../../Store/HonourSlice";
import { getAllTeachers } from "../../../Store/TeacherSlice";
import three from "../../../dist/img/3.png";
import two from "../../../dist/img/2.png";
import one from "../../../dist/img/1.png";
import SectionFreeVideoComponent from "../../../Components/SectionFreeVideoComponent/SectionFreeVideoComponent";

export default function HomePage() {
  const token = Cookies.get("access_token");
  const HomeSlider = useSelector((state) => state.slider);
  const HonourSlider = useSelector((state) => state.honour);
  const TeacherSlider = useSelector((state) => state.teacher);

  const dispatch = useDispatch();
  useEffect(() => {
    try {
      dispatch(getAllSlider());
      dispatch(getAllHonour());
      dispatch(getAllTeachers());
    } catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  const data = [
    {
      title: "الصف الاول الثانوي",
      image: one,
    },
    {
      title: "الصف الثاني الثانوي",
      image: two,
    },
    {
      title: "الصف الثالث الثانوي",
      image: three,
    },
  ];

  return (
    <div className={css.container}>
      <HomeHeadSlider data={HomeSlider?.records?.data} />
      <SectionsSeparator name="مشاهدة الفيديوهات" />
      <SectionFreeVideoComponent />
      <SectionsSeparator name="لوحة الشرف" />
      <HonorBoardSlider
        data={HonourSlider?.records?.data}
        text="لوحة شرف بأفضل طلاب في منصة ابداع"
      />
      <SectionsSeparator name=" المدرسين" />
      <SliderTeacher data={TeacherSlider?.records?.data} />

      {!token && (
        <>
          <SectionsSeparator name=" الصفوف" />
          <LevelsHome data={data} />
        </>
      )}
    </div>
  );
}
