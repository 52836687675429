import React from "react";

export default function CheckoutPage() {
  return (
    <div
      style={{
        minHeight: "90vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <h4>الصفحة الخاصة با checkout </h4>
      <h1>سيتم اضافه خدمه فوري للدفع الالكتروني قريبا</h1>
    </div>
  );
}
