import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllHonour = createAsyncThunk(
  "honour/getAllHonour",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/honor");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب   لوحة الشرف ",
          location: " لوحة الشرف",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const deleteHonour = createAsyncThunk(
  "honour/deleteHonour",
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI;
    try {
      await axiosInstance.delete(`/honor/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف الصورة بنجاح",
          location: "لوحة الشرف",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف  من  لوحة الشرف ",
          location: " لوحة الشرف",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const createHonour = createAsyncThunk(
  "honour/createHonour",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/honor", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء الصورة بنجاح",
          location: "لوحة الشرف",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في انشاء  لوحة الشرف ",
          location: " لوحة الشرف",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editHonour = createAsyncThunk(
  "honour/editHonour",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/honor/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم تعديل الصورة بنجاح",
          location: "لوحة الشرف",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في التعديل علي  لوحة الشرف ",
          location: " لوحة الشرف",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const honourSlice = createSlice({
  name: "honour",
  initialState: initState,
  reducers: {
    resetHonourData(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllHonour
      .addCase(getAllHonour.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllHonour.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllHonour.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // deleteHonour
      .addCase(deleteHonour.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(deleteHonour.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteHonour.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      })
      // add createClass
      .addCase(createHonour.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createHonour.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = [...state.records.data, action.payload];
        state.complete = true;
      })
      .addCase(createHonour.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  editHonour
      .addCase(editHonour.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(editHonour.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.map((item) =>
          item?._id === action.payload.data?._id ? action.payload : item
        );
        state.complete = true;
      })
      .addCase(editHonour.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.complete = false;
      });
  },
});
export const { resetHonourData } = honourSlice.actions;
export default honourSlice.reducer;
