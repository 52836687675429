import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSections,
  resetSectionsData,
  deleteSection,
} from "../../../../Store/SectionSlice";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdDelete, MdEdit } from "react-icons/md";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";
import SectionForm from "../../../../Components/Forms/SectionForm/SectionForm";
import FilterDataForTeacher from "../../../../Components/Serches/FilterDataForTeacher/FilterDataForTeacher";

export default function CreateSection() {
  const { records, loading, complete, error } = useSelector(
    (state) => state.section
  );
  const meRedux = useSelector((state) => state.me);
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);
  const [sectionData, setSectionData] = useState(records?.data);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllSections());
    return () => {
      dispatch(resetSectionsData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllSections());
    }
  }, [complete, dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  const handleDeleteClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.name}) `);
      const dispatchData = () => {
        dispatch(deleteSection(row?.original?._id));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "صورة الباب",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "50px", height: "50px", borderRadius: "25%" }}
              src={row?.original?.image}
              alt="section-img"
            />
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "اسم الباب",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "description",
        header: " نبذة",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{`${row?.original?.description?.slice(0, 20)}...`}</span>
          </>
        ),
      },
      {
        accessorKey: "price",
        header: " السعر",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{`  ${row?.original?.price} جنيه`}</span>
          </>
        ),
      },
      {
        accessorKey: "discount",
        header: " الخصم",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{`  ${row?.original?.discount} % `}</span>
          </>
        ),
      },
      {
        accessorKey: "class",
        header: "الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.class?.name} </span>
          </>
        ),
      },
      {
        accessorKey: "teacher",
        header: "المدرس",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.class?.teacher.name} </span>
          </>
        ),
      },
      {
        id: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex algin-items-center justify-content-center gap-2">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            {meRedux.records.role === "manager" && (
              <MainBtn
                btn={<MdDelete onClick={() => handleDeleteClick(row)} />}
              />
            )}
          </div>
        ),
      },
    ],
    [meRedux.records.role, handleEditClick, handleDeleteClick]
  );
  return (
    <>
      <div
        className="p-2 pb-3 rounded text-center mb-3"
        style={{ backgroundColor: "var(--light-color)" }}
      >
        <h3 className="text-center py-3">اختر المدرس</h3>
        <FilterDataForTeacher
          sectionsData={records?.data}
          setSectionsData={setSectionData}
        />
      </div>
      <h3
        style={{
          color: "var(--dark-color)",
          textShadow: "var(--main-color) 0px 0px 10px",
        }}
        className="text-center py-1"
      >
        {" "}
        الابواب
      </h3>
      <MainTable
        data={sectionData || records?.data || []}
        columns={columns}
        isLoading={loading}
        form={<SectionForm type="add" SendData={[]} />}
      />
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {error && (
        <AlertsModel error={error} msg="هناك خطاء في الابواب" place="الباب" />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<SectionForm type="edit" SendData={sendData} />}
        />
      )}
    </>
  );
}
