import { FaUpLong } from "react-icons/fa6";
import css from "./ScrollTop.module.css";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState, useEffect } from "react";

export default function ScrollTop() {
  const [scrollValue, setScrollValue] = useState(0);
  const [visible, setVisible] = useState(false);
  const scrollThreshold = 120;
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercent = Math.floor((scrollTop * 100) / height);
      setScrollValue(scrollPercent);
      setVisible(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ScrollUp = () => {
    setScrollValue(0)
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div
      onClick={ScrollUp}
      className={css.bodyScrollTop}
      style={{
        display: visible ? "block" : "none",
      }}
    >
      <CircularProgressbarWithChildren
        value={scrollValue}
        styles={buildStyles({
          pathColor: "var(--main-color)",
          trailColor: "#FFF",
        })}
      >
        <div>
          <FaUpLong className={css.icon} />
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
