import React from "react";
import css from "./SectionsSeparator.module.css";

export default function SectionsSeparator({ name }) {
  return (
    <div className={css.container}>
      <div className={css.caverImg}></div>
      <h1 className={css.effect} data-text={name}>
        {name}
      </h1>
      <svg
        className={css.editorial1}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id="gentle-wave"
            d="M-160 44c30 0 
            58-18 88-18s
            58 18 88 18 
            58-18 88-18 
            58 18 88 18
            v44h-352z"
          />
        </defs>
        <g className={css.parallax1}>
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="3"
            fill="var(--light-color)"
          />
        </g>
        <g className={css.parallax2}>
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="0"
            fill="var(--light-color)"
          />
        </g>
        <g className={css.parallax3}>
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="9"
            fill="var(--light-color)"
          />
        </g>
        <g className={css.parallax4}>
          <use
            xlinkHref="#gentle-wave"
            x="50"
            y="6"
            fill="var(--light-color)"
          />
        </g>
      </svg>
    </div>
  );
}
