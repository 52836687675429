import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";

const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
};

export const getAllCoupons = createAsyncThunk(
  "coupons/getAllCoupons",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/coupon?limit=50000");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب الكوبونات",
          location: "الكوبونات",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);
// export const cheekCoupon = createAsyncThunk(
//   "coupons/cheekCoupon",
//   async (code, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.get(
//         `/coupon/checkCoupon?code=${code}`
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const createCoupons = createAsyncThunk(
  "coupons/createCoupons",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/coupon", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء الكوبونات بنجاح",
          location: "الكوبونات",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في انشاء الكوبونات",
          location: "الكوبونات",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const couponsSlice = createSlice({
  name: "coupons",
  initialState: initState,
  reducers: {
    resetError(state) {
      state.error = false;
      state.complete = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addCase(getAllCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(getAllCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(getAllCoupons.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch coupons. Please try again.";
        state.complete = false;
      })
      // add
      .addCase(createCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(createCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(createCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      });
    // // cheekCoupon
    // .addCase(cheekCoupon.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    //   state.complete = false;
    // })
    // .addCase(cheekCoupon.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.records = action.payload;
    //   state.complete = true;
    // })
    // .addCase(cheekCoupon.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = `${action.payload}`;
    //   state.complete = false;
    // });
  },
});
export const { resetError } = couponsSlice.actions;
export default couponsSlice.reducer;
