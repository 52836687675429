import { useFormik } from "formik";
import css from "../MainStyleForms.module.css";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlinePassword } from "react-icons/md";
import { UserChangePasswordValidation } from "../../../Util/VallationForms";
import { useDispatch, useSelector } from "react-redux";
import { changePassword, resetData } from "../../../Store/AuthSlice";
import { useEffect } from "react";
import AlertsModel from "../../../Model/AlertsModel/AlertsModel";

export default function UserChangePasswordForm() {
  const { records ,error,complete,loading} = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => dispatch(resetData());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      currentPasword: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: UserChangePasswordValidation,

    onSubmit: async (values) => {
      try {
        dispatch(changePassword(values));
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  return (
    <div className={`container ${css.container} `}>
      <h3 className="text-center py-3">تغير كلمة المرور الخاصة بالحساب</h3>
      <form className="row g-3" onSubmit={formik.handleSubmit}>
        <div className="col-12">
          <div className="input-group has-validation mb-2">
            <span className="input-group-text rounded-0 rounded-end">
              <RiLockPasswordFill
                className="fs-4"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.currentPasword &&
                  formik.errors.currentPasword &&
                  "is-invalid"
                }`}
                id="currentPasword"
                placeholder=""
                {...formik.getFieldProps("currentPasword")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="currentPasword right-0"
              >
                ادخل كلمة المرور القديمه
              </label>
            </div>
            {formik.touched.currentPasword && formik.errors.currentPasword && (
              <div className="invalid-feedback">
                {formik.errors.currentPasword}
              </div>
            )}
          </div>
          <div className="input-group has-validation mb-2">
            <span className="input-group-text rounded-0 rounded-end">
              <MdOutlinePassword
                className="fs-4"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.password &&
                  formik.errors.password &&
                  "is-invalid"
                }`}
                id="password"
                placeholder=""
                {...formik.getFieldProps("password")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="name right-0"
              >
                ادخل كلمة المرور الجديدة
              </label>
            </div>
            {formik.touched.password && formik.errors.password && (
              <div className="invalid-feedback">{formik.errors.password}</div>
            )}
          </div>
          <div className="input-group has-validation mb-2">
            <span className="input-group-text rounded-0 rounded-end">
              <MdOutlinePassword
                className="fs-4"
                style={{ color: "var(--main-color)" }}
              />
            </span>
            <div className="form-floating is-invalid ">
              <input
                type="password"
                className={`form-control rounded-0 rounded-start ${
                  formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm &&
                  "is-invalid"
                }`}
                id="passwordConfirm"
                placeholder=""
                {...formik.getFieldProps("passwordConfirm")}
              />
              <label
                style={{ color: "var(--gray-color)" }}
                htmlFor="name right-0"
              >
                اكد كلمة المرور
              </label>
            </div>
            {formik.touched.passwordConfirm &&
              formik.errors.passwordConfirm && (
                <div className="invalid-feedback">
                  {formik.errors.passwordConfirm}
                </div>
              )}
          </div>
        </div>
        <div className="col-12  d-flex justify-content-center align-content-center">
          <button
            type="submit"
            // disabled={loading}
            className="btn btn-primary px-4 py-2 fs-5"
          >
            {!loading ? "تغير" : "جاري تغير"}
            {loading && (
              <span
                className="spinner-border spinner-border-sm mx-2"
                aria-hidden="true"
              ></span>
            )}
          </button>
        </div>
      </form>
      {error && (
        <AlertsModel error={error} msg="لم يتم تغير كلمة المرور" place="ملفي" />
      )}
     {complete && (
        <AlertsModel error={error} msg=" تم تغير كلمة المرور " place="ملفي" />
      )}
    </div>
  );
}
