import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import "swiper/css/pagination";
import css from "./HonorBoardSlider.module.css";
import AAAA from "../../../dist/img/1600-X-900.jpg";

import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

export default function HonorBoardSlider({ data, text }) {
  const [defaultImg, setDefaultImg] = useState(data);
  useEffect(() => {
    !data || data?.length < 1
      ? setDefaultImg([{ image: AAAA }])
      : setDefaultImg(data);
  }, [data]);
  
  return (
    <div className="container">
      <h3
        style={{ textShadow: "var(--dark-color) 0px 0px 8px" }}
        className="text-center py-4 "
      >
        {text}
      </h3>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        spaceBetween={0}
        loop={true}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          reverseDirection: true,
        }}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        className={`mySwiper ${css.mySwiper}`}
      >
        {defaultImg?.map((img, i) => (
          <SwiperSlide key={i} className={css.SwiperSlide}>
            <img src={img.image} alt={`Img-${img.name}`} loading="lazy" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
