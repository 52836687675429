import React, { useCallback, useEffect, useMemo, useState } from "react";
import MainModel from "../../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../../Components/Buttons/DeleteBtn/DeleteBtn";
import AlertsModel from "../../../../Model/AlertsModel/AlertsModel";
import { useDispatch, useSelector } from "react-redux";
import MainBtn from "../../../../Components/Buttons/MainBtn/MainBtn";
import { MdEdit } from "react-icons/md";
import MainTable from "../../../../Components/tables/MainTable/MainTable";
import { TiUserDelete } from "react-icons/ti";
import {
  getAllTeachers,
  resetTeacherData,
  // resetPointTeacher,
} from "../../../../Store/TeacherSlice";
import { resetPointTeacher } from "../../../../Store/TeacherSlice.js";
import CreateTeacherForm from "../../../../Components/Forms/CreateTeacherForm/CreateTeacherForm";

export default function CreateTeacher() {
  const { records, loading, error, complete } = useSelector(
    (state) => state.teacher
  );
  const dispatch = useDispatch();
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [openModel, setOpenModel] = useState(null);
  const [sendData, setSendData] = useState([]);

  useEffect(() => {
    dispatch(getAllTeachers());

    return () => {
      dispatch(resetTeacherData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (complete) {
      dispatch(getAllTeachers());
    }
  }, [complete, dispatch]);

  const handleEditClick = useCallback((row) => {
    setSendData(row.original);
    setOpenModel((prevModel) => !prevModel);
  }, []);

  const handleResetPointClick = useCallback(
    (row) => {
      setModelDelete((prevModelDelete) => !prevModelDelete);
      setDeleteMessage(
        ` هل انت متأكد من حذف النقاط ل (${row?.original?.name}) `
      );
      const dispatchData = () => {
        const formData = new FormData();
        formData.append("point", 0);
        dispatch(resetPointTeacher([row?.original?._id, formData]));
      };
      setSelectedRowForDelete(() => dispatchData);
    },
    [dispatch]
  );

  // const handleDeleteClick = useCallback(
  //   (row) => {
  //     setModelDelete((prevModelDelete) => !prevModelDelete);
  //     setDeleteMessage(`هل انت متأكد من حذف ال (${row?.original?.name}) `);
  //     const dispatchData = () => {
  //       dispatch(deleteTeacher(row?.original?._id));
  //     };
  //     setSelectedRowForDelete(() => dispatchData);
  //   },
  //   [dispatch]
  // );

  const columns = useMemo(
    () => [
      {
        accessorKey: "image",
        header: "صورة الصف",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <img
              style={{ width: "45px", height: "45px", borderRadius: "25%" }}
              src={row?.original?.image}
              alt={`admin-img`}
            />
            {/* <img
              style={{ borderRadius: "25%", height: "30px", width: "30px" }}
              alt={"avatar-img"}
              src={row?.original?.avater}
            />
            <img
              style={{ borderRadius: "25%", height: "30px", width: "30px" }}
              alt={"avatar-img"}
              src={row?.original?.picture}
            /> */}
          </>
        ),
      },
      {
        accessorKey: "name",
        header: "اسم المدرس ",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.name}</span>
          </>
        ),
      },
      {
        accessorKey: "email",
        header: "الاميل",
        flex: 1,
      },
      {
        accessorKey: "phone",
        header: "الهاتف",
        flex: 1,
      },
      {
        accessorKey: "point",
        header: "النقاط",
        size: 50,
        Cell: ({ row }) => (
          <div className=" d-flex align-items-center justify-content-evenly">
            <span className="me-2 fs-6">{row?.original?.point}</span>
            <MainBtn
              btn={<TiUserDelete onClick={() => handleResetPointClick(row)} />}
            />
          </div>
        ),
      },
      {
        accessorKey: "subject",
        header: "الماده",
        size: 50,
        Cell: ({ row }) => (
          <>
            <span>{row?.original?.subject}</span>
          </>
        ),
      },
      {
        accessorKey: "نبذة ",
        header: "Description",
        flex: 1,
        Cell: ({ row }) => (
          <>
            <span
              title={row?.original?.description}
              key={row?.original?._id}
            >{`${row?.original?.description?.slice(0, 20)}...`}</span>
          </>
        ),
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 100,
        Cell: ({ row }) => (
          <div className="d-flex align-content-center justify-content-center gap-1">
            <MainBtn btn={<MdEdit onClick={() => handleEditClick(row)} />} />
            {/* <MainBtn
              btn={
                <MdDelete
                  className="mainBtnIcon"
                  onClick={() => handleDeleteClick(row)}
                />
              }
            /> */}
          </div>
        ),
      },
    ],
    [handleEditClick, handleResetPointClick]
  );

  return (
    <>
      <h4 className="text-center py-2">المدرسين</h4>
      <MainTable
        data={records?.data || []}
        columns={columns}
        isLoading={loading}
        // form={<CreateTeacherForm type="add" SendData={[]} />}
      />
      {error && (
        <AlertsModel error={error} msg="هناك خطاء في الصفوف" place="الصف" />
      )}
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              msg={deleteMessage}
              deleteData={selectedRowForDelete}
            />
          }
        />
      )}
      {openModel && (
        <MainModel
          setModel={setOpenModel}
          content={<CreateTeacherForm type="edit" SendData={sendData} />}
        />
      )}
      {error && (
        <AlertsModel
          error={error}
          msg="هناك خطاء في المدرسين"
          place="المدرسين"
        />
      )}
    </>
  );
}
