import React from "react";

export default function LoadingModel() {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh", width: "100%" }}
    >
      <div
        className="spinner-grow text-primary"
        role="status"
        style={{ height: "150px", width: "150px" }}
      ></div>
    </div>
  );
}
