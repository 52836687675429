import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import css from "./MainModel.module.css";
import { IoCloseSharp } from "react-icons/io5";

export default function MainModel({ setModel, content }) {
  const [opening, setOpening] = useState(true);

  useEffect(() => {
    document.body.classList.add("stop-scrolling");

    return () => {
      document.body.classList.remove("stop-scrolling");
    };
  }, []);

  const handlePopup = () => {
    setModel(false);
    setOpening(false);
  };

  if (!opening) return null;

  return ReactDOM.createPortal(
    <div className={css.container}>
      <div onClick={handlePopup} className={css.overlay}></div>
      <div className={css.body}>
        <div className={css.hight}>{content}</div>
        <span onClick={handlePopup} className={`btn btn-danger ${css.btn}`}>
            <IoCloseSharp className="fs-3" />
        </span>
      </div>
    </div>,
    document.body
  );
}
