import React from "react";
import { Outlet } from "react-router-dom";
import HomeFooter from "../../Components/Footer/HomeFooter";
import ScrollTop from "../../Components/Buttons/ScrollTop/ScrollTop";
import HomeHeader from "../../Components/Header/HomeHeader/HomeHeader";
import { useSelector } from "react-redux";
import AlertsModel from "../../Model/AlertsModel/AlertsModel";
import UploadVideoModel from "../../Model/UploadVideoModel/UploadVideoModel";

export default function Home() {
  const errors = useSelector((state) => state.errors);
  const { loading, uploadProgress } = useSelector((state) => state.video);

  return (
    <>
      <HomeHeader />
      <div style={{minHeight:"100vh"}}>
        <Outlet />
      </div>
      <footer>
        <HomeFooter />
      </footer>
      <ScrollTop />
      {errors && <AlertsModel data={errors} />}
      {loading && <UploadVideoModel progress={uploadProgress} />}
    </>
  );
}
