import React from "react";
import css from "./Policies.module.css";

export default function PrivacyPolicy() {
  return (
    <div className={css.container}>
      <div className="container">
        <div>
          <h2 className="text-center">سياسة الخصوصية</h2>
          <p>
            تدرك منصة إبداع التعليمية أهمية الخصوصية وحماية البيانات الشخصية في
            العصر الرقمي، ولذلك نحن ملتزمون بحماية خصوصية مستخدمي خدمات منصة
            إبداع التعليمية أثناء تصفح أو استخدام موقعنا الإلكتروني أو خدماتنا
            التعليمية.
          </p>
          <p>
            ستوضح سياسة الخصوصية هذه (المشار إليها فيما بعد بـ "سياسة الخصوصية")
            كيف تضمن منصة إبداع التعليمية حماية البيانات الشخصية على مستوى
            متقدم، مثل أي معلومات تتعلق بالأشخاص الطبيعيين الذين يمكن تحديد
            هوياتهم بشكل مباشر أو غير مباشر، سواء باستخدام معلومات مثل الاسم أو
            رقم التعريف أو معلومات عن الموقع أو معرف الإنترنت. وتشمل هذه السياسة
            النقاط التالية:
          </p>
          <ul>
            <li>لماذا نقوم بجمع ومعالجة بياناتك الشخصية وكيف نستخدمها.</li>
            <li>
              الأساس القانوني الذي نقوم بناءً عليه بمعالجة بياناتك الشخصية
            </li>
            <li>الأطراف التي يمكنها الوصول إلى بياناتك الشخصية</li>
            <li>أين يمكن تخزين أو نقل بياناتك الشخصية.</li>
            <li>كيف نحمي أمن بياناتك الشخصية.</li>
            <li>مدة الاحتفاظ ببياناتك الشخصية</li>
            <li>
              الحقوق التي يمكنك ممارستها فيما يتعلق ببياناتك الشخصية بموجب
              القوانين واللوائح الحالية.
            </li>
            <li>
              كيفية التواصل معنا للاستفسارات أو الملاحظات المتعلقة بسياسة
              الخصوصية.
            </li>
          </ul>
          <p>
            تنطبق هذه السياسة على جميع الخدمات التي تقدمها منصة إبداع التعليمية
            عبر موقعها الإلكتروني وتطبيقاتها الرقمية، وتشمل الكورسات التعليمية
            وغيرها من الخصائص الرقمية (المشار إليها مجتمعة بـ "خدمات منصة إبداع
            التعليمية").
          </p>
          <p>
            قد نقوم بإرسال إشعارات خاصة أخرى تسلط الضوء على استخدامات معينة
            لبياناتك الشخصية أو لإتاحة الاشتراك أو إلغاء الاشتراك في خدمات
            محددة، بما في ذلك الخدمات الجديدة التي قد تكون لها سياسات خصوصية
            خاصة بها.
          </p>
          <h4>تعريفات رئيسية لسياسة الخصوصية:</h4>
          <ul>
            <li>"نحن" أو "لنا" تشير إلى منصة إبداع التعليمية.</li>
            <li>
              "أنت" أو "المستخدم" تشير إلى الشخص الذي يستخدم خدمات منصة إبداع
              التعليمية.
            </li>
          </ul>
        </div>
        <div>
          <h4>لماذا نقوم بجمع ومعالجة بياناتك الشخصية؟</h4>
          <p>نقوم بجمع ومعالجة بياناتك الشخصية لألغراض التالية:</p>
          <ul>
            <li>
              لتزويدك بالخدمات التي تطلبها: سواءً كانت الوصول إلى المحتويات
              التعليمية مثل الدروس أو الكورسات المتاحة على منصة إبداع التعليمية،
              أو لتفعيل استمارات عبر الإنترنت والاستطلاعات. كما نطلب بيانات
              شخصية مثل اسمك أو عنوان بريدك الإلكتروني أو رقم هاتفك لتسجيل حساب
              جديد أو الاشتراك في نشراتنا الإخبارية.
            </li>
            <li>
              لتسهيل المشاركة والتفاعل: نمنحك إمكانية المشاركة الكاملة في
              الميزات التفاعلية مثل المنتديات الدراسية، مناقشات الطلبة
              والمعلمين، وإنشاء حساب شخصي يمكن للآخرين رؤيته، والذي قد يشمل اسمك
              وصورتك، حسب اختيارك.
            </li>
            <li>
              لتحسين خدماتنا التعليمية: نستخدم بياناتك لفهم كيفية استخدامك
              لمنصتنا التعليمية بهدف تطوير وتحسين خدماتنا التعليمية وتقديم عروض
              جديدة.
            </li>
            <li>
              لمنحك خدمات خاصة باحتياجاتك واهتماماتك؛ لتزويدك بمحتوى مخصص مثل
              اإلعالنات والخدمات ذات الصلة؛ والسماح لنا بمعرفتك بشكل أفضل لمنحك
              المزيد من الخدمات التي تطلبها المزيد من العروض والعروض الحصرية
              والمعلومات والمسابقات ولمنحك تجربة أفضل كمستخدم؛ وللكشف عن أشياء
              أكثر تعقيدا مثال إي اإلعالنات قد تجدها أكثر فائدة أو أي الخدمات
              والمعلومات أكثر مالءمة لك، مما يمنحك تجربة أفضل وأكثر إفادة
              لممتلكاتنا الرقمية
            </li>
            <li>
              لتنفيذ المعاملات المالية: نحتفظ بسجلات معاملتك، مثل دفع رسوم
              الكورسات أو الاشتراكات، لضمان تفعيل الخدمة بشكل صحيح، والامتثال
              للمتطلبات القانونية والتنظيمية.
            </li>
            <li>
              للتواصل معك بشكل فعال: نستخدم بياناتك لتقديم الدعم الفني، ولحل
              المشكلات التي قد تواجهها أثناء استخدام خدماتنا، ولإرسال العروض
              الترويجية أو لإعلامك بالتحديثات الخاصة بخدماتنا التعليمية.
            </li>
            <li>
              لنشاء وللحفاظ على بيئة موثوقة وآمنة؛ التحقيق أو كشف أو منع أو
              اإلبالغ عن االحتيال أو التحريفات أو االنتهاكات أو الحوادث األمنية
              أو غيرها من األنشطة المحظورة أو غير القانونية المحتملة؛ ولحماية
              بائعينا وحقوقك وأمان وسالمة خدماتنا؛ ولتطبيق شروط الخدمة الخاصة
              بنا أو غيرها من االتفاقيات أو السياسات؛ وللتحقق من هويتك.
            </li>
            <li>
              االمتثال ألية قوانين أو أنظمة سارية؛ أو االستجابة لطلبات قانونية
              للحصول على معلومات من الحكومة أو من خالل اإلجراءات القانونية؛
              ولتحقيق أي غرض آخر يتم اإلفصاح عنه لك فيما يتعلق بخدماتنا؛
              واالتصال بك لحل النزاعات وتقديم المساعدة في خدماتنا.
            </li>
          </ul>
        </div>
        <div>
          <h4>على أي أساس قانوني نقوم بمعالجة بياناتك الشخصية؟</h4>
          <p>
            بنا ًء على نوع البيانات التي سيتم معالجتها، سنقوم بمعالجة بياناتك
            الشخصية على أحد األسس التالية:
          </p>
          <ul>
            <li>
              موافقتك المسبقة: على سبيل المثال، عندما نعرض عليك التسجيل في
              خدماتنا وإنشاء حساب لك، أو االشتراك في رسائلنا اإلخبارية أو رسائل
              البريد اإللكتروني التسويقية أو عندما نطلب منك اتباع أي إجراء ذو
              صلة يتيح لك إما قبول أو رفض معالجة البيانات المتوخاة بوضوح؛ في حال
              قبولك، يحق لك سحب موافقتك في أي وقت. ومع ذلك، وفي بعض الحاالت، إن
              اختيارك لعدم اإلفصاح عن بياناتك الشخصية أو سحب موافقتك قد يؤدي إلى
              الحد من إستخدامك لخدماتنا أو ميزاتنا أو عروضنا. على سبيل المثال،
              قد ال يكون بإمكانك الولوج إلى أجزاء معينة على مواقعنا اإللكترونية
              والرد على استفساراتك دون تقديم المعلومات ذات الصلة.
            </li>
            <li>
              عالقة تعاقدية: في مثل هذه الحالة، إن معالجة بياناتك الشخصية ضروري
              بشكل عام لتنفيذ أو إنجاز العقد؛ مما يعني أنه في حال عدم رغبتك
              بقيامنا بمعالجة بياناتك الشخصية في هذا السياق، قد نرفض الدخول في
              مثل هذا العقد معك.
            </li>
            <li>
              {" "}
              مصلحة مشروعة: بمعنى قانون حماية البيانات: على سبيل المثال عند
              تطبيق المراقبة أو أجهزة التعقب التي تشير إلى معالجة البيانات
              الشخصية للكشف عن عمليات االحتيال والتطفل عبر اإلنترنت ومنعها. مثال
              عندما تقوم بالوصول إلى وزيارة مواقعنا اإللكترونية، حتى بدون إنشاء
              حساب، واالستفادة من الخدمات التي ابداع التعليمية فإنك تقر بأننا قد
              نقوم بتنفيذ معالجة شرعية للبيانات كما هو موضح في سياسة الخصوصية
              هذه. إن كنت ال توافق على هذه السياسة، فقد ترغب في التوقف عن مشاهدة
              / استخدام مواقعنا اإللكترونية.
            </li>
            <li> االلتزامات القانونية المطبقة على أنشطتنا.</li>
          </ul>
        </div>
        <div>
          <h4>أين يمكن تخزين أو نقل بياناتك الشخصية؟</h4>
          <p>
            منصة إبداع التعليمية قد تكون جزءاً من شراكات مع شركات ومزودين في دول
            مختلفة حول العالم. ولهذا السبب، قد نحتاج إلى نقل بياناتك الشخصية إلى
            بلدان أخرى خارج بلد إقامتك، بما في ذلك خارج المنطقة التي قد تكون
            قوانين حماية البيانات فيها أقل صرامة من تلك المعمول بها في بلدك. على
            سبيل المثال، قد نقوم بتخزين ومعالجة بياناتك الشخصية على خوادم موجودة
            خارج بلد إقامتك.
          </p>
          <p>
            ومع ذلك، نحرص دائمًا على اتخاذ الضمانات المناسبة لضمان مستوى عالٍ من
            الحماية لبياناتك الشخصية، بغض النظر عن مكان تخزينها أو نقلها.
          </p>
          <p>
            إذا كنت بحاجة إلى مزيد من المعلومات حول كيفية حماية بياناتك الشخصية
            أو تفاصيل نقلها، يُرجى التواصل معنا كما هو موضح في قسم "كيفية
            الاتصال بنا".
          </p>
        </div>
        <div>
          <h4>كيف نحمي أمن بياناتك الشخصية؟</h4>
          <p>
            نحن نتبع إجراءات أمنية مادية وإلكترونية وتقنية وتدابيرأمنية وتنظيمية
            مناسبة، بما في ذلك نماذج الحوكمة لحماية بياناتك الشخصية وفقا لقانون
            حماية البيانات من الوصول غير المصرح به إلى البيانات الشخصية أو
            استخدامها أو تغييرها أو اإلفصاح عنها أو إتالفها
          </p>
          <p>
            يرجى المالحظة أنه ال يمكن ضمان نقل أية بيانات عبر اإلنترنت أو الموقع
            اإللكتروني من دون أي تدخل. باإلضافة إلى ذلك، عليك أيضا اتخاذ
            اإلجراءت المناسبة لحماية بياناتك الشخصية خاصة من خالل الحفاظ على
            كلمة المرور وسريتها. إن كنت تعلم أو لديك شكوك بأن بيانات اعتماد
            حسابك قد ضاعت أو تمت سرقتها أو اختلست أو تعرضت ألية مخاطر أخرى أو في
            حال وجود أي استخدام غير مصرح به فعليا لحسابك، يُرجى منك االتصال بنا
            باتباع التعليمات الواردة في الفقرة "كيفية االتصال بنا" أدناه.
          </p>
          <p>
            إن كنا نعلم أو لدينا سبب لالعتقاد أن بياناتك الشخصية قد تعرضت
            لالختراق، سوف نقوم فورا بإبالغ المستخدمين المتضررين من حدوث خرق وفقا
            للقوانين المعمول بها.
          </p>
        </div>
        <div>
          <h4>ما هي مدة االحتفاظ ببياناتك الشخصية؟</h4>
          <p>
            سوف نقوم باالحتفاظ ببيان اتك الشخصية طالما أن حسابك ناشط، أو طالما
            كان ذلك ضروريا للغرض الذي تم جمع البيانات من أجله أساسا. مدة
            الإلحتفاظ هذه قد تصل إلى 10 سنوات. وتستند فترات االحتفاظ لدينا على
            احتياجات أنشطتنا، والمعلومات الخاصة بك التي لم يعد هناك حاجة لها يتم
            تحويلها لمعلومات مجهولة بشكل قاطع أو تدميرها.
          </p>
          <p>
            نهدف إلى الحفاظ على خدماتنا بطريقة تحمي المعلومات من التدمير العرضي
            أو الضار. ولهذا السبب، بعد حذف المعلومات من خدماتنا، قد ال نقوم فورا
            بحذف النسخ المتبقية من خوادمنا النشطة وقد ال نزيل المعلومات من أنظمة
            النسخ االحتياطي الخاصة بنا.
          </p>
          <p>
            يجوز لنا أيضا االحتفاظ ببياناتك الشخصية لفترات أطول إذا لزم األمر
            لالمتثال اللتزاماتنا القانونية أو لحماية مصالحنا المشروعة
          </p>
        </div>
        <div>
          <h4>كيفية الوصول إلى وتحديث وحذف بياناتك الشخصية؟ </h4>
          <p>بموجب قانون حماية البيانات يحق لك ممارسة الحقوق التالية:</p>
          <ul>
            <li>
              {" "}
              الحق في الوصول إلى بياناتك الشخصية، وفي هذه الحالة قد نوفر لك نسخة
              عن هذه البيانات، إن لم يكن ذلك متاحا لك مباشرة، على سبيل المثال
              ضمن حسابك الشخصي؛
            </li>
            <li>
              {" "}
              الحق في تعديل بياناتك الشخصية، إن كانت بياناتك غير دقيقة أو قديمة؛
              في هذا الصدد، من المهم أن تكون البيانات الشخصية التي نحتفظ بها عنك
              دقيقة وحديثة. يرجى إطالعنا في حال حدث أي تغيير على بياناتك الشخصية
              خالل الفترة التي نحتفظ بها بمعلوماتك؛
            </li>
            <li>
              الحق في سحب الموافقة، حيث تعتمد المعالجة على موافقتك المسبقة وسحب
              موافقتك على جميع عمليات التسويق؛
            </li>
            <li>
              الحق على االعتراض على معالجة بياناتك الشخصية، حيث تعتمد المعالجة
              على مصالحن ا المشروعة، بقدر ما يسمح له موقفك؛
            </li>
            <li>الحق في مسح البيانات الشخصية الخاصة بك؛</li>
            <li> الحق في تقييد معالجة البيانات الشخصية الخاصة بك؛</li>
            <li>
              {" "}
              الحق في تلقي بياناتك الشخصية إلرسالها إلى طرف ثالث أو لنقل بياناتك
              الشخصية إلى طرف ثالث من اختيارك إن كان ذلك ممكنا تقنيا؛
            </li>
            <li>
              {" "}
              الحق في ت قديم شكوى لدى هيئة مختصة بحماية البيانات إذا اعتبرت أن
              معالجة بياناتك الشخصية قد انتهك قانون حماية البيانات.
            </li>
            <li>
              {" "}
              يمكنك إرسال طلب لنا بهذا الشأن في أي وقت كما هو موضح في قسم "كيفية
              االتصال بنا."
            </li>
            <p>يرجى مالحظة ما يلي:</p>
            <li> قد نحتاج إلى التحقق من هويتك قبل أن نتصرف بناء على طلبك؛</li>
            <li>
              {" "}
              بما أن ممارسة هذه الحقوق تخضع لشروط وقيود قانونية معينة، قد نضطر
              إلى رفض طلبك إذا لم يتم استيفاء هذه الشروط أو في حال وجود قيود
              قانونية؛
            </li>
            <li>
              {" "}
              قد نقوم برفض طلبات متكررة بدون سبب معقول، وتتطلب جهدا فنيا -على
              سبيل المثال تطوير نظام جديد أو تغيير أساسي للممارسات الحالية-، أو
              تشكيل خطر على خصوصية اآلخرين.
            </li>
            <li>
              على أية حال، سنسعى إلى التعامل بشكل فوري مع طلبك خالل شهر واحد
              -خاضعا ألية تمديدات نحققها قانونيا-. إذا قمنا برفض طلبك، سوف نخبرك
              باألسباب.
            </li>
          </ul>
        </div>
        <div>
          <h4>كيف نقوم باستخدام الكوكيز -Cookies -؟</h4>
          <p>
            الكوكي هي جزء صغير من المعلومات التي يرسلها خادم الويب إلى متصفح
            الويب، مما يتيح للخادم جمع المعلومات من المتصفح. تسمح لك معظم
            المتصفحات بإيقاف تشغيل الكوكيز. إن كنت ترغب بمعرفة كيف يمكنك القيام
            بذلك، يرجى االطالع على قائمة المساعدة على المتصفح الخاص بك. ومع ذلك،
            إن بعض الكوكيز مهمة لنا حتى نتمكن من تزويدك ابداع التعليمية التي
            طلبتها وسوف يؤدي إيقاف الكوكيز إلى الحد من استخدامك لملكياتنا
            الرقمية.
          </p>
          <p>
            على سبيل المثال، نحن نقوم بتخزين الكوكيز والتقنيات المشابهة
            ونستخدمها:
          </p>
          <ul>
            <li>
              {" "}
              لتحسين تجربتك كمستخدم وضمان الجودة الشاملة لخدمات ابداع التعليمية
            </li>
            <li>
              للتعرف عليك عند زيارتك المواقع االلكترونية الرقمية الخاصة بنا؛
            </li>
            <li>
              {" "}
              لمتابعة ودراسة أنماط التصفح والتنقل الخاصة بك في الموقع
              اإللكتروني، وإلنشاء ملفك الديموغرافي وتزويدك بالخدمة األكثر مالءمة
              لذوقك -توصيات أكثر مالءمة وعروض ذات صلة لما يحلو لك-؛
            </li>
            <li>
              {" "}
              لتحليل عادات استهالك المحتوى -على سبيل المثال، البرامج التي يتم
              مشاهدتها وقراءة المحتوى واألجهزة المستخدمة إلخ.- ولتوفير وتخصيص
              وقياس وتحسين إعالناتنا وتسويقنا؛
            </li>
            <li>
              {" "}
              لتقديم المحتوى المناسب المسموح به في كل منطقة جغرافية استنادا إلى
              حقوق الملكية الفكرية المرخصة لنا؛
            </li>
            <li>
              {" "}
              لمشاركة بعض البيانات لتوفير اإلعالن المخصص بك وإرسال مواد التسويق
              والرسائل اإلخبارية.
            </li>
          </ul>
          <p>
            كما نقوم بتبادل معلومات حول استخدامك لموقعنا مع وسائل التواصل
            االجتماعية وشركائنا في اإلعالنات واإلحصاءات الذين قد يقومون بدمجها
            مع معلومات أخرى قدمتها لهم أو التي جمعوها من استخدامك لخدماتهم.
          </p>
          <p>
            بإستخدامك خدمات ابداع التعليمية أنت توافق على ملفات الكوكي الخاصة
            بنا. لمعرفة المزيد حول كيفية استخدامنا للكوكيز، يرجى اإلتصال بنا كما
            هو موضح في قسم "كيفية االتصال بنا."
          </p>
        </div>
        <div>
          <h4>كيف تطبق سياسة الخصوصية هذه مع معالجة بيانات من طرف ثالث؟</h4>
          <p>
            قد يتم توفير خدمات ابداع التعليمية من خالل و/أو االستفادة من الميزات
            -مثل عناصر التحكم في الصوت- التي تشغلها منصات تابعة لطرف ثالث، أو
            تحتوي على روابط لمواقع يشغلها طرف ثالث قد تختلف سياساته المتعلقة
            بمعالجة البيانات الشخصية عن تلك السياسات الخاصة بنا
          </p>
          <p>فمثال:</p>.
          <ul>
            <li>
              {" "}
              قد تتمكن من الوصول إلى خدمات ابداع التعليمية من خالل منصات مثل
              أنظمة األلعاب وأجهزة التلفزيون الذكية وأجهزة الجوال وأجهزة فك
              التشفير وعدد آخر من األجهزة المتصلة باإلنترنت. باإلضافة إلى ذلك،
              قد تواجه تطبيقات تابعة لطرف ثالث تتفاعل مع خدمات ابداع التعليمية.
              هذا الطرف الثالث لديه خصوصية وسياسات بيانات الشخصية المنفصلة
              والمستقلة.
            </li>
            <li>
              {" "}
              قد تحت وي مواقعنا الرقمية على روابط لمواقع الكترونية أخرى خارجة عن
              سيطرتنا وال تغطيها سياسة الخصوصية هذه. في حال قمت بالدخول إلى
              مواقع أخرى من خالل استخدام الروابط المتاحة، يمكن لمشغلي تلك
              المواقع جمع معلومات منك واستخدامها وفقا لسياسات الخصوصية الخاصة
              بهم، والتي قد تختلف عن تلك الخاصة بنا.
            </li>
          </ul>
          <p>
            ندعوك لمراجعة هذه السياسات والشروط إن كنت ترغب في االستفسار عن
            معالجة بياناتك الشخصية من قبل طرف ثالث.
          </p>
        </div>
        <div>
          <h4>كيف يمكن تحديث سياسة الخصوصية هذه؟</h4>
          <p>
            قد نقوم بتحديث سياسة الخصوصية هذه من وقت آلخر استجابة للمتطلبات
            القانونية أو التنظيمية أو التشغيلية. سيتم نشر أي تغييرات في سياسة
            الخصوصية هذه على هذه الصفحة. عندما تكون هذه التغييرات مادية، قد نوفر
            لك إخطارا محددا بأية تغييرات من هذا القبيل -بما في ذلك موعد دخولها
            حيز التنفيذ- وفقا للقانون.
          </p>
          <p>
            حيثما ينطبق ذلك -على سبيل المثال، إن كانت التغييرات تتطلب معالجة
            بيانات جديدة تعتمد على موافقتك المسبقة- سوف نتأكد أنه قد أتيحت لك
            إمكانية قبول هذه التغييرات حتى تتمكن من مواصلة استخدام خدماتنا أو
            رفضها - في هذه الحالة يحق لك إلغاء استخدامك لخدمات. ابداع التعليمية
          </p>
          <p>تم تحديث سياسة الخصوصية هذه في 08 اكتوبر 2024 .</p>
        </div>
      </div>
    </div>
  );
}
