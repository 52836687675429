import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "./Config";
import { setError } from "./ErrorSlice";
import { errorFunction } from "../hooks/ErrorFunction";


const initState = {
  records: [],
  loading: false,
  error: false,
  complete: false,
  completeGetAdmin: false,
};

export const getAllUsers = createAsyncThunk(
  "users/getAllUsers",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get("/users?limit=50000");
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب   المستخدمين ",
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getUserByPhone = createAsyncThunk(
  "users/getUserByPhone",
  async (phone, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/users?phone=${phone}`);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء في احضار المستخدم من خلال رقم الهاتف",
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const getUserByRole = createAsyncThunk(
  "users/getUserByRole",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.get(`/users?role=admin`);
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في جلب الادمن ",
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error.message);
    }
  }
);

export const addAdmin = createAsyncThunk(
  "users/addAdmin",
  async (formData, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post("/users", formData);
      dispatch(
        setError({
          type: "complete",
          message: "تم انشاء ادمن بنجاح",
          location: "الادمن",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: errorFunction(error,"حدث خطا ما في انشاء admin"),
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await axiosInstance.delete(`/users/${id}`);
      dispatch(
        setError({
          type: "complete",
          message: "تم حذف المستخدم بنجاح",
          location: "المستخدمين",
        })
      );
      return id;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في حذف مستخدم ",
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const editUser = createAsyncThunk(
  "users/editUser",
  async ([id, formData], { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.put(`/users/${id}`, formData);
      dispatch(
        setError({
          type: "complete",
          message: "  تم التعديل  بنجاح",
          location: "  المستخدمين",
        })
      );
      return response.data;
    } catch (error) {
      dispatch(
        setError({
          type: "error",
          message: "حدث خطاء ما في تعديل علي المستخدم",
          location: "  المستخدمين",
        })
      );
      return rejectWithValue(error);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: initState,
  reducers: {
    resetDataUser(state) {
      state.error = false;
      state.complete = false;
      state.completeGetAdmin = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllUsers
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getAllUsers.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch categories. Please try again.";
        state.complete = false;
      })
      // getUserByPhone
      .addCase(getUserByPhone.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(getUserByPhone.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = true;
      })
      .addCase(getUserByPhone.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch categories. Please try again.";
        state.complete = false;
      })
      // getUserByRole
      .addCase(getUserByRole.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.complete = false;
      })
      .addCase(getUserByRole.fulfilled, (state, action) => {
        state.loading = false;
        state.records = action.payload;
        state.complete = false;
      })
      .addCase(getUserByRole.rejected, (state) => {
        state.loading = false;
        state.error = "Failed to fetch categories. Please try again.";
        state.complete = false;
      })
      // addAdmin
      .addCase(addAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(addAdmin.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(state.records)) {
          state.records = [...state.records, action.payload];
        } else {
          state.records = [action.payload];
        }
        state.complete = true;
      })
      .addCase(addAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = `${action.payload}`;
        state.complete = false;
      })
      //  delete
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.complete = false;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.records.data = state.records.data.filter(
          (e) => e._id !== action.payload
        );
        state.complete = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload.message}`;
        state.complete = false;
      })
      // edit
      .addCase(editUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.completeGetAdmin = false;
      })
      .addCase(editUser.fulfilled, (state, action) => {
        state.loading = false;
        //  state.records = action.payload;
        state.completeGetAdmin = true;
      })
      .addCase(editUser.rejected, (state, action) => {
        state.loading = false;
        state.error = `Error: ${action.payload}`;
        state.completeGetAdmin = false;
      });
  },
});
export const { resetDataUser } = usersSlice.actions;
export default usersSlice.reducer;
