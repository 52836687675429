import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import css from "./AdminSidePar.module.css";
import { TfiLayoutSlider } from "react-icons/tfi";
import { FaChartColumn } from "react-icons/fa6";
import {
  MdOutlineDomainVerification,
  MdDriveFileMoveRtl,
  MdControlPoint,
} from "react-icons/md";
import { RiCoupon3Fill } from "react-icons/ri";
import { FaUserGroup } from "react-icons/fa6";
import { IoPersonAddSharp } from "react-icons/io5";
import DashboardAvatar from "./DashboardAvatar/DashboardAvatar";
import LastTime from "./LastTime/LastTime";
import { useSelector } from "react-redux";

import { MdLogout } from "react-icons/md";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import MainModel from "../../../Model/mainModel/MainModel";
import DeleteBtn from "../../../Components/Buttons/DeleteBtn/DeleteBtn";
import { resetData } from "../../../Store/getMeSlice";
import { useDispatch } from "react-redux";

export default function AdminSidePar() {
  const [modelDelete, setModelDelete] = useState(false);
  const [selectedRowForDelete, setSelectedRowForDelete] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    const logoutSure = () => {
      Cookies.remove("access_token");
      navigate("/");
      dispatch(resetData());
    };
    setSelectedRowForDelete(() => logoutSure);
    setModelDelete(true);
  };
  const { loading } = useSelector((state) => state.users);
  const { records } = useSelector((state) => state.me);
  const location = useLocation();
  return (
    <div className={css.container}>
      <div className={css.avatar}>
        <DashboardAvatar />
      </div>
      <div className={css.GoPages}>
        <Link
          to="/dashboard"
          className={location.pathname === "/dashboard" ? `${css.active}` : ""}
          title="dashboard"
        >
          <span>
            <MdOutlineDomainVerification />
          </span>
          <span className={css.name}>الرئسية</span>
        </Link>
        {records.role === "manager" && (
          <>
            <Link
              to="accounts"
              title="الاحصائيات"
              className={
                location.pathname === "/dashboard/accounts"
                  ? `${css.active}`
                  : ""
              }
            >
              <span>
                <FaChartColumn />
              </span>
              <span className={css.name}>الاحصائيات</span>
            </Link>
            <div
              className={`${css.dropstart} ${
                location.pathname === "/dashboard/create-user" ||
                location.pathname === "/dashboard/create-user/teacher"
                  ? `${css.active}`
                  : ""
              }`}
            >
              <span>
                <IoPersonAddSharp />
              </span>
              <div dir="ltr" className={`dropstart`}>
                <div
                  type="button"
                  className=" dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className={css.name}>اضافة</span>
                </div>
                <ul className={`dropdown-menu ${css.dropdownMenu}`}>
                  <li>
                    <Link
                      to="create-user"
                      title=" انشاء أدمن"
                      className={` dropdown-item ${
                        location.pathname === "/dashboard/create-user"
                          ? `${css.active}`
                          : ""
                      }`}
                    >
                      <span className={css.name}> انشاء أدمن</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dashboard/create-user/teacher"
                      title="تعديل مدرس"
                      className={` dropdown-item ${
                        location.pathname === "/dashboard/create-user/teacher"
                          ? `${css.active}`
                          : ""
                      }`}
                    >
                      <span className={css.name}> تعديل المدرس</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
        <Link
          to="users"
          title="المستخدمين"
          className={
            location.pathname === "/dashboard/users" ? `${css.active}` : ""
          }
        >
          {loading && location.pathname === "/dashboard/users" ? (
            <span
              className="spinner-border spinner-border-sm me-1"
              aria-hidden="true"
            ></span>
          ) : (
            <span>
              <FaUserGroup />
            </span>
          )}
          <span className={css.name}>المستخدمين</span>
        </Link>
        <Link
          to="print-coupons"
          title="كوبونات"
          className={
            location.pathname === "/dashboard/print-coupons"
              ? `${css.active}`
              : ""
          }
        >
          <span>
            <RiCoupon3Fill />
          </span>
          <span className={css.name}>كوبونات</span>
        </Link>
        <Link
          to="add-points"
          title="النقاط"
          className={
            location.pathname === "/dashboard/add-points" ? `${css.active}` : ""
          }
        >
          {loading && location.pathname === "/dashboard/add-points" ? (
            <span
              className="spinner-border spinner-border-sm me-1"
              aria-hidden="true"
            ></span>
          ) : (
            <span>
              <MdControlPoint />
            </span>
          )}
          <span className={css.name}>النقاط</span>
        </Link>
        <div
          className={`${css.dropstart} ${
            location.pathname === "/dashboard/create-files" ||
            location.pathname === "/dashboard/create-files/section" ||
            location.pathname === "/dashboard/create-files/class"
              ? `${css.active}`
              : ""
          } `}
        >
          <span>
            <MdDriveFileMoveRtl />
          </span>
          <div dir="ltr" className={`dropstart`}>
            <div
              type="button"
              className=" dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className={css.name}>مجلدات</span>
            </div>
            <ul className={`dropdown-menu ${css.dropdownMenu}`}>
              <li>
                <Link
                  to="/dashboard/create-files"
                  title="files"
                  className={` dropdown-item  ${
                    location.pathname === "/dashboard/create-files"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}>محاضره</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/create-files/section"
                  title="section"
                  className={` dropdown-item  ${
                    location.pathname === "/dashboard/create-files/section"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}>باب</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/create-files/class"
                  title="class"
                  className={` dropdown-item  ${
                    location.pathname === "/dashboard/create-files/class"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}>الصف</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={`${css.dropstart} ${
            location.pathname === "/dashboard/sliders" ||
            location.pathname === "/dashboard/sliders/honour" ||
            location.pathname === "/dashboard/sliders/gallery-teacher"
              ? `${css.active}`
              : ""
          } `}
        >
          <span>
            <TfiLayoutSlider />
          </span>
          <div dir="ltr" className={`dropstart`}>
            <div
              type="button"
              className=" dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className={css.name}>سليدرات</span>
            </div>
            <ul className={`dropdown-menu ${css.dropdownMenu}`}>
              <li>
                <Link
                  to="sliders"
                  title="السليدر الرئيسي"
                  className={` dropdown-item ${
                    location.pathname === "/dashboard/sliders"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}>السليدر الرئيسي</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/sliders/honour"
                  title="لوحة الشرف"
                  className={` dropdown-item ${
                    location.pathname === "/dashboard/sliders/honour"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}> لوحة الشرف</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/sliders/gallery-teacher"
                  title="معرض المدرس"
                  className={` dropdown-item ${
                    location.pathname === "/dashboard/sliders/gallery-teacher"
                      ? `${css.active}`
                      : ""
                  }`}
                >
                  <span className={css.name}> معرض المدرس</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <span
          onClick={() => logOut()}
          className={css.logout}
          title="تسجيل الخروج"
        >
          <span>
            <MdLogout />
          </span>
          <span className={css.name}> خروج</span>
        </span>
      </div>
      <div className={css.lastTime}>
        <LastTime />
      </div>
      {modelDelete && (
        <MainModel
          setModel={setModelDelete}
          content={
            <DeleteBtn
              setModel={setModelDelete}
              deleteData={selectedRowForDelete}
              msg="هل تريد تسجيل الخروج ؟"
            />
          }
        />
      )}
    </div>
  );
}
